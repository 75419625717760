export default {
  // -------------- Navigation Bar --------------
  homepage: "αρχική",
  quiz: "αuiz",
  curriculum: "υλη",
  statistics: "στατιστικά",
  exam_page: "εξέταση",
  profil: "προφίλ",
  logout: "αποσύνδεση",

  // -------------- General --------------
  title: "τίτλος",
  class: "μάθημα",
  classes: "μαθήματα",
  department: "τμήμα",
  departments: "τμήματα",
  chapter: "κεφάλαιο",
  chapters: "κεφάλαια",
  choose_class: "επιλέξτε μάθημα",
  choose_classes: "επιλέξτε μαθήματα",
  choose_department: "επιλέξτε τμήμα",
  choose_departments: "επιλέξτε τμήματα",
  choose_professor: "επιλέξτε καθηγητή",
  choose_professors: "επιλέξτε καθηγητές",
  choose_classrooms: "επιλέξτε αίθουσες",
  choose_chapter: "επιλέξτε κεφάλαι",
  choose_chapters: "επιλέξτε κεφάλαια",
  upload_file: "ανέβασμα αρχείου",
  upload_files: "ανέβασμα αρχείων",
  previous: "προηγούμενο",
  next: "επόμενο",
  previous_male: "προηγούμενος",
  next_male: "επόμενος",
  previous_female: "προηγούμενη",
  next_female: "επόμενη",
  average: "μέσος όρος",
  filter: "φίλτρο",
  filters: "φίλτρα",
  edit: "επεξεργασία",
  save: "αποθήκευση",
  search: "αναζήτηση",
  start: "εκκίνηση",
  back: "επιστροφή",
  delete: "διαγραφή",
  delete_concurrent: "διαγραφή επανάληψης",
  delete_just_this: "διαγραφή επιλεγμένου",
  cancel: "ακύρωση",
  enter_title: "εισάγετε τίτλο",
  enter_class: "επιλέξτε μάθημα",
  enter_department: "επιλέξτε τμήμα",
  confirm_delete_label: "είστε σίγουροι ότι θέλετε να διαγράψετε ",
  enter_professor: "επιλέξτε καθηγητή",
  start_time: "ώρα έναρξης",
  end_time: "ώρα λήξης",
  create: "δημιουργία",
  classroom: "αίθουσα",
  professor: "καθηγητής",
  student: "μαθητής",
  parent: "γονέας",
  admin: "ιδιοκτήτης",
  present: "παρών",
  dismissed: "απών",
  all_present: "όλοι οι μαθητές είναι παρόντες",
  all_dismised: "όλοι οι μαθητές είναι απόντες",
  progress: "επίδοση",
  understanding: "κατανόηση",
  vocal: "προφορικός",
  no_effort: "καμία προσπάθεια",
  minimum_effort: "ελάχιστη προσπάθεια",
  medium_effort: "μέτρια προσπάθεια",
  good_effort: "καλή προσπάθεια",
  excelent_effort: "άριστη προσπάθεια",
  question_mark: ";",
  january: "ιανουάριος",
  february: "φεβρουάριος",
  march: "μάρτιος",
  april: "απρίλιος",
  may: "μάιος",
  june: "ιούνιος",
  july: "ιούλιος",
  august: "αύγουστος",
  september: "σεπτέμβριος",
  october: "οκτώβριος",
  november: "νοέμβριος",
  december: "δεκέμβριος",
  hour: "ώρα",
  hours: "ώρες",
  print: "εκτύπωση",
  exercise_title: "εκφώνηση",
  upload_mp3: "Ανέβαστε αρχείου ήχου",
  answers: "απαντήσεις",
  exams: "διαγωνίσματα",
  lecture: "μάθημα",
  test: "τεστ",
  exam: "διαγώνισμα",
  online_exam: "e-διαγώνισμα",
  tuition: "δίδακτρα",
  more_info: "περισσότερες πληροφορίες",
  to: "προς",
  all_departments: "όλα τα τμήματα",
  all_classes: "όλα τα μαθήματα",

  // -------------- Side Filters --------------
  add_class: "προσθήκη μαθήματος",
  add_class_title: "διαμόρφωση νέου μαθήματος",
  class_name_placeholder: "όνομα μαθήματος",

  // -------------- Login Page --------------
  login: "είσοδος",
  sign_up: "εγγραφή",
  password: "κωδικός",
  login_with_google: "σύνδεση με google",
  first_name: "όνομα",
  last_name: "επώνυμο",
  repeat_password: "επιβεβαίωση κωδικού",
  sign_up_with_google: "εγγραφή με google",
  conrigm_conditions: "αποδέχομαι τους όρους χρήσης και πολιτικής προστασίας",
  enter: "σύνδεση",

  // -------------- Home Page --------------
  announcements_title: "ανακοινώσεις",
  description: "περιγραφή",
  all: "όλα",
  notify: "ειδοποιήστε",
  professors: "καθηγητές",
  students: "μαθητές",
  parents: "γονείς",
  publish: "δημοσίευση",
  program: "πρόγραμμα",
  rotate_classrooms: "εναλλαγή αιθουσών",
  rotate_classrooms_title: "εναλλαγή αιθουσών για εβδομαδιαία μαθήματα",
  rotate_classrooms_label:
    "Επιλέξτε τις αίθουσες για εβδομαδιαία εναλλαγή. Κάθε εβδομάδα, τα μαθήματα που πραγματοποιούνται σε μια επιλεγμένη αίθουσα θα μεταφέρονται στην επόμενη. Για παράδειγμα, αν έχετε επιλέξει τις αίθουσες 1, 2 και 3, τότε οι δραστηριότητες από την αίθουσα 1 θα μεταφερθούν στην αίθουσα 2 την επόμενη εβδομάδα, από την 2 στην 3, και από την 3 πίσω στην 1. Αυτή η διαδικασία θα επαναλαμβάνεται κάθε εβδομάδα αυτόματα εκτός και αν αφαιρέσετε κάποια επιλεγμένη αίθουσα.",
  all_classrooms: "όλες οι αίθουσες",
  selected_classrooms: "επιλεγμένες αίθουσες",
  configure_event_titel: "προγραμματισμός δραστηριότητας",
  parent_event: "ενημέρωση γονέων",
  no_repeat: "καμία επανάληψη",
  weekly_repeat: "εβδομαδιαία επανάληψη",
  max_score: "μέγιστη βαθμολογία",
  max_rank: "μέγιστος βαθμός",
  max_rank_short: "μεγ. βαθμός",
  add_thema: "προσθήκη θέματος",
  configure_themata_label:
    "διαμορφώστε τον αριθμό και τη βαθμολογία των θεμάτων",
  enter_lesson_taught: "εισάγετε διδακτέα ύλη",
  enter_lesson_taught_placehodler: "διδακτέα ύλη...",
  enter_homework: "εισάγετε διάβασμα για το σπίτι",
  enter_homework_placeholder: "διάβασμα για το σπίτι...",
  enter_general_comments: "εισάγετε γενικά σχόλια",
  enter_general_comments_placeholder: "γενικά σχόλια...",
  comments_for_progress: "σχόλια επόδοσης μαθητών",
  transform_to_online: "μετατροπή σε ηλεκτρονικό μάθημα",
  start_online: "εκκίνηση ηλεκτρονικού μαθήματος",
  mark_exam_label: "καταγραφή βαθμών στους μαθητές",
  daily_test: "ημερήσιο τεστ",
  contact_with_parent: "επικοινωνία με γονέα",
  studying_room: "αναγνωστήριο",

  // -------------- Users Page --------------
  add_class: "προσθήκη μαθήματος",
  add_class_title: "διαμόρφωση νέου μαθήματος",
  class_name_placeholder: "όνομα μαθήματος",
  enter_name: "εισάγετε όνομα",
  enter_last_name: "εισάγετε επώνυμο",
  enter_email_username: "εισάγετε email / Username",
  enter_password: "εισάγετε κωδικό",
  repeat_password: "επαναλάβετε κωδικού",
  repeat_password_placeholder: "επανάληψη κωδικού",
  chapter_name: "όνομα κεφαλαίου",
  add_chapter: "προσθέστε νέο κεφάλαιο",
  new_chapter_name: "όνομα νέου κεφαλαίου",

  // -------------- Quiz Page --------------
  manage_questions: "διαχείριση ερωτήσεων",
  create_question: "δημιουργία ερώτησης",
  question_type: "τύπος ερώτησης",
  search_chapter: "αναζητήστε κεφάλαιο",
  multiple_choice: "πολλαπλής επιλογής",
  crossword: "αντιστοίχισης",
  correct_wrong: "Σωστό / Λάθος",
  explanation: "επεξήγηση",
  hint: "βοήθημα",
  enter_possible_answers:
    "παρακαλώ πληκτρολογήστε παρακάτω πιθανές απαντήσεις...",
  possible_answer: "πιθανή απάντηση",
  explanation_placeholder: "επεξήγηση σωστής απάντησης",
  hint_placeholder: "δώστε ένα βοήθημα",
  auto_generate_quiz: "Αυτόματη δημιουργία ερώτησης",
  column_a: "στήλη α",
  column_b: "στήλη β",
  above_question_is: "η παρακάνω εκφώνηση είναι",
  mulitple_possible_answers: "πολλαπλές πιθανές απαντήσεις",
  cancel_mulitple_possible_answers: "ακύρωση πολλαπλών πιθανών απαντήσεων",

  // -------------- Curriculum Page --------------
  upload_curriculum: "ανέβασμα ύλης",
  search_curriculum: "αναζητήστε ύλη",
  grid: "grid",
  exercises: "ασκήσεις",
  enable_answers: "αποδοχή απαντήσεων απο τους μαθητές",
  locked: "κλειδωμένο",
  disable_download: "απενεργοποίηση λήψης αρχείων",
  download_all: "λήψη όλων",
  submission: "υποβολή",
  submissions: "υποβολές",
  student_answers: "απαντήσεις μαθητών",

  // -------------- Statistics Page --------------
  online_users: "ενεργοί χρήστες",
  general: "γενικά",
  students_number_short: "αρ. μαθητών",
  professors_number_short: "αρ. καθηγητών",
  departments_number_short: "αρ. τμημάτων",
  chapters_number_short: "αρ. κεφαλαίων",
  general_average: "γενικός μέσος όρος",
  involvment_label: "ποσοστό συμμετοχής μαθητών",
  average_appeal: "τυπική απόκληση",
  exams_average_month: "μέσος όρος διαγωνισμάτων ανά μήνα",
  students_performance: "επίδοση μαθητών",
  total_departments_average: "συνολικός μέσος όρος τμημάτων",
  departments_average: "μέσος όρος τμημάτων",
  departments_average_thema: "μέσος όρος τμημάτων ανά θέμα",
  expected_annual_short: "ανμ. Ετήσιος Τζίρος",
  total_earnings_short: "συν. Εισπράξεις",
  expected_earnings_short: "αναμ. εισπράξεις",
  tuition_per_month: "σύνολο διδάκτρων ανά μήνα",
  cash_payments: "πληρωμές με τράπεζα",
  card_payments: "πληρωμές με κάρτα",
  total_payments: "σύνολο πληρωμών",
  expected_tuition: "αναμενόμενα δίδακτρα",
  students_not_paid: "μαθητές που δεν έχουν πληρώσει",
  students_paid: "μαθητές που έχουν πληρώσει",
  tuition_per_grade: "σύνολο διδάκτρων ανά τάξη",

  // -------------- Profile Page --------------
  student_parents: "γονέις μαθητή",
  asign_tuition: "ανάθεση διδάκτρων",
  out_of_school: "εξωσχολικές δραστηριότητες",
  edit_departments: "επεξεργασία τμημάτων",
  comments: "σχόλια",
  evaluation: "αξιολόγηση",
  uni_selector: "μηχανογραφικό",

  // -------------- Chat --------------
  conversations: "συνομιλίες",
  group_chat: "ομαδική",
};
