import "./curriculum.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  BiAbacus,
  BiBorderAll,
  BiCheckbox,
  BiSearch,
  BiX,
} from "react-icons/bi";
import SideFilters from "../../components/SideFilters/side-filters";
import { SocketContext } from "../../app/socket";
import CurriculumCard from "./sections/CurriculumItem/curriculumCard";
import MobileSideFilters from "../../components/SideFilters/MobileSideFilters/mobileSideFilters";
import LoaderCard from "../../components/LoaderCard/loaderCard";
import Pagination from "../../components/Pagination/pagination";
import AddCurriculum from "./sections/AddCurriculum/addCurriculum";
import { iconStyle } from "../../utils/generalUtils";
import LiveWorksheetEmbed from "./sections/CurriculumItem/LiveWorksheetEmbed";
import { cookieUtils } from "../../utils/cookie-parser";
import CurriculumFilters from "./sections/CurriculumFilters/curriculumFilters";

function Curriculum() {
  const [curriculum, setCurriculum] = useState([]);
  const [gotCurriculum, setGotCurriculum] = useState(false);
  const [classes, setClasses] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const profile = useSelector((state) => state.profile.value);
  const searchRef = useRef(null);
  const [curriculumToEdit, setCurriculumToEdit] = useState();
  const [activePage, setActivePage] = useState(1);
  const activePageRef = useRef(0);
  const [totalCurriculum, setTotalCurriculum] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const socketContext = useContext(SocketContext);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [addExpanded, setAddExpanded] = useState(false);
  const [showDouble, setShowDouble] = useState(false);
  const [showLiveWork, setShowLiveWork] = useState(false);
  const [showExercises, setShowExercises] = useState(false);
  const [curriculumLoading, setCurriculumLoading] = useState();

  const showExercisesRef = useRef(0);
  const showLiveWorkRef = useRef(0);

  const [curriculumFiltersOpen, setCurriculumFiltersOpen] = useState(false);

  const [pageLimit, setPageLimit] = useState(showDouble ? 30 : 10);

  useEffect(() => {
    // Check if the cookie exists and set the state
    const savedShowDouble = cookieUtils.getCookie("showDouble");
    if (savedShowDouble !== "") {
      setShowDouble(savedShowDouble === "true");
    }
  }, []);

  useEffect(() => {
    // Update the cookie whenever the state changes
    cookieUtils.setCookie("showDouble", showDouble, 7); // expires in 7 days
  }, [showDouble]);

  const toggleView = () => {
    setShowDouble(!showDouble);
  };

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const sideFiltersChapterId = useSelector(
    (state) => state.chapters.selectedChapterId
  );

  const prevSideFiltersChapterId = useRef(sideFiltersChapterId);
  const prevSideFiltersClassId = useRef(sideFiltersClassId);
  const prevSearchText = useRef(searchText);

  useEffect(() => {
    if (searchOpen && searchRef.current) {
      searchRef.current.focus();
    }
    if (!searchOpen) {
      setSearchText("");
    }
  }, [searchOpen]);

  const mobileFiltersClick = () => {
    setMobileFiltersOpen(!mobileFiltersOpen);
  };

  useEffect(() => {
    setGotCurriculum(false);
    return getCurriculum();
  }, [sideFiltersClassId]);

  useEffect(() => {
    if (activePageRef.current > 0) {
      setGotCurriculum(false);
      return getCurriculum();
    } else {
      activePageRef.current++;
    }
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    setGotCurriculum(false);
    return getCurriculum();
  }, [searchText]);

  useEffect(() => {
    if (showLiveWorkRef.current > 0) {
      return getCurriculum();
    } else {
      showLiveWorkRef.current++;
    }
  }, [showLiveWork]);

  useEffect(() => {
    if (showExercisesRef.current > 0) {
      return getCurriculum();
    } else {
      showExercisesRef.current++;
    }
  }, [showExercises]);

  useEffect(() => {
    if (
      sideFiltersChapterId !== prevSideFiltersChapterId.current ||
      sideFiltersClassId !== prevSideFiltersClassId.current ||
      searchText !== prevSearchText.current
    ) {
      setGotCurriculum(false);
      setActivePage(1);
      return getCurriculum();
    }

    prevSideFiltersChapterId.current = sideFiltersChapterId;
    prevSideFiltersClassId.current = sideFiltersClassId;
    prevSearchText.current = searchText;
  }, [sideFiltersChapterId, sideFiltersClassId]);

  const getCurriculum = () => {
    let args = {
      class_id: sideFiltersClassId ? sideFiltersClassId : -1,
      search_text: searchText,
      limit: pageLimit,
      pagination_index: activePage,
      only_exercises: showExercises,
      only_live_work: showLiveWork,
    };

    const getCurriculumListener = (data) => {
      console.log("data");
      console.log(data);
      if (data && data[0]) {
        setTotalCurriculum(data[0].total);
      } else {
        setTotalCurriculum(0);
      }
      setGotCurriculum(true);
      setCurriculum(data);
    };

    const refreshCurriculumListener = () => {
      socketContext.socket.emit("getCurriculum", args);
    };

    socketContext.socket.on("curriculum", getCurriculumListener);
    socketContext.socket.emit("getCurriculum", args);
    socketContext.socket.on("refreshCurriculum", refreshCurriculumListener);

    return () => {
      socketContext.socket.off("getCurriculum", getCurriculumListener);
      socketContext.socket.off("curriculum", getCurriculumListener);
      socketContext.socket.off("refreshCurriculum", refreshCurriculumListener);
    };
  };

  const populateCurriculum = () => {
    return curriculum.map((item) => {
      let departments = [];
      let departmentNames = [];
      let itemClassName = "";
      if (item.departments) {
        departments = JSON.parse(item.departments);
        departmentNames = allDepartments
          .filter((dept) => departments.includes(dept.department_id))
          .map((dept) => dept.department_name);
      }
      if (item.class_id) {
        const foundClass = classes.find((c) => c.class_id === item.class_id);
        if (foundClass) {
          itemClassName = foundClass.class_name + " " + foundClass.grade_name;
        }
      }
      return (
        <CurriculumCard
          key={"curriculumCard" + item.id}
          curriculum={item}
          departmentNames={departmentNames}
          itemClassName={itemClassName}
          curriculumToEdit={curriculumToEdit}
          setCurriculumToEdit={setCurriculumToEdit}
          curriculumLoading
        ></CurriculumCard>
      );
    });
  };

  return (
    <div className="curriculum">
      {<SideFilters />}
      {showUploadModal ? <div className="modal-background show"></div> : ""}
      <div className={"curriculum__posts " + (showDouble ? "view-double" : "")}>
        {/* <LiveWorksheetEmbed /> */}
        {profile.user_type !== "student" && profile.user_type !== "parent" ? (
          <div
            className={
              "curriculum__header " + (addExpanded ? "is-expanded" : "")
            }
          >
            <AddCurriculum
              curriculumToEdit={curriculumToEdit}
              setCurriculumToEdit={setCurriculumToEdit}
              curriculum={curriculum}
              setCurriculum={setCurriculum}
              closed={searchOpen || mobileFiltersOpen}
              isExpanded={addExpanded}
              setIsExpanded={setAddExpanded}
              setCurriculumLoading={setCurriculumLoading}
            />
            {/* <div
              className="curriculum__header-view"
              onClick={() => {
                toggleView();
              }}
            >
              {!showDouble ? (
                <BiBorderAll
                  className="search-close"
                  size={"35px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              ) : (
                <BiCheckbox
                  className="search-close"
                  size={"35px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              )}
            </div> */}
            <CurriculumFilters
              isExpanded={curriculumFiltersOpen}
              setIsExpanded={setCurriculumFiltersOpen}
              isGridView={showDouble}
              setIsGridView={setShowDouble}
              showExercises={showExercises}
              setShowExercises={setShowExercises}
              showLiveWork={showLiveWork}
              setShowLiveWork={setShowLiveWork}
            />
            <div
              className={
                "curriculum__header-search " + (searchOpen ? "open" : "closed")
              }
              onClick={() => {
                if (!searchOpen) {
                  setSearchOpen(true);
                }
              }}
            >
              <input
                ref={searchRef}
                className="input"
                placeholder="Αναζήτηση"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchOpen ? (
                <BiX
                  className="search-close"
                  onClick={() => setSearchOpen(false)}
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              ) : (
                <BiSearch
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              )}
            </div>
            <div
              className={
                "curriculum-mobile-filters " +
                (mobileFiltersOpen && !searchOpen ? "open" : "closed")
              }
              onClick={mobileFiltersClick}
            >
              <div className="curriculum-mobile-filters__preview">
                {mobileFiltersOpen ? (
                  <span onClick={() => setMobileFiltersOpen(false)}>
                    Φίλτρα
                    <BiX
                      size={"25px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                  </span>
                ) : (
                  <BiAbacus
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}
              </div>
              <MobileSideFilters />
            </div>
          </div>
        ) : (
          <div className="student-header">
            <div
              className={
                "student-header__search " + (mobileFiltersOpen ? " closed" : "")
              }
              onClick={() => setMobileFiltersOpen(false)}
            >
              <input
                ref={searchRef}
                placeholder="Αναζήτηση"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <BiSearch
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
            <div
              className={
                "student-header__filters " +
                (mobileFiltersOpen ? "open" : "closed")
              }
              onClick={mobileFiltersClick}
            >
              <div className="student-header__filters-preview">
                {mobileFiltersOpen ? (
                  <span onClick={() => setMobileFiltersOpen(false)}>
                    Φίλτρα
                    <BiX
                      size={"25px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                  </span>
                ) : (
                  <BiAbacus
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}
              </div>
              <MobileSideFilters bigWidth={true} />
            </div>
          </div>
        )}
        {!gotCurriculum ? (
          <LoaderCard cardSum={5} width={100} height={"xl"} />
        ) : (
          populateCurriculum()
        )}
        <div className="curriculum__pagination">
          {totalCurriculum && totalCurriculum > pageLimit ? (
            <Pagination
              className="pagination-bar"
              currentPage={activePage}
              totalCount={totalCurriculum}
              pageSize={pageLimit}
              onPageChange={(page) => setActivePage(page)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Curriculum;
