import { createSlice } from "@reduxjs/toolkit";

export const classNamesSlice = createSlice({
  name: "classes",
  initialState: {
    value: [{}],
    selectedClassId: -1,
    selectedGradeId: -1,
  },
  reducers: {
    setClasses: (state, action) => {
      // console.log(action.payload);
      state.value = action.payload;
      // state.value.class_name = action.payload.class_name;
    },
    setCurrentClassId: (state, action) => {
      state.selectedClassId = action.payload;
    },
    setCurrentGradeId: (state, action) => {
      console.log(" setting grade from slice ");
      console.log(action.payload);
      state.selectedGradeId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClasses, setCurrentClassId, setCurrentGradeId } =
  classNamesSlice.actions;

export default classNamesSlice.reducer;
