import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../../../app/socket";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  BiPlus,
  BiCheck,
  BiX,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import { useSelector } from "react-redux";
import "./extra-filters.scss";
import { usePrintUsersContext } from "../printUsersContext";
import { CircularProgressbar } from "react-circular-progressbar";
import { iconStyle } from "../../../../utils/generalUtils";

function ExtraFilters() {
  const socketContext = useContext(SocketContext);
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const [filterExams, setFilterExams] = useState(false);
  const [examGrade, setExamGrade] = useState(0);

  const {
    includeExams,
    includeProgress,
    includeUnderstanding,
    includeTest,
    includeEvaluation,
    startAt,
    setStartAt,
    finishAt,
    setFinishAt,
    filterProgress,
    setFilterProgress,
    filterUnderstanding,
    setFilterUnderstanding,
    progressMaxGrade,
    setProgressMaxGrade,
    progressMinGrade,
    setProgressMinGrade,
    understandingMaxGrade,
    setUnderstandingMaxGrade,
    understandingMinGrade,
    setUnderstandingMinGrade,
  } = usePrintUsersContext();

  const generalSettings = useSelector((state) => state.profile.generalSettings);

  return (
    <div className="extra-filters">
      <span className="extra-filters__title">Έυρος Ημερομηνίας</span>
      <div className="extra-filters__periods">
        <div className="period-item">
          <span>Έναρξη</span>
          <input
            className="input"
            type="date"
            value={startAt}
            onChange={(e) => setStartAt(e.target.value)}
          />
        </div>
        <div className="period-item">
          <span>Λήξη</span>
          <input
            className="input"
            type="date"
            value={finishAt}
            onChange={(e) => setFinishAt(e.target.value)}
          />
        </div>
      </div>
      {includeExams ? (
        <div className="extra-filters__config">
          <div className="target__select">
            <div className="item" onClick={() => setFilterExams(!filterExams)}>
              <div
                className={
                  "target__select-radio " + (filterExams ? "active" : "")
                }
              ></div>
              <span className="fc">Φίλτρα Διαγωνισμάτων</span>
            </div>
          </div>
          {filterExams ? (
            <div className="extra-filters__config-container">
              <span className="title">Μέγιστος βαθμός</span>
              <div key={"score-item "} className="score-item">
                Μέγιστος Βαθμός
                <div className="score-item__input">
                  <BiChevronDown
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("#d6d6d6")}
                    onClick={() => setExamGrade(examGrade - 1)}
                  />
                  <input
                    type="number"
                    value={examGrade}
                    onChange={(e) => setExamGrade(e.target.value)}
                  />
                  <BiChevronUp
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("#d6d6d6")}
                    onClick={() => setExamGrade(examGrade + 1)}
                  />
                </div>
                <div className="score-item__max">
                  <span>Μεγ. Βαθμός: 100</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {includeProgress ? (
        <div className="extra-filters__config">
          <div className="target__select">
            <div
              className="item"
              onClick={() => setFilterProgress(!filterProgress)}
            >
              <div
                className={
                  "target__select-radio " + (filterProgress ? "active" : "")
                }
              ></div>
              <span className="fc">Φίλτρα Επίδοσης Μαθήματος</span>
            </div>
          </div>
          {filterProgress ? (
            <div className="extra-filters__config-container half">
              <div className="container-half">
                <span className="title">Ελάχιστος βαθμός</span>
                <select
                  className="input"
                  value={progressMinGrade}
                  onChange={(e) => setProgressMinGrade(e.target.value)}
                >
                  <option value={0}>Καμία Προσπάθεια</option>
                  <option value={1}>Ελάχιστη Προσπάθεια</option>
                  <option value={2}>Μέτρια Προσπάθεια</option>
                  <option value={3}>Καλή Προσπάθεια</option>
                  <option value={4}>Άριστη Προσπάθεια</option>
                </select>
              </div>
              <div className="container-half">
                <span className="title">Μέγιστος βαθμός</span>
                <select
                  className="input"
                  value={progressMaxGrade}
                  onChange={(e) => setProgressMaxGrade(e.target.value)}
                >
                  <option value={0}>Καμία Προσπάθεια</option>
                  <option value={1}>Ελάχιστη Προσπάθεια</option>
                  <option value={2}>Μέτρια Προσπάθεια</option>
                  <option value={3}>Καλή Προσπάθεια</option>
                  <option value={4}>Άριστη Προσπάθεια</option>
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {includeUnderstanding ? (
        <div className="extra-filters__config">
          <div className="target__select">
            <div
              className="item"
              onClick={() => setFilterUnderstanding(!filterUnderstanding)}
            >
              <div
                className={
                  "target__select-radio " +
                  (filterUnderstanding ? "active" : "")
                }
              ></div>
              <span className="fc">Φίλτρα Κατανόησης Μαθήματος</span>
            </div>
          </div>
          {filterUnderstanding ? (
            <div className="extra-filters__config-container half">
              <div className="container-half">
                <span className="title">Ελάχιστος βαθμός</span>
                <select
                  className="input"
                  value={understandingMinGrade}
                  onChange={(e) => setUnderstandingMinGrade(e.target.value)}
                >
                  <option value={0}>Καμία Προσπάθεια</option>
                  <option value={1}>Ελάχιστη Προσπάθεια</option>
                  <option value={2}>Μέτρια Προσπάθεια</option>
                  <option value={3}>Καλή Προσπάθεια</option>
                  <option value={4}>Άριστη Προσπάθεια</option>
                </select>
              </div>
              <div className="container-half">
                <span className="title">Μέγιστος βαθμός</span>
                <select
                  className="input"
                  value={understandingMaxGrade}
                  onChange={(e) => setUnderstandingMaxGrade(e.target.value)}
                >
                  <option value={0}>Καμία Προσπάθεια</option>
                  <option value={1}>Ελάχιστη Προσπάθεια</option>
                  <option value={2}>Μέτρια Προσπάθεια</option>
                  <option value={3}>Καλή Προσπάθεια</option>
                  <option value={4}>Άριστη Προσπάθεια</option>
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ExtraFilters;
