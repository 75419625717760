import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiPlus, BiUpload, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./add-expense.scss";
import { useSelector } from "react-redux";
import Compress from "compress.js";
import ExamThumbnail from "../../../../components/ExamThumbnail/exam-thumbnail";
import { calls } from "../../../../config/db_config";
import { tokenUtils } from "../../../../utils/token-utils";
import axios from "axios";
import { nanoid } from "nanoid";
import { fileUtils } from "../../../../utils/fileUtils";

function AddExpense({
  professors,
  students,
  parents,
  isExpanded,
  setIsExpanded,
}) {
  const envVars = useSelector((state) => state.profile.environmentVars);
  const socketContext = useContext(SocketContext);
  const hasCameraAccess = useSelector((state) => state.profile.hasCameraAccess);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [sendToAade, setSendToAade] = useState(false);
  const [aadeAfm, setAadeAfm] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newExpenseTypeName, setNewExpenseTypeName] = useState("");
  const [expensesTypes, setExpensesTypes] = useState([]);
  const [expenseDate, setExpenseDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [expenseAmount, setExpenseAmount] = useState();

  const [selectedExpenseType, setSelectedExpenseType] = useState();

  const dropdownRef = useRef(null);
  const previewRef = useRef(null);

  const index = 0;
  const [files, setFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const compress = new Compress();

  useEffect(() => {
    const cleanUpGetExpensesTypes = getExpensesTypes();
    return () => {
      cleanUpGetExpensesTypes();
    };
  }, []);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.125 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "70vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        previewRef.current &&
        !previewRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, previewRef]);

  const addNewExpenseType = () => {
    if (newExpenseTypeName) {
      let body = {
        type_name: newExpenseTypeName,
      };

      socketContext.socket.emit("addExpenseType", body);
      setNewExpenseTypeName("");
    }
  };

  const getExpensesTypes = () => {
    let args = {};

    const getExpensesTypesListener = (data) => {
      console.log(data);
      setExpensesTypes(data);
      if (data && data.length) {
        setSelectedExpenseType(data[0].school_expenses_type_id);
      }
    };

    const refreshExpensesTypesListener = () => {
      socketContext.socket.emit("getExpensesTypes", args);
    };

    socketContext.socket.on("expensesTypes", getExpensesTypesListener);
    socketContext.socket.emit("getExpensesTypes", args);
    socketContext.socket.on(
      "refreshExpensesTypes",
      refreshExpensesTypesListener
    );

    return () => {
      socketContext.socket.off("getExpensesTypes", getExpensesTypesListener);
      socketContext.socket.off("expensesTypes", getExpensesTypesListener);
      socketContext.socket.off(
        "refreshExpensesTypes",
        refreshExpensesTypesListener
      );
    };
  };

  const populateExpensesTypes = () => {
    return expensesTypes.map((type) => {
      return (
        <div
          className={
            "dropdown-content__item " +
            (selectedExpenseType &&
            selectedExpenseType == type.school_expenses_type_id
              ? " active"
              : "")
          }
          onClick={() => {
            setSelectedExpenseType(type.school_expenses_type_id);
            setDropdownOpen(false);
          }}
          key={"expensesType " + type.school_expenses_type_id}
        >
          <span>{type.name}</span>
        </div>
      );
    });
  };

  const imageChange = async (event) => {
    let fileList = Array.from(event.target.files);

    // Correctly filter image files
    const imageList = fileList.filter((file) => file.type.startsWith("image/"));

    const pdfList = fileList.filter((file) => file.type === "application/pdf");
    const audioList = fileList.filter((file) => file.type.startsWith("audio/"));
    const videoList = fileList.filter((file) => file.type.startsWith("video/"));
    const pptList = fileList.filter(
      (file) =>
        file.type === "application/vnd.ms-powerpoint" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    );
    const wordList = fileList.filter(
      (file) =>
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    );

    let tempThumbnailList = [];
    let updatedFileList = files;
    updatedFileList[index] = [];

    // Process image files
    await compress
      .compress(imageList, {
        size: 2, // the max size in MB
        quality: 0.75, // the quality of the image
        maxWidth: 1920, // the max width of the output image
        maxHeight: 1920, // the max height of the output image
        resize: true, // resize the image
        rotate: false, // no rotation
      })
      .then((data) => {
        data.forEach(function (singleData) {
          const base64 = singleData.data;
          const name = singleData.alt;
          const imgExt = singleData.ext;

          const blob = Compress.convertBase64ToFile(base64, imgExt);
          let file = new File([blob], name);
          updatedFileList[index].push(file);

          let baseData = "data:" + imgExt + ";base64," + base64;
          tempThumbnailList.push(baseData);
        });
      });

    // Process PDF files
    pdfList.forEach((pdfFile) => {
      updatedFileList[index].push(pdfFile);
      tempThumbnailList.push("../../../resources/icons/pdf-icon.png");
    });

    // Process audio files
    audioList.forEach((audioFile) => {
      updatedFileList[index].push(audioFile);
      tempThumbnailList.push("../../../resources/icons/audio-icon.png");
    });

    // Process video files
    videoList.forEach((videoFile) => {
      updatedFileList[index].push(videoFile);
      tempThumbnailList.push("../../../resources/icons/video-icon.png");
    });

    // Process PowerPoint files
    pptList.forEach((pptFile) => {
      updatedFileList[index].push(pptFile);
      tempThumbnailList.push("../../../resources/icons/ppt-icon.png");
    });

    // Process Word files
    wordList.forEach((wordFile) => {
      updatedFileList[index].push(wordFile);
      tempThumbnailList.push("../../../resources/icons/word-icon.png");
    });

    setThumbnails(tempThumbnailList);
    setFiles(updatedFileList);
  };

  const populateThumbnails = () => {
    if (!files || !files[index]) {
      return null;
    }

    return files[index].map((file, i) => {
      let imageSrc = "";
      if (file.type === "application/pdf") {
        imageSrc = "../../../resources/icons/pdf-icon.png";
      } else if (file.type.startsWith("audio/")) {
        imageSrc = "../../../resources/icons/audio-icon.png";
      } else if (file.type.startsWith("video/")) {
        imageSrc = "../../../resources/icons/video-icon.png";
      } else if (
        file.type === "application/vnd.ms-powerpoint" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        imageSrc = "../../../resources/icons/ppt-icon.png";
      } else if (
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        imageSrc = "../../../resources/icons/word-icon.png";
      } else {
        imageSrc = thumbnails[i];
      }

      return (
        <ExamThumbnail
          key={i}
          index={i}
          removeThumbnail={removeThumbnail}
          image={imageSrc}
        />
      );
    });
  };

  const removeThumbnail = (i) => {
    let updatedFileList = files;
    let tempThumbnails = thumbnails;

    if (thumbnails.length >= 1) {
      updatedFileList[index].splice(i, 1);
      tempThumbnails.splice(i, 1);
    } else {
      updatedFileList[index] = [];
      tempThumbnails = [];
    }

    setThumbnails(Array.from(tempThumbnails));
    setFiles(updatedFileList);
  };

  const saveExpense = () => {
    let submissionLinks = [];

    const formData = new FormData();
    let i = 0;
    if (files.length) {
      files.forEach((file) => {
        let j = 0;
        submissionLinks.push([]);
        file.forEach((image) => {
          let blob = image.slice(0, image.size);
          let name = `expense-${i}-${j++}-${nanoid()}.${fileUtils.getFileExtension(
            image.name
          )}`;
          let newFile = new File([blob], name, { type: image.type });

          let link =
            calls.endpoint +
            `/resources/${envVars.DB_SCHOOL_NAME}/curriculum/` +
            name;
          submissionLinks[i].push(link);

          formData.append("arrayOfFilesName", newFile);
        });
        i++;
      });

      try {
        axios
          .post(calls.submitCurriculumImages, formData, {
            headers: { Authorization: tokenUtils.getBearerToken() },
          })
          .then((response) => {
            if (response.data) {
              let body = {
                amount: expenseAmount,
                type: selectedExpenseType,
                date: expenseDate,
                files: submissionLinks,
              };

              socketContext.socket.emit("addSchoolExpense", body);

              setNewExpenseTypeName("");
              setIsExpanded(false);
              setExpenseAmount();
              setSelectedExpenseType();
              setFiles([]);
              setThumbnails([]);
              setIsExpanded(false);
              document.body.style.overflowY = "auto";
            }
          });
      } catch (ex) {
        console.log(ex);
      }
    } else {
      let body = {
        amount: expenseAmount,
        type: selectedExpenseType,
        date: expenseDate,
      };

      socketContext.socket.emit("addSchoolExpense", body);

      setNewExpenseTypeName("");
      setIsExpanded(false);
      setExpenseAmount();
      setSelectedExpenseType();
    }
  };

  return (
    <div
      ref={containerRef}
      className={"add-expense " + (isExpanded ? "is-expanded" : "is-collapsed")}
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "add-expense__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="add-expense__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="add-expense__config-item">
              <span className="add-expense__config-item-label">
                Τύπος Εξοδολογίου
              </span>
              <div
                className={
                  "add-expense__config-select " + (dropdownOpen ? "open" : "")
                }
              >
                <div
                  className={
                    "dropdown-content open " + (dropdownOpen ? "open" : "")
                  }
                  ref={dropdownRef}
                >
                  {expensesTypes.length ? (
                    populateExpensesTypes()
                  ) : (
                    <div className="dropdown-content__item ">
                      <span>Προσθέστε τύπο εξοδολόγιου...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="add-expense__config-add">
              <input
                type="text"
                value={newExpenseTypeName}
                placeholder="Νέος τύπος εξοδολόγιου"
                onChange={(e) => {
                  setNewExpenseTypeName(e.target.value);
                }}
              />
              <div
                className="new-button cta"
                onClick={() => addNewExpenseType()}
              >
                {/* <BiPlus
                        size={"30px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                      /> */}
                Προσθήκη
              </div>
            </div>
            <div className="add-expense__config-item">
              <span className="add-expense__config-item-label">Ημερομηνία</span>
              <div className="add-expense__config-date">
                <input
                  className="time input"
                  type="date"
                  value={expenseDate}
                  onChange={(e) => setExpenseDate(e.target.value)}
                />
              </div>
            </div>
            <div className="add-curriculum__config-item">
              <span className="label">Ανέβασμα Αρχείων</span>
              <div className="add-curriculum__config-item-files">
                {hasCameraAccess !== 0 && (
                  <div className="add-curriculum__config-item-upload">
                    <BiUpload
                      size={"50px"}
                      color={"#ccc"}
                      style={iconStyle("transparent")}
                    />
                    <input
                      className="form-control configure__files-input"
                      type="file"
                      accept=".pdf, .jpeg, .jpg, .png, .mp3, .wav, .mp4, .ppt, .pptx, .wma, .docx, .doc"
                      name="myImage"
                      onChange={imageChange}
                      multiple
                    />
                  </div>
                )}
                {!hasCameraAccess && (
                  <span className="configure__files-error">
                    Ενεργοποιήστε τα δικαιώματα κάμερας για να έχετε πρόσβαση
                    στην κάμερα και στα αρχεία σας
                  </span>
                )}
                <div className="add-curriculum__config-item-thumbnails">
                  {populateThumbnails()}
                </div>
              </div>
            </div>
            <div className="add-expense__config-item">
              <span className="add-expense__config-item-label">Ποσό</span>
              <div className="add-expense__config-amount">
                <input
                  className="input"
                  placeholder="Ποσό"
                  value={expenseAmount}
                  onChange={(e) => setExpenseAmount(e.target.value)}
                />
                €
              </div>
            </div>

            <div className="add-expense__config-item save">
              <button className="cta" onClick={() => saveExpense()}>
                Αποθήκευση
              </button>
            </div>

            <div
              className="add-expense__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="add-expense__preview">
            <BiPlus
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>Προσθήκη Εξοδολόγιου</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default AddExpense;
