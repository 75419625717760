import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import "./event-comment.scss";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronRight,
  BiDesktop,
  BiPencil,
} from "react-icons/bi";
import { getGeneralSettingValue, iconStyle } from "../../../utils/generalUtils";

function parseLinks(text) {
  if (!text) {
    return ""; // Return an empty string if text is undefined or null
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a href={part} key={index} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    )
  );
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get the year, month, and day from the Date object
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Format and return the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
};

function EventComment({ selectedDate, event }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [lectureComment, setLectureComment] = useState();
  const [newLectureComment, setNewLectureComment] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const [homework, setHomework] = useState();
  const [newHomeWork, setNewHomework] = useState();
  const [homeworkEdit, setHomeworkEdit] = useState(false);

  const [generalComment, setGeneralComment] = useState();
  const [newGeneralComment, setNewGeneralComment] = useState();
  const [generalCommentEdit, setGeneralCommentEdit] = useState(false);

  useEffect(() => {
    const cleanUpGetEventComment = getLectureComment();
    return () => {
      cleanUpGetEventComment();
    };
  }, []);

  useEffect(() => {
    if (lectureComment) {
      setNewLectureComment(lectureComment.comment_value);
    }
  }, [lectureComment]);

  useEffect(() => {
    if (generalComment) {
      setNewGeneralComment(generalComment.comment_value);
    }
  }, [generalComment]);

  useEffect(() => {
    if (homework) {
      setNewHomework(homework.comment_value);
    }
  }, [homework]);

  const getLectureComment = () => {
    let args = {
      event_id: event.event_id,
      date: formatDate(selectedDate),
    };

    const getLectureCommentListener = (data) => {
      if (data && data[0]) {
        // setLectureComment(data[0]);
        data.map((comment) => {
          if (comment.comment_type == "curriculum") {
            setLectureComment(comment);
          } else if (comment.comment_type == "homework") {
            setHomework(comment);
          } else if (comment.comment_type == "general") {
            setGeneralComment(comment);
          }
        });
      }
      // if (!data || !data.length) {
      //   setIsEdit(true);
      //   setHomeworkEdit(true);
      //   setGeneralCommentEdit(true);
      // }
    };

    const refreshLectureCommentListener = () => {
      socketContext.socket.emit("getLectureComment", args);
    };

    socketContext.socket.on(
      "lectureComment" + event.event_id,
      getLectureCommentListener
    );
    socketContext.socket.emit("getLectureComment", args);
    socketContext.socket.on(
      "refreshLectureComment" + event.event_id,
      refreshLectureCommentListener
    );

    return () => {
      socketContext.socket.off("getLectureComment", getLectureCommentListener);
      socketContext.socket.off(
        "lectureComment" + event.event_id,
        getLectureCommentListener
      );
      socketContext.socket.off(
        "refreshLectureComment" + event.event_id,
        refreshLectureCommentListener
      );
    };
  };

  const saveLectureComment = (type) => {
    let newText = "";
    let commentId = 0;

    if (!type || type == "curriculum") {
      newText = newLectureComment;
      if (lectureComment) {
        commentId = lectureComment.lecture_comment_id;
      }
    } else if (type == "homework") {
      newText = newHomeWork;
      if (homework) {
        commentId = homework.lecture_comment_id;
      }
    } else if (type == "general") {
      newText = newGeneralComment;
      if (generalComment) {
        commentId = generalComment.lecture_comment_id;
      }
    }

    if (
      (!lectureComment && type == "curriculum") ||
      (!homework && type == "homework") ||
      (!generalComment && type == "general")
    ) {
      if (newText) {
        let body = {
          event_id: event.event_id,
          date: formatDate(selectedDate),
          comment: newText,
          type: type ? type : "curriculum",
        };

        socketContext.socket.emit("addLectureComment", body);
        setIsEdit(false);
      }
    } else {
      let body = {
        lecture_comment_id: commentId,
        event_id: event.event_id,
        comment: newText,
        type: type ? type : "curriculum",
      };

      socketContext.socket.emit("updateLectureComment", body);
      setIsEdit(false);
      setHomeworkEdit(false);
      setGeneralCommentEdit(false);
    }
  };

  return (
    <div
      className={
        "event-comment " +
        (getGeneralSettingValue(generalSettings, "lecture-multiple-comments")
          ? " multiple "
          : "")
      }
    >
      <div className="event-comment__section ">
        {!lectureComment ? (
          <div className="event-comment__new">
            <span className="event-comment__new-title">
              Εισάγετε διδακτέα ύλη
            </span>
            <div className="event-comment__new-input">
              <textarea
                className="input"
                placeholder="Διδακτέα ύλη..."
                value={newLectureComment}
                onChange={(e) => setNewLectureComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevents the default action of Enter key in a form
                    saveLectureComment("curriculum");
                  }
                }}
              ></textarea>
              <BiChevronRight
                onClick={() => saveLectureComment("curriculum")}
                size={"25px"}
                color={"#6225e6"}
                style={iconStyle("transparent")}
              />
            </div>
          </div>
        ) : isEdit ? (
          <div className="event-comment__new">
            <span className="event-comment__new-title">
              Επεξεργασία διδακτέας ύλης
            </span>
            <div className="event-comment__new-input">
              <textarea
                className="input"
                placeholder="Διδακτέα ύλη..."
                value={newLectureComment}
                onChange={(e) => setNewLectureComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevents the default action of Enter key in a form
                    saveLectureComment();
                  }
                }}
              ></textarea>
            </div>
            <div className="event-comment__new-save edit">
              <button
                className="cta cta-red"
                onClick={() => {
                  setIsEdit(false);
                  setNewLectureComment(lectureComment.comment_value);
                }}
              >
                Ακύρωση
              </button>
              <button className="cta" onClick={() => saveLectureComment()}>
                Αποθήκευση
              </button>
            </div>
          </div>
        ) : (
          <div className="event-comment__item">
            <span className="event-comment__item-title">Διδακτέα ύλη</span>
            <span className="event-comment__item-comment">
              {lectureComment ? parseLinks(lectureComment.comment_value) : ""}
            </span>
            <div
              className="event-comment__item-edit"
              onClick={() => setIsEdit(true)}
            >
              <BiPencil
                size={"25px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />
            </div>
          </div>
        )}
      </div>
      {getGeneralSettingValue(generalSettings, "lecture-multiple-comments") ? (
        <div className="event-comment__section ">
          {!homework ? (
            <div className="event-comment__new">
              <span className="event-comment__new-title">
                Εισάγετε διάβασμα για το σπίτι
              </span>
              <div className="event-comment__new-input">
                <textarea
                  className="input"
                  placeholder="Διάβασμα για το σπίτι..."
                  value={newHomeWork}
                  onChange={(e) => setNewHomework(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevents the default action of Enter key in a form
                      saveLectureComment("homework");
                    }
                  }}
                ></textarea>
                <BiChevronRight
                  onClick={() => saveLectureComment("homework")}
                  size={"25px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
              </div>
            </div>
          ) : homeworkEdit ? (
            <div className="event-comment__new">
              <span className="event-comment__new-title">
                Επεξεργασία Διάβασμα για το σπίτι
              </span>
              <div className="event-comment__new-input">
                <textarea
                  className="input"
                  placeholder="Διάβασμα για το σπίτι..."
                  value={newHomeWork}
                  onChange={(e) => setNewHomework(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevents the default action of Enter key in a form
                      saveLectureComment("homework");
                    }
                  }}
                ></textarea>
              </div>
              <div className="event-comment__new-save edit">
                <button
                  className="cta cta-red"
                  onClick={() => {
                    setHomeworkEdit(false);
                    setNewHomework(homework.comment_value);
                  }}
                >
                  Ακύρωση
                </button>
                <button
                  className="cta"
                  onClick={() => saveLectureComment("homework")}
                >
                  Αποθήκευση
                </button>
              </div>
            </div>
          ) : (
            <div className="event-comment__item">
              <span className="event-comment__item-title">
                Διάβασμα για το σπίτι
              </span>
              <span className="event-comment__item-comment">
                {homework ? parseLinks(homework.comment_value) : ""}
              </span>
              <div
                className="event-comment__item-edit"
                onClick={() => setHomeworkEdit(true)}
              >
                <BiPencil
                  size={"25px"}
                  color={"#ccc"}
                  style={iconStyle("transparent")}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {getGeneralSettingValue(generalSettings, "lecture-multiple-comments") ? (
        <div className="event-comment__section">
          {!generalComment ? (
            <div className="event-comment__new">
              <span className="event-comment__new-title">
                Εισάγετε γενικά σχόλια
              </span>
              <div className="event-comment__new-input">
                <textarea
                  className="input"
                  placeholder="Γενικό σχόλιο..."
                  value={generalComment}
                  onChange={(e) => setNewGeneralComment(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevents the default action of Enter key in a form
                      saveLectureComment("general");
                    }
                  }}
                ></textarea>
                <BiChevronRight
                  onClick={() => saveLectureComment("general")}
                  size={"25px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
              </div>
            </div>
          ) : generalCommentEdit ? (
            <div className="event-comment__new">
              <span className="event-comment__new-title">
                Επεξεργασία γενικών σχολίων
              </span>
              <div className="event-comment__new-input">
                <textarea
                  className="input"
                  placeholder="Γενικά σχόλια"
                  value={newGeneralComment}
                  onChange={(e) => setNewGeneralComment(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevents the default action of Enter key in a form
                      saveLectureComment("general");
                    }
                  }}
                ></textarea>
              </div>
              <div className="event-comment__new-save edit">
                <button
                  className="cta cta-red"
                  onClick={() => {
                    setGeneralCommentEdit(false);
                    setNewGeneralComment(generalComment.comment_value);
                  }}
                >
                  Ακύρωση
                </button>
                <button
                  className="cta"
                  onClick={() => saveLectureComment("general")}
                >
                  Αποθήκευση
                </button>
              </div>
            </div>
          ) : (
            <div className="event-comment__item">
              <span className="event-comment__item-title">Γενικά Σχόλια</span>
              <span className="event-comment__item-comment">
                {generalComment ? generalComment.comment_value : ""}
              </span>
              <div
                className="event-comment__item-edit"
                onClick={() => setGeneralCommentEdit(true)}
              >
                <BiPencil
                  size={"25px"}
                  color={"#ccc"}
                  style={iconStyle("transparent")}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default EventComment;
