import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../../../app/socket";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BiPlus, BiCheck, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { useSelector } from "react-redux";
import "./include-stats.scss";
import { usePrintUsersContext } from "../printUsersContext";

function IncludeStats() {
  const socketContext = useContext(SocketContext);
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const {
    includeExams,
    setIncludeExams,
    includeEvaluation,
    setIncludeEvaluation,
    includeTest,
    setIncludeTest,
    includeProgress,
    setIncludeProgress,
    includeUnderstanding,
    setIncludeUnderstanding,
    includeComments,
    setIncludeComments,
    includeDismissals,
    setIncludeDismissals,
  } = usePrintUsersContext();

  const generalSettings = useSelector((state) => state.profile.generalSettings);

  return (
    <div className="include-stats">
      <span className="include-stats__title">
        Επιλέξτε στατιστικά για εκτύπωση
      </span>
      <div className="target input-container">
        <span className="label fc">Στατιστικά Μαθητών:</span>
        <div className="target__select">
          <div className="item" onClick={() => setIncludeExams(!includeExams)}>
            <div
              className={
                "target__select-radio " + (includeExams ? "active" : "")
              }
            ></div>
            <span className="fc">Διαγωνίσματα</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeEvaluation(!includeEvaluation)}
          >
            <div
              className={
                "target__select-radio " + (includeEvaluation ? "active" : "")
              }
            ></div>
            <span className="fc">Αξιολόγηση</span>
          </div>
          <div className="item" onClick={() => setIncludeTest(!includeTest)}>
            <div
              className={
                "target__select-radio " + (includeTest ? "active" : "")
              }
            ></div>
            <span className="fc">Τεστ</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeProgress(!includeProgress)}
          >
            <div
              className={
                "target__select-radio " + (includeProgress ? "active" : "")
              }
            ></div>
            <span className="fc">Επίδοση Μαθήματος</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeUnderstanding(!includeUnderstanding)}
          >
            <div
              className={
                "target__select-radio " + (includeUnderstanding ? "active" : "")
              }
            ></div>
            <span className="fc">Κατανόηση Μαθήματος</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeComments(!includeComments)}
          >
            <div
              className={
                "target__select-radio " + (includeComments ? "active" : "")
              }
            ></div>
            <span className="fc">Σχόλια</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeDismissals(!includeDismissals)}
          >
            <div
              className={
                "target__select-radio " + (includeDismissals ? "active" : "")
              }
            ></div>
            <span className="fc">Απουσίες</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncludeStats;
