import React, { useEffect } from "react";
import { BiPlus, BiMinus, BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./grades-section.scss";
import { useEventContext } from "../../eventContext";
import { iconStyle } from "../../../../utils/generalUtils";

function GradesSection() {
  const { eventMaxRank, setEventMaxRank } = useEventContext();
  const {
    eventDetailedRank,
    setEventDetailedRank,
    eventDetailedNames,
    setEventDetailedNames,
  } = useEventContext();

  const changeDetailedRank = (value, index) => {
    let newValue = Number(value);
    const remainingRankExcludingCurrent =
      calculateRemainingRank() + Number(eventDetailedRank[index]);
    if (newValue > remainingRankExcludingCurrent && newValue >= 0) {
      newValue = remainingRankExcludingCurrent;
    }
    setEventDetailedRank((currentRanks) => {
      const updatedRanks = [...currentRanks];
      updatedRanks[index] = newValue;
      return updatedRanks;
    });
  };

  const calculateRemainingRank = () => {
    const totalUsedRank = eventDetailedRank.reduce(
      (acc, rank) => acc + Number(rank),
      0
    );
    return eventMaxRank - totalUsedRank;
  };

  const changeDetailedName = (value, index) => {
    let newValue = value;
    setEventDetailedNames((currentRanks) => {
      const updatedRanks = [...currentRanks];
      updatedRanks[index] = newValue;
      return updatedRanks;
    });
  };

  const populateDetailedRank = () => {
    return eventDetailedRank.map((rank, index) => {
      const remainingRankExcludingCurrent =
        calculateRemainingRank() + Number(rank);
      return (
        <div className="rank-item" key={"detailedRank" + index}>
          {/* <span className="rank-label">Θέμα {index + 1}</span> */}
          <input
            className="input rank-item__name"
            placeholder="Όνομα θέματος"
            value={eventDetailedNames[index]}
            onChange={(e) => changeDetailedName(e.target.value, index)}
          />
          <span className="rank-sub-label">Μέγιστος βαθμός</span>

          <div className="rank-item__input">
            <BiChevronDown
              size={"30px"}
              color={"#fff"}
              style={iconStyle("#d6d6d6")}
              onClick={() =>
                changeDetailedRank(eventDetailedRank[index] - 1, index)
              }
            />
            <input
              type="number"
              value={eventDetailedRank[index]}
              onChange={(e) => changeDetailedRank(e.target.value, index)}
            />
            <BiChevronUp
              size={"30px"}
              color={"#fff"}
              style={iconStyle("#d6d6d6")}
              onClick={() =>
                changeDetailedRank(eventDetailedRank[index] + 1, index)
              }
            />
          </div>
          {index !== 0 ? (
            <div className="remove">
              <BiMinus
                onClick={() => removeThema(index)}
                size={"40px"}
                color={"#fff"}
                style={iconStyle("#ff6f6f")}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const addNewThema = () => {
    const remainingRank = calculateRemainingRank();
    if (remainingRank > 0) {
      setEventDetailedRank((currentRanks) => [
        ...currentRanks,
        Math.min(0, remainingRank),
      ]);
    }
  };

  const removeThema = (index) => {
    setEventDetailedRank((currentRanks) => {
      const updatedRanks = [...currentRanks];
      updatedRanks.pop(index);
      return updatedRanks;
    });
  };

  return (
    <div className={"grades-section "}>
      <div className="max-rank">
        <span className="max-rank__label">Μέγιστη βαθμολογία</span>
        <div className="max-rank__wrapper">
          {/* <input type="number" className="input" placeholder="π.χ. 100" /> */}
          <div className="rank-item__input">
            <BiChevronDown
              size={"30px"}
              color={"#fff"}
              style={iconStyle("#d6d6d6")}
              onClick={() => setEventMaxRank(eventMaxRank - 1)}
            />
            <input
              type="number"
              value={eventMaxRank}
              onChange={(e) => setEventMaxRank(e.target.value)}
            />
            <BiChevronUp
              size={"30px"}
              color={"#fff"}
              style={iconStyle("#d6d6d6")}
              onClick={() => setEventMaxRank(eventMaxRank + 1)}
            />
          </div>
          <div className="grades-section__progress">
            <div
              className="total"
              style={{
                width: `${
                  (eventDetailedRank.reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  ) *
                    100) /
                  eventMaxRank
                }%`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="detailed-rank">
        <span className="detailed-rank__label">
          Διαμορφώστε τον αριθμό και τη βαθμολογία των θεμάτων
        </span>
        <div className="list">
          {populateDetailedRank()}
          {calculateRemainingRank() > 0 ? (
            <div className="list__add" onClick={() => addNewThema()}>
              <BiPlus
                size={"70px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
              <span className="label">Προσθήκη Θέματος</span>
            </div>
          ) : (
            <div className="list__limit">
              <span>
                Έχετε συμπληρώσει τον μέγιστο βαθμό διαγωνίματος. Για να
                προσθέστε θέμα μειώστε βαθμό σε κάποιο θέμα.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GradesSection;
