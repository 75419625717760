// Inspector.jsx
import React from "react";

function Inspector({
  editorData,
  selectedSectionId,
  selectedComponentId,
  updateComponent,
  updateSection,
}) {
  const section = editorData.sections.find(
    (s) => String(s.id) === String(selectedSectionId)
  );
  let component = null;

  if (section) {
    component = section.components.find(
      (c) => String(c.id) === String(selectedComponentId)
    );
  }

  if (section && !component && selectedSectionId) {
    return (
      <div style={{ padding: "1em" }}>
        <h3>Editing Section: {section.name}</h3>
        <label>
          Flex direction:
          <select
            value={section.styles?.flexDirection || "column"}
            onChange={(e) =>
              updateSection(section.id, {
                styles: { ...section.styles, flexDirection: e.target.value },
              })
            }
          >
            <option value="column">column</option>
            <option value="row">row</option>
          </select>
        </label>
      </div>
    );
  }

  if (component && selectedComponentId) {
    if (component.type === "text") {
      return (
        <div style={{ padding: "1em" }}>
          <h3>Editing Text</h3>
          <label>
            Content:
            <input
              type="text"
              value={component.content || ""}
              onChange={(e) =>
                updateComponent(section.id, component.id, {
                  content: e.target.value,
                })
              }
              style={{ marginLeft: "0.5em" }}
            />
          </label>
          <br />
          <label>
            Font Size:
            <input
              type="text"
              value={component.styles?.fontSize || ""}
              onChange={(e) =>
                updateComponent(section.id, component.id, {
                  styles: { ...component.styles, fontSize: e.target.value },
                })
              }
              style={{ marginLeft: "0.5em" }}
            />
          </label>
        </div>
      );
    } else if (component.type === "image") {
      return (
        <div style={{ padding: "1em" }}>
          <h3>Editing Image</h3>
          <label>
            Source:
            <input
              type="text"
              value={component.src || ""}
              onChange={(e) =>
                updateComponent(section.id, component.id, {
                  src: e.target.value,
                })
              }
              style={{ marginLeft: "0.5em" }}
            />
          </label>
          <br />
          <label>
            Width:
            <input
              type="text"
              value={component.styles?.width || ""}
              onChange={(e) =>
                updateComponent(section.id, component.id, {
                  styles: { ...component.styles, width: e.target.value },
                })
              }
              style={{ marginLeft: "0.5em" }}
            />
          </label>
        </div>
      );
    } else {
      return (
        <div style={{ padding: "1em" }}>
          <h3>Editing {component.type}</h3>
          <p>Not implemented yet.</p>
        </div>
      );
    }
  }

  return (
    <div style={{ padding: "1em" }}>No component or section selected.</div>
  );
}

export default Inspector;
