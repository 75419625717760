import React, { useRef, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { SocketContext } from "../../../app/socket";
import StatsLine from "../../Profile/TabSections/StudentStatistics/statsPrint/statsLine";
import { usePrintUsersContext } from "./printUsersContext";

function isBetweenDates(date, startAt, finishAt) {
  const tempDate = new Date(date);
  const localDate = new Date(
    tempDate.getTime() - tempDate.getTimezoneOffset() * 60000
  );
  const createdAt = new Date(localDate).toISOString().split("T")[0];
  const startDate = new Date(startAt).toISOString().split("T")[0];
  const finishDate = new Date(finishAt).toISOString().split("T")[0];

  return createdAt >= startDate && createdAt <= finishDate;
}

const monthsInGreek = [
  "Ιανουαρίου",
  "Φεβρουαρίου",
  "Μαρτίου",
  "Απριλίου",
  "Μαΐου",
  "Ιουνίου",
  "Ιουλίου",
  "Αυγούστου",
  "Σεπτεμβρίου",
  "Οκτωβρίου",
  "Νοεμβρίου",
  "Δεκεμβρίου",
];

function formatMySQLTimestamp(mysqlTimestamp) {
  const date = new Date(mysqlTimestamp);

  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 10);
}

function PrintUsersButton() {
  const schoolLogo = useSelector((state) => state.profile.schoolLogo);
  const htmlRef = useRef(null);
  const socketContext = useContext(SocketContext);

  const [students, setStudents] = useState([]);
  const [profileComments, setProfileComments] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [examsData, setExamsData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [generalTests, setGeneralTests] = useState([]);
  const [lectureProgress, setLectureProgress] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => htmlRef.current, // Reference to the element you want to print
    documentTitle: "Student Report", // Optional: Title for the print document
  });

  const {
    startAt,
    finishAt,
    selectedClasses,
    selectedGrades,
    includeExams,
    includeComments,
    progressMaxGrade,
    progressMinGrade,
    understandingMaxGrade,
    understandingMinGrade,
    printTitle,
    incldueProgress,
    includeUnderstanding,
    includeEvaluation,
    includeTest,
  } = usePrintUsersContext();

  useEffect(() => {
    const cleanUpGetProfileComments = getProfileComments();
    const cleanUpGetEvaluation = getEvaluation();
    const cleanUpGetExams = getUserExamsDetailed();
    const cleanUpGetMarkedTests = getMarkedTests();
    const cleanUpGetTotalLectureProgress = getTotalLectureProgress();
    return () => {
      cleanUpGetProfileComments();
      cleanUpGetEvaluation();
      cleanUpGetExams();
      cleanUpGetMarkedTests();
      cleanUpGetTotalLectureProgress();
    };
  }, []);

  const getTotalLectureProgress = () => {
    let args = {};

    const getTotalLectureProgressListener = (data) => {
      console.log(" ----- ");
      console.log(data);
      setLectureProgress(data);
    };

    socketContext.socket.on(
      "totalLectureProgress",
      getTotalLectureProgressListener
    );
    socketContext.socket.emit("getTotalLectureProgress", args);

    return () => {
      socketContext.socket.off(
        "getTotalLectureProgress",
        getTotalLectureProgressListener
      );
      socketContext.socket.off(
        "totalLectureProgress",
        getTotalLectureProgressListener
      );
    };
  };

  const getProfileComments = () => {
    let args = {};

    const getProfileCommentsListener = (data) => {
      setProfileComments(data);
    };

    const refreshProfileCommentsListener = () => {
      socketContext.socket.emit("getProfileComments", args);
    };

    socketContext.socket.on(
      "profileComments-stats",
      getProfileCommentsListener
    );
    socketContext.socket.emit("getProfileComments", args);
    socketContext.socket.on(
      "refreshProfileComments",
      refreshProfileCommentsListener
    );

    return () => {
      socketContext.socket.off(
        "getProfileComments",
        getProfileCommentsListener
      );
      socketContext.socket.off(
        "profileComments-stats",
        getProfileCommentsListener
      );
      socketContext.socket.off(
        "refreshProfileComments",
        refreshProfileCommentsListener
      );
    };
  };

  const getEvaluation = () => {
    let args = {};

    const getEvaluationListener = (data) => {
      setEvaluation(data);
    };

    socketContext.socket.on("evaluation", getEvaluationListener);
    socketContext.socket.emit("getEvaluation", args);

    return () => {
      socketContext.socket.off("getEvaluation", getEvaluationListener);
      socketContext.socket.off("evaluation", getEvaluationListener);
    };
  };

  const getUserExamsDetailed = () => {
    let args = {};
    const getUserExamsDetailedListener = (data) => {
      const temp = [];
      if (data && data.length) {
        data.map((exam, index) => {
          if (index <= 20) {
            if (!exam.score) {
              exam.score = 0;
            }
            temp.push(exam);
          }
        });
      }
      setExamsData(temp); // This line populates examsData with initial data
    };

    socketContext.socket.on("examsDetailed", getUserExamsDetailedListener);
    socketContext.socket.emit("getUserExamsDetailed", args);

    return () => {
      socketContext.socket.off(
        "getUserExamsDetailed",
        getUserExamsDetailedListener
      );
      socketContext.socket.off("examsDetailed", getUserExamsDetailedListener);
    };
  };

  const getMarkedTests = () => {
    let args = {};

    const getMarkedExamsListener = (data) => {
      setGeneralTests(data);
    };

    socketContext.socket.on("generalTests", getMarkedExamsListener);
    socketContext.socket.emit("getGeneralTests", args);

    const refreshGeneraltestsListener = () => {
      socketContext.socket.emit("getGeneralTests", args);
    };
    socketContext.socket.on("refreshGeneralTests", refreshGeneraltestsListener);

    return () => {
      socketContext.socket.off("generalTests", getMarkedExamsListener);
      socketContext.socket.off(
        "refreshGeneralTests",
        refreshGeneraltestsListener
      );
    };
  };

  useEffect(() => {
    const cleanUpGetGetUsersInGrades = getUsersInGrades();
    return () => {
      cleanUpGetGetUsersInGrades();
    };
  }, [selectedClasses, selectedGrades]);

  const getUsersInGrades = () => {
    let args = { classes: selectedClasses, grades: selectedGrades };

    const getUsersInGradesListener = (data) => {
      setStudents(data);
    };

    const refreshUserGradesListener = () => {
      socketContext.socket.emit("getUsersInGrades", args);
    };

    socketContext.socket.on("usersInGrades", getUsersInGradesListener);
    socketContext.socket.emit("getUsersInGrades", args);
    socketContext.socket.on("refreshUsersInGrades", refreshUserGradesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getUsersInGrades", getUsersInGradesListener);
      socketContext.socket.off("usersInGrades", getUsersInGradesListener);
      socketContext.socket.off(
        "refreshUsersInGrades",
        refreshUserGradesListener
      );
    };
  };

  const populateComments = (studentId) => {
    return profileComments
      .filter((com) => com.to_user_id == studentId)
      .map((comment) => {
        if (isBetweenDates(comment.created_at, startAt, finishAt)) {
          let profName = comment.last_name + " " + comment.first_name;
          let commentTitle = comment.title;
          let commentDate = formatMySQLTimestamp(comment.created_at);
          let commentValue = comment.description;
          let commentClass = comment.class_name ? comment.class_name : "Γενικό";
          return `<tr>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${commentTitle}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${commentClass}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${profName}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${commentValue}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${commentDate}</td>
    </tr>`;
        }
      });
  };

  const populateExams = (studentId) => {
    let examsToLoop = examsData;
    if (window.location.href.includes("simmetria")) {
      examsToLoop = examsData
        .slice() // Create a shallow copy to avoid mutating the original state
        .sort((a, b) => a.class_name.localeCompare(b.class_name));
    }
    return examsData
      .filter((exam) => exam.user_id == studentId)
      .map((exam) => {
        if (isBetweenDates(exam.event_date, startAt, finishAt)) {
          let profName = exam.prof_last_name + " " + exam.prof_first_name;
          let examDate = formatMySQLTimestamp(exam.submitted_at);
          let examScore = exam.score + " / " + exam.max_rank;
          if (exam.not_marked) {
            examScore =
              window.location.hostname.includes("simmetria") ||
              window.location.hostname.includes("localhost")
                ? "Ανεπαρκές"
                : "Αβαθμολόγητο";
          }
          if (exam.no_show) {
            examScore = "Δεν προσήλθε";
          }
          let examTitle = exam.exam_title;
          let examClass = exam.class_name;
          let examComment = exam.result_text;
          return `<tr>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${examClass}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${profName}</td>
      <td width="20%" style="padding: 10px; word-break: break-word;  text-align: right; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${examScore}</td>
    </tr>`;
        }
      });
  };

  const groupByClassId = (tests) => {
    return tests.reduce((groups, test) => {
      const { class_id } = test;
      if (!groups[class_id]) {
        groups[class_id] = [];
      }
      groups[class_id].push(test);
      return groups;
    }, {});
  };

  // Helper function to sort by date
  const sortByDate = (tests) => {
    return tests.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  useEffect(() => {
    let temp = [];
    lectureProgress.map((prog) => {
      if (prog.type == "progress") {
        if (prog.progress_grade > parseInt(progressMinGrade)) {
          temp.push(prog);
        }
      } else {
        if (prog.progress_grade > parseInt(understandingMinGrade)) {
          temp.push(prog);
        }
      }
    });
    setLectureProgress(temp);
  }, [progressMinGrade, understandingMinGrade]);

  const populateLectureProgress = (type, studentId) => {
    const groupedTests = groupByClassId(
      lectureProgress.filter(
        (prog) => prog.type == type && prog.user_id == studentId
      )
    ); // Group by class_id

    return Object.keys(groupedTests).map((class_id) => {
      const sortedTests = sortByDate(groupedTests[class_id]);
      const className = sortedTests[0].class_name; // Assuming all have the same class_name

      // Separate progress and understanding types
      const progressTests = sortedTests.filter(
        (test) =>
          test.type === "progress" &&
          isBetweenDates(test.date, startAt, finishAt)
      );
      const understandingTests = sortedTests.filter(
        (test) =>
          test.type === "understanding" &&
          isBetweenDates(test.date, startAt, finishAt)
      );

      return (
        <div key={class_id} style={{ width: "100%" }}>
          <h5>{className}</h5> {/* Use class_name in the header */}
          {progressTests.length > 0 && (
            <StatsLine
              data={progressTests}
              type="progress"
              style={{ width: "100%" }}
            />
          )}
          {understandingTests.length > 0 && (
            <StatsLine
              data={understandingTests}
              type="understanding"
              style={{ width: "100%" }}
            />
          )}
        </div>
      );
    });
  };

  const populateEvaluation = (studentId) => {
    let evaluationToLoop = evaluation;
    if (window.location.href.includes("simmetria")) {
      evaluationToLoop = evaluation
        .slice() // Create a shallow copy to avoid mutating the original state
        .sort((a, b) => a.class_name.localeCompare(b.class_name));
    } else if (window.location.href.includes("orama")) {
      // Step 1: Group by class_id
      const groupedByClassId = evaluation.reduce((acc, item) => {
        acc[item.class_id] = acc[item.class_id] || [];
        acc[item.class_id].push(item);
        return acc;
      }, {});

      // Step 2: Sort each group by month
      for (const classId in groupedByClassId) {
        groupedByClassId[classId].sort((a, b) => a.month - b.month);
      }

      // Step 3: Flatten the grouped and sorted structure back into a single array
      evaluationToLoop = Object.values(groupedByClassId).flat();
    }
    return evaluationToLoop
      .filter((eva) => eva.student_id == studentId)
      .map((eva) => {
        if (isBetweenDates(eva.date, startAt, finishAt)) {
          let evaTitle = eva.title ? eva.title : "";
          let evaDate = formatMySQLTimestamp(eva.date);
          let vocalGrade = eva.vocal_grade;
          let examGrade = eva.exam_grade;
          let testGrade = eva.test_grade;
          let month = monthsInGreek[eva.month ? eva.month : 0];
          if (window.location.href.includes("orama")) {
            return `<tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${month}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${
            eva.creator_last_name
          }</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${
            eva.class_name
          }</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${vocalGrade} ${
              vocalGrade ? "/ 20" : ""
            }</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${testGrade} ${
              testGrade ? "/ 100" : ""
            }</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${examGrade} ${
              examGrade ? "/ 100" : ""
            }</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${
            eva.general_comments
          }</td>
        </tr>`;
          } else if (window.location.href.includes("chionidou")) {
            let classGrade = eva.class_grade;
            return `<tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Class Grade</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${classGrade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">HomeWork</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.homework_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Vocabulary</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.vocabulary_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Grammar</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.grammar_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Reading</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.reading_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Writing</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.writing_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>`;
          } else if (window.location.href.includes("linguacademy")) {
            let classGrade = eva.class_grade;
            return `<tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Γραμματική</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.grammar_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Λεξιλόγιο</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.vocabulary_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Προφορικά</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.vocal_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Ακουστική Κατανόηση</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.listening_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Διαγωνίσματα</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.exam_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>`;
          } else if (window.location.href.includes("simmetria")) {
            return `<tr>
      <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.class_name}</td>
      <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaDate}</td>
      <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
      <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${vocalGrade} / 20</td>
    </tr>`;
          } else {
            return `<tr>
      <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
      <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaDate}</td>
      <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
      <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${vocalGrade} / 20</td>
    </tr>`;
          }
        }
      });
  };

  const populateTest = (studentId) => {
    const sortedTests = [...generalTests].sort((a, b) => {
      if (a.class_id < b.class_id) return -1;
      if (a.class_id > b.class_id) return 1;
      return 0;
    });

    return sortedTests
      .filter((test) => test.student_id == studentId)
      .map((test) => {
        if (isBetweenDates(test.date, startAt, finishAt)) {
          const profName = `${test.last_name} ${test.first_name}`;
          const testDate = formatMySQLTimestamp(test.date);
          let testScore = `${test.grade} / ${test.max_rank}`;
          const testClass = test.class_name;
          if (test.grade == 0) {
            testScore = "Ανεπαρκές";
          }

          return `<tr>
          <td width="25%" style="padding: 10px; word-break: break-word; border: 1px solid #dddddd;">${testClass}</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border: 1px solid #dddddd;">${testDate}</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border: 1px solid #dddddd;">${profName}</td>
          <td width="25%" style="padding: 10px; word-break: break-word; text-align: right; border: 1px solid #dddddd;">${testScore}</td>
        </tr>`;
        }
        return null; // Ensure that the map always returns something
      })
      .filter((row) => row !== null); // Remove any null entries
  };

  const populateTemplates = () => {
    return students.map((student) => {
      let htmlTemplate = `<!DOCTYPE html>
        <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
        <head>
          <title></title>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&subset=greek" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" type="text/css" />
          <style>
            * {
              box-sizing: border-box;
            }

            .section-print {
              page-break-before: always;
            }  
        
            body {
              margin: 0;
              max-width: 100%; /* Prevent body overflow */
              width: 100%;
              font-family: 'Montserrat', sans-serif;
            }
        
            .nl-container {
              width: 100%;
              max-width: 900px;
              margin: 0 auto;
              padding: 0;
            }
        
            table {
              width: 100%;
              border-collapse: collapse;
            }
        
            /* Header styling */
            .header-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
        
            .header-container img {
              max-width: 300px;
              max-height: 200px;
              margin-bottom: 20px;
            }
        
            .header-title {
              font-size: 28px;
              font-weight: bold;
              margin-bottom: 5px;
            }
        
            .header-period {
              font-size: 16px;
              color: #666;
            }
        
            /* Student image and info */
            .student-wrapper {
              display: flex;
              align-items: center;
              gap: 20px;
              margin-bottom: 20px;
            }
        
            .student-wrapper img {
              width: 140px !important;
              height: 140px !important;
              object-fit: cover;
              border-radius: 50%;
            }
        
            .student-info {
              font-size: 18px;
              color: #7747FF;
            }
        
            /* Tables */
            table {
              border-collapse: collapse;
              width: 100%;
              font-size: 14px;
            }
        
            th, td {
              padding: 10px;
              text-align: left;
            }
        
            thead {
              background-color: #f2f2f2;
            }
        
            /* Remove unwanted dots */
            td:empty::before {
              content: none !important;
            }
        
            /* Print styles */
            @media print {
              /* Force same weight across different subsets */
                body {
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400; /* You can experiment with other weights */
                }
        
                :lang(el) {
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400; /* Match the weight used for other characters */
                }
        
              body, .nl-container {
                margin: 0;
                padding: 0;
                width: 100%;
                max-width: 900px;
                overflow: visible;
              }
        
              .nl-container {
                width: 100% !important;
                max-width: 900px !important;
              }
        
              .row-content {
                width: 100% !important;
                max-width: 900px !important;
                margin: 0 auto;
              }
        
              table {
                max-width: 100% !important;
                width: 100% !important;
                height: auto !important;
              }
        
              .desktop_hide, .mobile_hide {
                display: none !important;
              }
              .student-wrapper {
                width: 100%;
              }
              .student-wrapper__img {
                width: 200px;
              }
              .student-wrapper img {
                width: 100px !important;
                height: 100px !important;
                object-fit: cover;
                border-radius: 50% !important;
              }
            }
          </style>
        </head>
        
        <body class="body section-print" style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
          <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;">
            <tbody>
              <tr>
                <td>
                  <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                    <tbody>
                      <tr>
                        <td>
                          <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                            <tbody>
                              <tr class="student-wrapper">
                                <td class="column column-1" width="25%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                  <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td class="student-wrapper__img">
                                        <h1 style="margin: 0; color: #7747FF; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 700; letter-spacing: normal;  text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 25.2px;"><span class="tinyMce-placeholder" style="word-break: break-word;">
                                        <img
              src="${
                student.profile_picture
                  ? student.profile_picture
                  : "resources/student.png"
              }"
              alt="student"
              style="width:100px;height:100px;"
            /></span></h1>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                  <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td class="pad">
                                        <h1 style="margin: 0; color: #7747FF; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 38px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 45.6px;"><span class="tinyMce-placeholder" style="word-break: break-word;">${
                                          student.first_name +
                                          " " +
                                          student.last_name
                                        }<br></span></h1>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  ${
                    includeExams
                      ? `<table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                  <tbody>
                    <tr>
                      <td>
                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                          <tbody>
                            <tr>
                              <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <h1 style="margin: 0; color: #000000; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 20px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 24px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Διαγωνίσματα:</span></h1>
                                    </td>
                                  </tr>
                                </table>
                                <table class="table_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; width: 100%; table-layout: fixed; direction: ltr; background-color: transparent; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 400; color: #101112; text-align: left; letter-spacing: 0px; word-break: break-all;" width="100%">
                                        <thead style="vertical-align: top; background-color: #f2f2f2; color: #101112; font-size: 14px; line-height: 120%; text-align: center;">
                                          <tr>
                                            <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Μάθημα<br></th>
                                            <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Καθηγητής<br></th>
                                            <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός<br></th>
                                          </tr>
                                        </thead>
                                        <tbody style="vertical-align: top; font-size: 16px; line-height: 120%;">
                                        ${populateExams(student.user_id)}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>`
                      : ``
                  }
                  ${
                    includeEvaluation
                      ? `<table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                  <tbody>
                    <tr>
                      <td>
                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                          <tbody>
                            <tr>
                              <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <h1 style="margin: 0; color: #000000; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 20px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 24px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Αξιολόγηση:</span></h1>
                                    </td>
                                  </tr>
                                </table>
                                <table class="table_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; width: 100%; table-layout: fixed; direction: ltr; background-color: transparent; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 400; color: #101112; text-align: left; letter-spacing: 0px; word-break: break-all;" width="100%">
                                        <thead style="vertical-align: top; background-color: #f2f2f2; color: #101112; font-size: 14px; line-height: 120%; text-align: center;">
                                          ${
                                            window.location.href.includes(
                                              "orama"
                                            )
                                              ? `<tr>
                                          <th width="16%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Μήνας<br></th>
                                          <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Καθηγητής<br></th>
                                          <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Μάθημα<br></th>
                                          <th width="10%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός Προφ.<br></th>
                                          <th width="11%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός Τεστ<br></th>
                                          <th width="11%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός Διαγ.<br></th>
                                          <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Σχόλιο<br></th>
                                        </tr>`
                                              : window.location.href.includes(
                                                  "chionidou"
                                                ) ||
                                                window.location.href.includes(
                                                  "linguacademy"
                                                )
                                              ? `<tr>
                                            <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Περίοδος<br></th>
                                            <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Τύπος βαθμού<br></th>
                                            <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός<br></th>
                                            <th width="50%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Σχόλιο<br></th>
                                          </tr>`
                                              : window.location.href.includes(
                                                  "simmetria"
                                                )
                                              ? `<tr>
                                      <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Μάθημα<br></th>
                                      <th width="10%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Ημερομηνία<br></th>
                                      <th width="25%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Σχόλιο<br></th>
                                      <th width="10%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Προφ. Βαθμός<br></th>
                                    </tr>`
                                              : `<tr>
                                          <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Τίτλος<br></th>
                                          <th width="10%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Ημερομηνία<br></th>
                                          <th width="25%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Σχόλιο<br></th>
                                          <th width="10%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός<br></th>
                                        </tr>`
                                          }
                                        </thead>
                                        <tbody style="vertical-align: top; font-size: 16px; line-height: 120%;">
                                        ${populateEvaluation(student.user_id)}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>`
                      : ``
                  }
                  ${
                    includeTest
                      ? ` <table class="section-print row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                      <tbody>
                        <tr>
                          <td>
                            <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                              <tbody>
                                <tr>
                                  <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                    <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                      <tr>
                                        <td class="pad">
                                          <h1 style="margin: 0; color: #000000; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 20px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 24px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Τεστ:</span></h1>
                                        </td>
                                      </tr>
                                    </table>
                                    <table class="table_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                      <tr>
                                        <td class="pad">
                                          <table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; width: 100%; table-layout: fixed; direction: ltr; background-color: transparent; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 400; color: #101112; text-align: left; letter-spacing: 0px; word-break: break-all;" width="100%">
                                            <thead style="vertical-align: top; background-color: #f2f2f2; color: #101112; font-size: 14px; line-height: 120%; text-align: center;">
                                              <tr>
                                                <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Μάθημα<br></th>
                                                <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Ημερομηνία<br></th>
                                                <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Καθηγητής<br></th>
                                                <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός<br></th>
                                              </tr>
                                            </thead>
                                            <tbody style="vertical-align: top; font-size: 16px; line-height: 120%;">
                                            ${populateTest(student.user_id)}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>`
                      : ``
                  }
                  ${
                    includeComments
                      ? `<table class="row row-9" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                  <tbody>
                    <tr>
                      <td>
                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                          <tbody>
                            <tr>
                              <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <h1 style="margin: 0; color: #000000; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 20px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 24px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Σχόλια:</span></h1>
                                    </td>
                                  </tr>
                                </table>
                                <table class="table_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; width: 100%; table-layout: fixed; direction: ltr; background-color: transparent; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 400; color: #101112; text-align: left; letter-spacing: 0px; word-break: break-all;" width="100%">
                                        <thead style="vertical-align: top; background-color: #f2f2f2; color: #101112; font-size: 14px; line-height: 120%; text-align: center;">
                                          <tr>
                                            <th width="16.666666666666668%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Τίτλος<br></th>
                                            <th width="16.666666666666668%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Μάθημα<br></th>
                                            <th width="16.666666666666668%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Καθηγητής/τρια</th>
                                            <th width="16.666666666666668%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Σχόλιο<br></th>
                                            <th width="16.666666666666668%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Ημερομηνία</th>
                                          </tr>
                                        </thead>
                                        <tbody style="vertical-align: top; font-size: 16px; line-height: 120%;">
                                          ${populateComments(student.user_id)}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                                <table class="divider_block block-3" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <div class="alignment" align="center">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                          <tr>
                                            <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #dddddd;"><span style="word-break: break-word;">&#8202;</span></td>
                                          </tr>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>`
                      : ``
                  }
                     
                  </td>
              </tr>
            </tbody>
          </table><!-- End -->
        </body>
        
        </html>
            `;

      return (
        <>
          <div
            key={"dangerous html " + student.user_id}
            dangerouslySetInnerHTML={{ __html: htmlTemplate }}
          />
          <div className="html-container__graph" style={{ padding: "0 40px" }}>
            {populateLectureProgress("progress", student.user_id)}
          </div>
        </>
      );
    });
  };

  return (
    <div className="print-users-button">
      <div className="cta" onClick={handlePrint}>
        Εκτύπωση
      </div>
      <div className="html-container" ref={htmlRef}>
        {populateTemplates()}
      </div>
    </div>
  );
}

export default PrintUsersButton;
