import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import "./announcements.scss";
import { motion } from "framer-motion";
import { BiPlus, BiX } from "react-icons/bi";
import NewAnnouncement from "./newAnnouncement";
import { SocketContext } from "../../app/socket";
import AnnouncementItem from "./announcementItem";
import LoaderCard from "../LoaderCard/loaderCard";
import { useLocation } from "react-router-dom";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import { useTranslation } from "react-i18next";

function Announcments() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const announcementParam = searchParams.get("announcement-id");
  const [announcementToEdit, setAnnouncementToEdit] = useState();
  const { t } = useTranslation();

  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    if (showAddModal) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
    } else {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [showAddModal]);

  const openAddModal = () => {
    if (!showAddModal) {
      setShowAddModal(true);
    }
  };

  const [announcements, setAnnouncements] = useState([]);
  const [gotAnnouncements, setGotAnnouncements] = useState(false);

  useEffect(() => {
    setGotAnnouncements(false);
    const cleanUpGetAnnouncements = getAnnouncements();
    return () => {
      if (getGeneralSettingValue(generalSettings, "announcement_calendar")) {
        cleanUpGetAnnouncements();
      }
    };
  }, []);

  const getAnnouncements = () => {
    let args = {};

    const getAnnouncementsListener = (data) => {
      setGotAnnouncements(true);
      setAnnouncements(data);
    };

    const refreshAnnouncementsListener = () => {
      console.log(" refreshed announcements ");
      socketContext.socket.emit("getAnnouncements", args);
    };

    socketContext.socket.on("announcements", getAnnouncementsListener);
    socketContext.socket.emit("getAnnouncements", args);
    socketContext.socket.on(
      "refreshAnnouncements",
      refreshAnnouncementsListener
    );

    return () => {
      socketContext.socket.off("getAnnouncements", getAnnouncementsListener);
      socketContext.socket.off("announcements", getAnnouncementsListener);
      socketContext.socket.off(
        "refreshAnnouncements",
        refreshAnnouncementsListener
      );
    };
  };

  const populateAnnouncements = () => {
    return announcements.map((announcement, i) => {
      return (
        <AnnouncementItem
          key={"announcemntItem" + announcement.announcement_id}
          announcement={announcement}
          announcementParam={announcementParam}
          setAnnouncementToEdit={setAnnouncementToEdit}
        />
      );
    });
  };

  const testNotification = () => {
    // const body = {
    //   user_id:
    // }
    // socketContext.socket.emit("getAnnouncements", body);
  };

  return (
    <div
      className={
        "announcements " +
        (profile.user_type == "student" && !announcements.length ? " hide" : "")
      }
    >
      {/* <button
        onClick={() => {
          testNotification();
        }}
      >
        {t("announcements_title")}
      </button> */}
      {showAddModal ? (
        <div
          className="modal-background show"
          onClick={() => {
            setShowAddModal(false);
            setAnnouncementToEdit();
          }}
        ></div>
      ) : (
        ""
      )}
      <div className="announcements__title">
        <span className="announcements__title-label fc">
          {t("announcements_title")}
        </span>
        {profile.user_type !== "student" &&
          profile.user_type !== "parent" &&
          (!getGeneralSettingValue(generalSettings, "professors_not_create") ||
            profile.user_type != "professor") && (
            <motion.div
              layout
              className={"announcements__add" + (showAddModal ? " open" : "")}
              onClick={() => openAddModal()}
              initial={"closed"}
              animate={showAddModal ? "open" : "closed"}
              exit={"closed"}
              whileHover={
                !showAddModal
                  ? { scale: 1.03, transition: { duration: 0.25 } }
                  : {}
              }
            >
              {!showAddModal && (
                <motion.div
                  layout="position"
                  className="announcements__add-plus"
                >
                  <BiPlus
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                </motion.div>
              )}
              {showAddModal || announcementToEdit ? (
                <motion.div layout="position" className="configure">
                  <NewAnnouncement
                    setShowAddModal={setShowAddModal}
                    announcements={announcements}
                    setAnnouncements={setAnnouncements}
                    announcementToEdit={announcementToEdit}
                    setAnnouncementToEdit={setAnnouncementToEdit}
                  />
                  <div
                    className="announcements__add-close"
                    onClick={() => {
                      setShowAddModal(false);
                      setAnnouncementToEdit(false);
                    }}
                  >
                    <BiX
                      size={"40px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                  </div>
                </motion.div>
              ) : (
                ""
              )}
            </motion.div>
          )}
      </div>
      <div className="announcements__list">
        {gotAnnouncements && populateAnnouncements()}
        {!gotAnnouncements && (
          <LoaderCard cardSum={2} width={100} size="large" />
        )}
      </div>
    </div>
  );
}

export default Announcments;
