import { Line } from "react-chartjs-2";
import "chart.js/auto";

const progressLabels = [
  "", // Index 0
  "", // Index 1
  "Ελάχιστη Προσπάθεια", // Index 2
  "Μέτρια Προσπάθεια", // Index 3
  "Καλή Προσπάθεια", // Index 4
  "Άριστη Προσπάθεια", // Index 5
];

const StatsLine = ({ data, type }) => {
  console.log(" ----=======0------- ");
  console.log(data);
  console.log(type);
  const chartData = {
    labels: data.map((test) => new Date(test.date).toLocaleDateString()), // X-axis: formatted dates
    datasets: [
      {
        label: type === "progress" ? "Επίδοση" : "Κατανόηση", // Label for the graph
        data: data.map((test) => test.progress_grade), // Y-axis: progress_grade values
        borderColor: "#6225e6", // Line color
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 1.5, // Start y-axis at 1 (corresponding to "Καμία Προσπάθεια")
        max: 5.5, // End y-axis at 5 (corresponding to "Άριστη Προσπάθεια")
        ticks: {
          callback: function (value) {
            return progressLabels[value]; // Use custom labels for y-axis
          },
        },
        title: {
          display: true,
          text: type == "progress" ? "Προσπάθεια" : "Κατανόηση", // Y-axis title
        },
      },
      x: {
        title: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return <Line data={chartData} options={options} style={{ width: "595px" }} />;
};

export default StatsLine;
