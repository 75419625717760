import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../app/socket";
import "./class-selector.scss";
import { BiChevronsDown, BiChevronsUp } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import { useTranslation } from "react-i18next";

function ClassSelector({
  classes,
  selectedGrades,
  setSelectedGrades,
  selectedClasses,
  setSelectedClasses,
  setSelectedClassId,
  isSingle,
}) {
  const { t } = useTranslation();
  const socketContext = useContext(SocketContext);
  const [grades, setGrades] = useState([]);
  const [openGrades, setOpenGrades] = useState({});

  useEffect(() => {
    getGrades();
  }, []);

  const getGrades = () => {
    let args = {};

    const getGradesListener = (data) => {
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);
    socketContext.socket.on("refreshGrades", () => {
      socketContext.socket.emit("getGrades", args);
    });

    return () => {
      socketContext.socket.off("grades", getGradesListener);
    };
  };

  const handleGradeClick = (gradeId) => {
    if (isSingle) {
      handleToggleClick(gradeId);
      return;
    }

    if (selectedGrades.includes(gradeId)) {
      setSelectedGrades((prev) => prev.filter((id) => id !== gradeId));
    } else {
      setSelectedGrades((prev) => [...prev.filter((id) => id !== -1), gradeId]);
      setSelectedClasses((prev) =>
        prev.filter((cls) => {
          const clsGrade = classes.find((c) => c.class_id === cls);
          return clsGrade && clsGrade.grade_id !== gradeId;
        })
      );
    }
  };

  const handleToggleClick = (gradeId) => {
    setOpenGrades((prev) => ({
      ...prev,
      [gradeId]: !prev[gradeId],
    }));
  };

  const handleClassClick = (classId) => {
    const classGradeId = classes.find(
      (cls) => cls.class_id === classId
    ).grade_id;

    if (isSingle) {
      setSelectedClasses((prev) => (prev.includes(classId) ? [] : [classId]));
      setSelectedGrades([]);
    } else {
      let newSelectedClasses;
      if (selectedClasses.includes(classId)) {
        // Deselect the class
        newSelectedClasses = selectedClasses.filter((id) => id !== classId);
      } else {
        // Select the class
        newSelectedClasses = [
          ...selectedClasses.filter((id) => id !== -1),
          classId,
        ];
        // Ensure the grade is not selected
        setSelectedGrades((prev) => prev.filter((id) => id !== classGradeId));
      }
      setSelectedClasses(newSelectedClasses);

      // After updating selected classes, check if all classes of the grade are selected
      const classesOfGrade = classes
        .filter((cls) => cls.grade_id === classGradeId)
        .map((cls) => cls.class_id);

      const allClassesSelected = classesOfGrade.every((id) =>
        newSelectedClasses.includes(id)
      );

      if (allClassesSelected) {
        // Deselect all classes of this grade
        newSelectedClasses = newSelectedClasses.filter(
          (id) => !classesOfGrade.includes(id)
        );
        setSelectedClasses(newSelectedClasses);

        // Select the grade
        setSelectedGrades((prev) => [
          ...prev.filter((id) => id !== -1),
          classGradeId,
        ]);
      }
    }
  };

  const handleSelectAll = () => {
    setSelectedGrades([-1]);
    setSelectedClasses([-1]);
  };

  const renderClasses = (gradeId) => {
    return classes
      .filter((cls) => cls.grade_id === gradeId)
      .map((cls) => (
        <div
          key={cls.class_id}
          className={`class-selector__class ${
            selectedClasses.includes(cls.class_id) ? "selected" : ""
          }`}
          onClick={() => handleClassClick(cls.class_id)}
        >
          {cls.class_name}
        </div>
      ));
  };

  const isSelectAllSelected =
    selectedGrades.length === 1 &&
    selectedGrades[0] === -1 &&
    selectedClasses.length === 1 &&
    selectedClasses[0] === -1;

  // New useEffect to expand grades with partially selected classes
  useEffect(() => {
    if (
      !isSingle &&
      grades.length > 0 &&
      classes.length > 0 &&
      selectedClasses.length > 0
    ) {
      let updatedOpenGrades = { ...openGrades };
      let hasChanges = false;

      grades.forEach((grade) => {
        const classesOfGrade = classes
          .filter((cls) => cls.grade_id === grade.grade_id)
          .map((cls) => cls.class_id);

        const selectedClassesOfGrade = selectedClasses.filter((id) =>
          classesOfGrade.includes(id)
        );

        if (
          selectedClassesOfGrade.length > 0 &&
          selectedClassesOfGrade.length < classesOfGrade.length &&
          !openGrades[grade.grade_id]
        ) {
          updatedOpenGrades[grade.grade_id] = true;
          hasChanges = true;
        }
      });

      if (hasChanges) {
        setOpenGrades(updatedOpenGrades);
      }
    }
  }, [selectedClasses, grades, classes, isSingle]);

  // Existing useEffect to handle initial selection logic
  useEffect(() => {
    if (
      !isSingle &&
      grades.length > 0 &&
      classes.length > 0 &&
      selectedClasses.length > 0
    ) {
      let updatedSelectedClasses = [...selectedClasses];
      let updatedSelectedGrades = [...selectedGrades];
      let hasChanges = false;

      grades.forEach((grade) => {
        const classesOfGrade = classes
          .filter((cls) => cls.grade_id === grade.grade_id)
          .map((cls) => cls.class_id);

        const allClassesSelected = classesOfGrade.every((id) =>
          updatedSelectedClasses.includes(id)
        );

        if (allClassesSelected) {
          // Deselect all classes of this grade
          updatedSelectedClasses = updatedSelectedClasses.filter(
            (id) => !classesOfGrade.includes(id)
          );

          // Select the grade
          if (!updatedSelectedGrades.includes(grade.grade_id)) {
            updatedSelectedGrades = [...updatedSelectedGrades, grade.grade_id];
          }
          hasChanges = true;
        }
      });

      // Only update state if changes were made
      if (hasChanges) {
        setSelectedClasses(updatedSelectedClasses);
        setSelectedGrades(updatedSelectedGrades);
      }
    }
  }, [selectedClasses, grades, classes, isSingle]);

  useEffect(() => {
    if (
      selectedClasses &&
      selectedClasses.length &&
      isSingle &&
      setSelectedClassId
    ) {
      let selectedClassId = selectedClasses[0];
      setSelectedClassId(selectedClassId);
      if (selectedClassId !== -1) {
        let gradeId = classes.find(
          (cls) => cls.class_id === selectedClassId
        ).grade_id;

        setOpenGrades((prev) => {
          if (!(gradeId in prev) || !prev[gradeId]) {
            return {
              ...prev,
              [gradeId]: true,
            };
          }
          return prev;
        });
      }
    }
  }, [selectedClasses, classes, isSingle, setSelectedClassId]);

  return (
    <div className="class-selector">
      <span className="class-selector__title fc">{t("choose_class")}</span>
      <div className="class-selector__accordion">
        {!isSingle ? (
          <button
            className={`class-selector__select-all ${
              isSelectAllSelected ? "selected" : ""
            }`}
            onClick={handleSelectAll}
          >
            <span className="fc">{t("all")}</span>
          </button>
        ) : null}
        {grades.map((grade) => (
          <div
            key={grade.grade_id}
            className={`class-selector__grade ${
              openGrades[grade.grade_id] ? "expanded " : ""
            } ${selectedGrades.includes(grade.grade_id) ? " selected" : ""}`}
          >
            <div
              className={`class-selector__grade-title ${
                selectedGrades.includes(grade.grade_id) ? "selected" : ""
              }`}
              onClick={() => handleGradeClick(grade.grade_id)}
            >
              <span className="grade-name">{grade.grade_name}</span>

              <div className="grade-toggle">
                {openGrades[grade.grade_id] ? (
                  <BiChevronsUp
                    className="search-close"
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleClick(grade.grade_id);
                    }}
                  />
                ) : (
                  <BiChevronsDown
                    className="search-close"
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleClick(grade.grade_id);
                    }}
                  />
                )}
              </div>
            </div>
            <div className={`class-selector__classes`}>
              {renderClasses(grade.grade_id)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClassSelector;
