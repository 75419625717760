import "./expenses-section.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { BiEdit, BiTrash, BiCheck, BiX, BiSearch } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import AddExpense from "./addExpense";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ExpensesItem from "./expensesItem";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ExpensesSection() {
  const socketContext = useContext(SocketContext);
  const [expensesTypes, setExpensesTypes] = useState([]);
  const [schoolExpenses, setSchoolExpenses] = useState([]);
  const [addExpanded, setAddExpanded] = useState(false);

  useEffect(() => {
    const cleanUpGetExpensesTypes = getExpensesTypes();
    const cleanUpGetSchoolExpenses = getSchoolExpenses();
    return () => {
      cleanUpGetExpensesTypes();
      cleanUpGetSchoolExpenses();
    };
  }, []);

  const getExpensesTypes = () => {
    let args = {};
    const getExpensesTypesListener = (data) => {
      setExpensesTypes(data);
    };
    const refreshExpensesTypesListener = () => {
      socketContext.socket.emit("getExpensesTypes", args);
    };

    socketContext.socket.on("expensesTypes", getExpensesTypesListener);
    socketContext.socket.emit("getExpensesTypes", args);
    socketContext.socket.on(
      "refreshExpensesTypes",
      refreshExpensesTypesListener
    );

    return () => {
      socketContext.socket.off("getExpensesTypes", getExpensesTypesListener);
      socketContext.socket.off(
        "refreshExpensesTypes",
        refreshExpensesTypesListener
      );
    };
  };

  const getSchoolExpenses = () => {
    let args = {};
    const getSchoolExepnsesListener = (data) => {
      setSchoolExpenses(data);
    };
    const refreshSchoolExpensesListener = () => {
      socketContext.socket.emit("getSchoolExpenses", args);
    };

    socketContext.socket.on("schoolExpenses", getSchoolExepnsesListener);
    socketContext.socket.emit("getSchoolExpenses", args);
    socketContext.socket.on(
      "refreshSchoolExpenses",
      refreshSchoolExpensesListener
    );

    return () => {
      socketContext.socket.off("getSchoolExpenses", getSchoolExepnsesListener);
      socketContext.socket.off(
        "refreshSchoolExpenses",
        refreshSchoolExpensesListener
      );
    };
  };

  const populateExpenses = () => {
    return schoolExpenses.map((expense, index) => {
      return (
        <ExpensesItem
          index={"expenses item" + index}
          expense={expense}
          expensesTypes={expensesTypes}
        />
      );
    });
  };

  // Helper function to calculate the total expenses per month
  const calculateMonthlyExpenses = () => {
    const monthLabels = [];
    const monthData = [];

    const startDate = new Date("2024-08-01"); // Start from August 2024

    // Custom month names in Greek (first three letters, starting from August)
    const greekMonths = [
      "Αυγ",
      "Σεπ",
      "Οκτ",
      "Νοε",
      "Δεκ",
      "Ιαν",
      "Φεβ",
      "Μαρ",
      "Απρ",
      "Μαι",
      "Ιουν",
      "Ιουλ",
    ];

    // Shift the greek months array to start from August
    const adjustedMonths = greekMonths;

    for (let i = 0; i < 12; i++) {
      const adjustedMonth = (startDate.getMonth() + i) % 12;
      const year =
        startDate.getFullYear() + Math.floor((startDate.getMonth() + i) / 12);

      const monthLabel = adjustedMonths[i] + " " + year;
      monthLabels.push(monthLabel);

      const totalExpense = schoolExpenses.reduce((total, expense) => {
        const expenseDate = new Date(expense.date);
        if (
          expenseDate.getMonth() === adjustedMonth &&
          expenseDate.getFullYear() === year
        ) {
          return total + parseFloat(expense.amount);
        }
        return total;
      }, 0);

      monthData.push(totalExpense);
    }

    return { monthLabels, monthData };
  };

  const { monthLabels, monthData } = calculateMonthlyExpenses();

  const data = {
    labels: monthLabels,
    datasets: [
      {
        label: "Μηνιαία Έξοδα",
        data: monthData,
        backgroundColor: "#6124e681", // Background color for bars
        borderColor: "#6225e6", // Border color for bars
        borderWidth: 2, // Border width of 2px
        borderRadius: 10, // Border radius of 10px for rounded bars
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Μηνιαία Έξοδα", // Title of the graph
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className={"section expenses-section"}>
      <span className="section__title">Εξοδολόγια</span>
      <AddExpense isExpanded={addExpanded} setIsExpanded={setAddExpanded} />
      <div className="expenses-section__content">
        <div className="expenses-timeline">{populateExpenses()}</div>
      </div>
      <div className="expenses-section__graph">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default ExpensesSection;
