import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../../../../app/socket";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronDown,
  BiChevronUp,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import "./mark-parent-contact.scss";
import { CircularProgressbar } from "react-circular-progressbar";

function MarkStudent({ student, selectedDate, classId }) {
  const socketContext = useContext(SocketContext);
  const [grade, setGrade] = useState(0);
  const [comment, setComment] = useState("");
  const [saved, setSaved] = useState(false);

  const saveGrade = () => {
    setSaved(true);
    const body = {
      student_id: student.user_id,
      selected_date: selectedDate,
    };

    socketContext.socket.emit("markParentContact", body);
  };

  return (
    <div className={"mark-parent-contact " + (saved ? "saved" : "")}>
      <div className="mark-parent-contact__personal">
        <div className="mark-parent-contact__personal-img">
          <img
            src={
              student.profile_picture
                ? student.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="mark-parent-contact__personal-name">
          <span>{student.first_name}</span>
          <span>{student.last_name}</span>
        </div>
      </div>
      <div className="mark-parent-contact__comment">
        <button className="cta" onClick={() => saveGrade()}>
          Επικοινωνία
        </button>
      </div>
    </div>
  );
}

export default MarkStudent;
