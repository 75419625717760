import React, { useState, useContext, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  BiChevronDown,
  BiChevronsDown,
  BiChevronsUp,
  BiPlus,
  BiUpload,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./students-modal.scss";
import { useSelector } from "react-redux";
import Compress from "compress.js";
import MarkStudent from "../markStudent/markStudnet";

function StudentsModal({
  isExpanded,
  setIsExpanded,
  markedTests,
  selectedDate,
}) {
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.1 - topPosition + "px",
        left: window.innerWidth * 0.15 - leftPosition + "px",
        width: "70vw",
        height: "80vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const [students, setStudents] = useState([]);
  const [grades, setGrades] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [gotStudents, setGotStudents] = useState(false);

  const [searchStudent, setSearchStudent] = useState("");

  useEffect(() => {
    if (selectedClass && selectedClass != -1) {
      const cleanUpGetAllUsers = getAllUsers();
      return () => {
        cleanUpGetAllUsers();
      };
    }
  }, [selectedClass, searchStudent]);

  useEffect(() => {
    const cleanUpGetGrades = getGrades();
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetGrades();
      cleanUpGetClasses();
    };
  }, []);

  useEffect(() => {
    setGotStudents(false);
  }, [selectedClass, selectedGrade]);

  const getAllUsers = () => {
    if (!selectedClass) return () => {};

    const uniqueId = "general-test-all";
    let args = {
      uniqe_id: uniqueId,
      type: "student",
      classId: selectedClass,
      name: searchStudent,
    };

    const getStudentsListener = (data) => {
      setGotStudents(true);
      setStudents(data);
    };

    const refreshStudentsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getStudentsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshStudentsListener
    );

    return () => {
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getStudentsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshStudentsListener
      );
    };
  };

  const getGrades = () => {
    let args = { order_by_id: true };
    const getGradesListener = (data) => {
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);

    const refreshGradesListener = () => {
      socketContext.socket.emit("getGrades", args);
    };
    socketContext.socket.on("refreshGrades", refreshGradesListener);

    return () => {
      socketContext.socket.off("getGrades", getGradesListener);
      socketContext.socket.off("grades", getGradesListener);
      socketContext.socket.off("refreshGrades", refreshGradesListener);
    };
  };

  const getClasses = () => {
    console.log(" getting classes ");
    const uniqe_id = "daily-test-classes";
    let args = {
      unique_id: uniqe_id,
    };

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes" + uniqe_id, getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);

    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes" + uniqe_id, getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const selectGrade = (gradeId) => {
    setGotStudents(true);
    if (selectedGrade == gradeId) {
      setSelectedGrade();
    } else {
      setSelectedGrade(gradeId);
    }
  };

  const populateGradeSections = () => {
    return grades.map((grade) => (
      <div
        className="accordion-section grades"
        key={"accordion-grade-item" + grade.grade_id}
      >
        <div
          className="accordion-section__title"
          onClick={() => selectGrade(grade.grade_id)}
        >
          <span>{grade.grade_name}</span>
          {selectedGrade === grade.grade_id ? (
            // <BiChevronsUp
            //   size={"30px"}
            //   color={"#cccccc"}
            //   style={iconStyle("transparent")}
            // />
            ""
          ) : (
            <BiChevronsDown
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          )}
        </div>

        {/* Animate the content */}
        <AnimatePresence initial={false}>
          {selectedGrade === grade.grade_id && (
            <motion.div
              className="accordion-section__content"
              layout
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ overflow: "hidden" }}
            >
              {populateClasses(grade.grade_id)}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    ));
  };

  const selectClass = (classId) => {
    setGotStudents(true);
    if (selectedClass == classId) {
      console.log("here");
      setSelectedClass();
    } else {
      setSelectedClass(classId);
    }
  };

  const populateClasses = (gradeId) => {
    return classes
      .filter((classItem) => classItem.grade_id === gradeId)
      .map((classItem) => (
        <div
          className="accordion-section small"
          key={"accordion-class-item" + classItem.class_id}
        >
          <div
            className="accordion-section__title"
            onClick={() => selectClass(classItem.class_id)}
          >
            <span>{classItem.class_name}</span>

            {selectedClass === classItem.class_id ? (
              <BiChevronsUp
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            ) : (
              <BiChevronsDown
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
          </div>

          {/* Animate the content */}
          <AnimatePresence initial={false}>
            {selectedClass === classItem.class_id && (
              <motion.div
                className="accordion-section__content"
                layout
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: "hidden" }}
              >
                <div className="students__list">{populateStudents()}</div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ));
  };

  const populateStudents = () => {
    if (gotStudents) {
      const unmarkedStudents = students.filter(
        (student) =>
          !markedTests.find(
            (test) =>
              test.student_id === student.user_id &&
              test.class_id === selectedClass
          )
      );

      if (unmarkedStudents.length > 0) {
        return unmarkedStudents.map((student) => (
          <MarkStudent
            key={`markstudent${student.user_id}classId${selectedClass}`}
            student={student}
            selectedDate={selectedDate}
            classId={selectedClass}
          />
        ));
      } else {
        return <span>No students available for this class...</span>;
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={
        "students-modal " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "students-modal__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="students-modal__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="students-modal__config-search">
              <input
                className="input"
                value={searchStudent}
                onChange={(e) => setSearchStudent(e.target.value)}
                placeholder="Αναζήτηση"
              />
            </div>
            <div className="students-modal__config-accordion">
              {populateGradeSections()}
            </div>
            <div
              className="students-modal__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="students-modal__preview">
            <BiPlus
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>Καταγραφή Επικοινωνίας με Γονέα για Μαθητή</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default StudentsModal;
