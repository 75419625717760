import React, { useState, useEffect } from "react";

function ChapterItem({ chapter, selectedChapters, setSelectedChapters }) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selectedChapters || !selectedChapters.length) {
      setSelected(false);
    } else {
      if (selectedChapters.find((chpt) => chpt == chapter.id)) {
        setSelected(true);
      }
    }
  }, [selectedChapters]);

  const selectDepartment = () => {
    setSelected(!selected);
    const cahpterId = chapter.id;
    if (!selectedChapters || !selectedChapters.length) {
      setSelectedChapters([cahpterId]);
    } else {
      setSelectedChapters((prevDepartments) => {
        if (prevDepartments.includes(cahpterId)) {
          return prevDepartments.filter((id) => id !== cahpterId);
        } else {
          return [...prevDepartments, cahpterId];
        }
      });
    }
  };

  return (
    <div
      key={"chapter" + chapter.id}
      className={"item " + (selected ? "selected" : "")}
      onClick={() => selectDepartment()}
    >
      <span className="title">{chapter.chapter_name}</span>
    </div>
  );
}

export default ChapterItem;
