import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAlignMiddle,
  BiPencil,
  BiPlus,
  BiPrinter,
  BiUpload,
  BiUserPin,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import "./add-print-template.scss";
import { useReactToPrint } from "react-to-print";

const defaultTemplates = [
  {
    meta: {
      type: "student",
      title: "Student Template",
      pageStyles: {
        padding: "4%",
        boxSizing: "border-box",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f8f9fa",
      },
      aspectRatio: "A4",
    },
    sections: [
      {
        id: "section-school-img",
        type: "section",
        name: "School Image",
        layout: "single-column",
        styles: {
          padding: "2%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        },
        components: [
          {
            id: "school-img",
            type: "image",
            styles: {
              img: {
                width: "100%",
                maxHeight: "300px",
              },
              container: {
                width: "40%",
                overflow: "hidden",
              },
            },
            content: "https://md.edupal.gr/resources/logos/edupal.png",
          },
        ],
      },
      {
        id: "section-1",
        type: "section",
        name: "Header",
        layout: "single-column",
        styles: {
          padding: "3%",
          textAlign: "center",
          borderRadius: "0.5em",
          backgroundColor: "#007bff",
          display: "flex",
          flexDirection: "column",
        },
        components: [
          {
            id: "header-title-2",
            type: "text",
            styles: {
              fontSize: "1em",
              alignSelf: "center",
              textAlign: "left",
              fontWeight: "600",
              marginLeft: "1.5%",
            },
            content: "Καρτέλα Μαθητή",
          },
        ],
      },
      {
        id: "section-2",
        type: "section",
        name: "Profile Header",
        layout: "single-column",
        styles: {
          gap: "2%",
          display: "flex",
          flexDirection: "column",
          padding: "3%",
          alignItems: "center",
          borderBottom: "0.1em solid #ddd",
          justifyContent: "space-between",
        },
        components: [
          {
            id: "comp-2",
            type: "image",
            styles: {
              img: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
              container: {
                width: "20%",
                border: "0.15em solid #007bff",
                overflow: "hidden",
                aspectRatio: "1",
                borderRadius: "50%",
              },
            },
            dynamicContent: "studentImage",
          },
          {
            id: "comp-3",
            type: "text",
            styles: {
              fontSize: "1em",
              alignSelf: "center",
              textAlign: "left",
              fontWeight: "600",
              marginLeft: "1.5%",
            },
            dynamicContent: "studentFullName",
          },
        ],
      },
    ],
  },
];

function AddPrintTemplate({ isExpanded, setIsExpanded }) {
  const htmlRef = useRef(null);

  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("student");

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      let newPositionStyle = {
        top: window.innerHeight * 0.5 - 225 - topPosition + "px",
        left: window.innerWidth * 0.3 - leftPosition + "px",
        width: "40vw",
        height: "450px",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const handleCreateTemplate = () => {
    const newTemplate = {
      title: title,
      type: type,
      template_data: defaultTemplates[0],
    };

    socketContext.socket.emit("createPrintTemplate", newTemplate);
    setIsExpanded(false);
  };

  return (
    <div
      ref={containerRef}
      className={
        "add-print-template " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "add-print-template__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="add-print-template__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="config-title">
              <span className="config-title__label">Τίτλος</span>
              <input
                className="input"
                placeholder="Εισάγεται τίτλο..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="config-type">
              <span className="config-type__label">Τύπος template</span>
              <div className="config-type__list">
                <div
                  className={
                    "config-type__list-item " +
                    (type == "student" ? "active" : "")
                  }
                  onClick={() => setType("student")}
                >
                  <BiUserPin
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span className="config-type__list-item-title">Μαθητή</span>
                </div>
                <div
                  className={
                    "config-type__list-item " +
                    (type == "general" ? "active" : "")
                  }
                  onClick={() => setType("general")}
                >
                  <BiAlignMiddle
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span className="config-type__list-item-title">Γενικό</span>
                </div>
              </div>
            </div>
            <div className="add-print-template__config-actions">
              <button className="cta" onClick={() => handleCreateTemplate()}>
                Αποθήκευση
              </button>
            </div>
            <div
              className="add-print-template__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="add-print-template__preview">
            <BiPlus
              size={"30px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default AddPrintTemplate;
