import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronDown,
  BiChevronUp,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { CircularProgressbar } from "react-circular-progressbar";

function ParentContactItem({ user, selectedDate, test, classItem }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [isEdit, setIsEdit] = useState(false);
  const [newGrade, setNewGrade] = useState(test.grade);
  const [newComment, setNewComment] = useState(test.comment);

  const deleteParentContact = () => {
    const body = {
      parent_contact_id: test.parent_contact_id,
    };

    socketContext.socket.emit("deleteParentContact", body);
    setIsEdit(false);
  };

  return (
    <div className={"students-item "}>
      <Link
        to={"/profile?user-id=" + user.user_id}
        className="students-item__personal"
      >
        <div className="students-item__personal-img">
          <img
            src={
              user.profile_picture
                ? user.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="students-item__personal-name">
          <span>{user.first_name}</span>
          <span>{user.last_name}</span>
        </div>
      </Link>
      {profile.user_type == "admin" || profile.user_type == "professor" ? (
        !isEdit ? (
          <div className="students-item__edit">
            <button className="cta cta-red" onClick={() => setIsEdit(true)}>
              Αφαίρεση
            </button>
          </div>
        ) : (
          <div className="students-item__save">
            <button className="cta cta-red" onClick={() => setIsEdit(false)}>
              Ακύρωση
            </button>
            <button className="cta" onClick={() => deleteParentContact()}>
              Διαγραφή
            </button>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default ParentContactItem;
