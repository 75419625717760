import React, { useState, useEffect } from "react";
import "./image-slider.scss";
import { iconStyle } from "../../utils/generalUtils";
import {
  BiChevronLeft,
  BiChevronRight,
  BiRotateLeft,
  BiRotateRight,
  BiZoomIn,
  BiZoomOut,
} from "react-icons/bi";
import { useSelector } from "react-redux";

const ImageSlider = ({ activeAsset, assets, disableDownload }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const profile = useSelector((state) => state.profile.value);

  useEffect(() => {
    const index = assets.findIndex((asset) => asset === activeAsset);
    if (index !== -1) setCurrentIndex(index);
  }, [activeAsset, assets]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : assets.length - 1
    );
    resetTransformations();
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % assets.length);
    resetTransformations();
  };

  const rotateLeft = () => setRotation((prev) => prev - 90);
  const rotateRight = () => setRotation((prev) => prev + 90);
  const zoomIn = () => setScale((prev) => prev + 0.1);
  const zoomOut = () => setScale((prev) => prev - 0.1);
  const resetTransformations = () => {
    setRotation(0);
    setScale(1);
  };

  const getFileType = (filename) => {
    const ext = filename.split(".").pop().toLowerCase();
    if (["png", "jpg", "jpeg"].includes(ext)) return "image";
    if (["pdf"].includes(ext)) return "pdf";
    if (["docx"].includes(ext)) return "docx";
    if (["mp3", "wma"].includes(ext)) return "audio";
    if (["ppt", "pptx"].includes(ext)) return "ppt";
    return "unknown";
  };

  const getAudioMimeType = (extension) => {
    const mimeTypes = {
      mp3: "audio/mpeg",
      wma: "audio/x-ms-wma",
    };
    return mimeTypes[extension] || "audio/*";
  };

  const canPlayAudioType = (type) => {
    const audio = document.createElement("audio");
    return !!audio.canPlayType(type).replace("no", "");
  };

  const renderAsset = (asset) => {
    const transformStyle = {
      transform: `rotate(${rotation}deg) scale(${scale})`,
    };

    if (typeof asset !== "string") return <div>Invalid asset type</div>;

    const fileType = getFileType(asset);
    const extension = asset.split(".").pop().toLowerCase();

    switch (fileType) {
      case "image":
        return (
          <img
            src={asset}
            alt="Slide"
            style={{ ...transformStyle, width: "100%", height: "100%" }}
          />
        );
      case "pdf":
        const pdfUrl = `${asset}#toolbar=0`;
        return (
          <iframe
            src={
              disableDownload &&
              !["admin", "professor"].includes(profile.user_type)
                ? pdfUrl
                : asset
            }
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
          />
        );
      case "audio":
        const audioType = getAudioMimeType(extension);
        if (canPlayAudioType(audioType)) {
          return (
            <audio className="audio-file" controls style={{ width: "100%" }}>
              <source src={asset} type={audioType} />
              Your browser does not support this audio format.
            </audio>
          );
        } else {
          return (
            <div className="audio-unsupported">
              {/* Your browser does not support this audio format. */}
              <a href={asset} download>
                Λήψη Αρχείου
              </a>
            </div>
          );
        }
      case "ppt":
        return (
          <div className="ppt-download">
            <button
              onClick={() => window.open(asset, "_blank")}
              className="ppt-download-button cta cta-fill"
            >
              Download PPT File
            </button>
          </div>
        );
      case "docx":
        return (
          <div className="ppt-download">
            <button
              onClick={() => window.open(asset, "_blank")}
              className="ppt-download-button cta cta-fill"
            >
              Λήψη αρχείου Word
            </button>
          </div>
        );
      default:
        return <div>Unsupported asset type</div>;
    }
  };

  const isImageFile = (asset) => {
    const fileType = getFileType(asset);
    return fileType === "image";
  };

  return (
    <div className="image-slider">
      <button className="asset-container__nav left" onClick={goToPrevious}>
        <BiChevronLeft
          size={"35px"}
          color={"#fff"}
          style={iconStyle("transparent")}
        />
      </button>
      <div className="asset-container">{renderAsset(assets[currentIndex])}</div>
      {isImageFile(assets[currentIndex]) && (
        <div className="asset-container__controls">
          <div className="controls-item" onClick={rotateLeft}>
            <BiRotateLeft
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="controls-item" onClick={zoomOut}>
            <BiZoomOut
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="controls-item" onClick={zoomIn}>
            <BiZoomIn
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="controls-item" onClick={rotateRight}>
            <BiRotateRight
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
        </div>
      )}
      <button className="asset-container__nav right" onClick={goToNext}>
        <BiChevronRight
          size={"35px"}
          color={"#fff"}
          style={iconStyle("transparent")}
        />
      </button>
      <div className="thumbnail-container">
        {assets.map((asset, index) => {
          const isActive = index === currentIndex;
          const fileType = getFileType(asset);
          let thumbnailSrc = asset;

          if (fileType === "audio") {
            thumbnailSrc = "../../../resources/icons/audio-icon.png";
          } else if (fileType === "ppt") {
            thumbnailSrc = "../../../resources/icons/ppt-icon.png";
          } else if (fileType === "pdf") {
            thumbnailSrc = "../../../resources/icons/pdf-icon.png";
          } else if (fileType === "docx") {
            thumbnailSrc = "../../../resources/icons/word-icon.png";
          }

          return (
            <img
              key={index}
              src={thumbnailSrc}
              alt="Thumbnail"
              className={`${isActive ? "active" : ""} ${fileType}`}
              onClick={() => {
                setCurrentIndex(index);
                resetTransformations();
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
