import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../app/socket";
import { BiInfoCircle } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import "./student-exam-score.scss";
import ExamRecords from "../ExamCard/examRecords";
import { useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import OnlineExamResults from "../OnlineExamResults/onlineExamResults";

function StudentExamScore({ event }) {
  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);

  const [detailedRank, setDetailedRank] = useState([]);

  useEffect(() => {
    if (!event.detailed_rank) {
      let temp = [];
      for (let i = 0; i < 4; i++) {
        temp.push(100 / 4);
      }
      setDetailedRank(temp);
    } else {
      let parsedTemp = [];
      try {
        parsedTemp = JSON.parse(event.detailed_rank);
        let temp = [];
        parsedTemp.map((item) => {
          if (item > 0) {
            temp.push(item);
          } else {
            temp.push(100 / parsedTemp.length);
          }
          setDetailedRank(temp);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const navigate = useNavigate();

  const populateDetailedScore = () => {
    try {
      const detailedScore = JSON.parse(event.detailed_score);
      return detailedScore.map((score, index) => {
        if (detailedRank[index]) {
          return (
            <div className="list__item" key={"detailedScoreItem" + index}>
              <span className="list__item-label">Θέμα {index + 1}ο</span>
              <CircularProgressbar
                pathColor="{red}"
                strokeWidth={6}
                className="user-score"
                value={(score * 100) / detailedRank[index]}
                duration={1.4}
                text={score + "/" + detailedRank[index]}
              />
            </div>
          );
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getWaitingTitle = () => {
    if (new Date(event.start_at) > new Date()) {
      return "Η εξέταση είναι μελλοντική. Δεν υπάρχει βαθμός ακόμα";
    } else {
      return "Το διαγώνισμα έχει βαθμολογηθεί...";
    }
  };

  const startExam = () => {
    const link = "/exam?exam-id=" + event.exam_id;
    document.body.style.overflowY = "auto";
    navigate(link);
  };

  return (
    <div className="student-exam-score">
      {event.score ? (
        <div className="wrapper">
          <div className="total-score">
            <span className="label">Συνολική Βαθμολογία</span>
            <div className="total-score__wrapper">
              <div className="score">
                <span className="score__label">
                  {event.score + "/" + event.max_rank}
                </span>
                <CircularProgressbar
                  pathColor="{red}"
                  strokeWidth={6}
                  className="user-score"
                  value={(event.score * 100) / event.max_rank}
                  duration={1.4}
                  // text={event.score + "/" + event.max_rank}
                />
              </div>
              <div className="comments">
                <span className="sub-label">Σχόλια καθηγητή</span>
                <span className="label">
                  {event.type != "online-exam" && event.result_text
                    ? event.result_text
                    : event.type == "online-exam" && event.general_comments
                    ? event.general__comment
                    : "Δεν υπάρχουν σχόλια καθηγητή."}
                </span>
              </div>
            </div>
          </div>
          <div className="detailed-score">
            <span className="label">Αναλυτική Βαθμολογία:</span>
            <div className="list">{populateDetailedScore()}</div>
          </div>
        </div>
      ) : (
        ""
      )}
      {event.score ? <OnlineExamResults exam={event} /> : ""}
      {!event.score && event.type != "online-exam" ? (
        <div className="no-score">
          <div className="wrapper">
            <div className="wrapper__back">
              <BiInfoCircle
                size={"35px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
            <span>{getWaitingTitle()}</span>
          </div>
        </div>
      ) : (
        ""
      )}
      {!event.score &&
      event.type == "online-exam" &&
      profile.user_type == "student" &&
      event.state != 2 &&
      new Date() >= new Date(event.start_at) &&
      new Date() <= new Date(event.finish_at) ? (
        <div className="online-exam-no-score">
          <button className="cta" onClick={() => startExam()}>
            {event.state == "in-progress" ? "Ολοκλήρωση " : "Εκκίνηση "}{" "}
            Διαγωνίσματος
          </button>
        </div>
      ) : (
        ""
      )}
      {!event.score && event.type == "online-exam" && event.state == 2 ? (
        <div className="no-score">
          <div className="wrapper">
            <div className="wrapper__back">
              <BiInfoCircle
                size={"35px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
            <span>
              {event.state == 2
                ? "Το διαγώνισμα σας είναι προς βαθμολόγηση..."
                : ""}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {!event.score &&
      event.type == "online-exam" &&
      (new Date() <= new Date(event.start_at) ||
        new Date() >= new Date(event.finish_at)) ? (
        <div className="no-score">
          <div className="wrapper">
            <div className="wrapper__back">
              <BiInfoCircle
                size={"35px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
            <span>
              {event.state == 2
                ? "Το διαγώνισμα σας είναι προς βαθμολόγηση..."
                : "Το διαγώνισμα είναι κλειδωμένο..."}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default StudentExamScore;
