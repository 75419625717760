// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import the languages you want to support
import translationEN from "./locales/en/translation.js";
import translationGR from "./locales/gr/translation.js";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  gr: {
    translation: translationGR,
  },
};

// Check for localStorage availability
const savedLanguage =
  (typeof window !== "undefined" && localStorage.getItem("language")) || "gr";

i18n
  .use(initReactI18next) // Initialize react-i18next
  .init({
    resources,
    lng: savedLanguage, // Set initial language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
    react: {
      useSuspense: false, // Disable suspense since we're loading translations upfront
    },
  });

export default i18n;
