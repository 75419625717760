// defaultComponents.js
import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

export const componentRenderer = {
  text: (component, previewMode = false) => (
    <div
      key={component.id}
      className="template-preview__component text"
      style={component.styles}
    >
      {previewMode
        ? "Sample Text"
        : component.dynamicContent || component.content}
    </div>
  ),

  image: (component, previewMode = false) => {
    const containerStyles = component.styles?.container || {};
    const imgStyles = component.styles?.img || {};
    return (
      <div
        key={component.id}
        className="template-preview__component image"
        style={containerStyles}
      >
        <img
          src={
            previewMode && component.dynamicContent
              ? `${process.env.PUBLIC_URL}/resources/student.png`
              : component.dynamicContent || component.content
          }
          style={imgStyles}
          alt=""
        />
      </div>
    );
  },

  nestedSection: (component, previewMode = false) => {
    // The outer wrapper
    const styleWrapper = {
      width: "100%", // always take full width
      border: "1px dashed #ccc",
      padding: "0.5em",
      margin: "0.5em 0",
      display: "flex",
      gap: "0.5em",
      flexWrap: "nowrap",
      boxSizing: "border-box",
      ...component.styles,
    };

    return (
      <div
        key={component.id}
        className="template-preview__component nested-section"
        style={styleWrapper}
      >
        {component.columns.map((col) => {
          // If we're in "editor mode" => bigger minHeight to help with dragging
          const colMinHeight = previewMode ? "auto" : "150px";

          return (
            <div
              key={col.id}
              style={{
                width: col.width || "50%",
                border: "1px solid #ddd",
                // No padding if you prefer, but bigger minHeight for the drop area
                padding: 0,
                minHeight: colMinHeight,
                boxSizing: "border-box",
                position: "relative",
              }}
            >
              {previewMode ? (
                <div>Column (Preview)</div>
              ) : (
                <Droppable
                  droppableId={`nestedSection-col-${component.id}__${col.id}`}
                  type="COMPONENT"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        // If dragging over, highlight
                        background: snapshot.isDraggingOver
                          ? "#fafaff"
                          : "transparent",
                        minHeight: colMinHeight,
                        width: "100%",
                      }}
                    >
                      {col.components.map((childComp, idx) => (
                        <Draggable
                          key={childComp.id}
                          draggableId={String(childComp.id)}
                          index={idx}
                          type="COMPONENT"
                        >
                          {(dragProvided, dragSnapshot) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                              style={{
                                userSelect: "none",
                                marginBottom: "0.5em",
                                border: dragSnapshot.isDragging
                                  ? "1px solid #007bff"
                                  : "1px solid transparent",
                                background: "#fff",
                                zIndex: dragSnapshot.isDragging ? 9999 : "auto",
                                opacity: dragSnapshot.isDragging ? 0.9 : 1,
                                padding: "0.5em",
                                // Merge Draggable’s style
                                ...dragProvided.draggableProps.style,
                              }}
                            >
                              {componentRenderer[childComp.type]
                                ? componentRenderer[childComp.type](
                                    childComp,
                                    false // still editor mode
                                  )
                                : `Unknown ${childComp.type}`}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </div>
          );
        })}
      </div>
    );
  },
};
