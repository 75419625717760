import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAlignMiddle,
  BiPencil,
  BiPlus,
  BiPrinter,
  BiUpload,
  BiUserPin,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import "./template-item.scss";
import { useReactToPrint } from "react-to-print";
import TemplatePreview from "../TemplatePreview/templatePreview";
import { Link } from "react-router-dom";

function TemplateItem({ templateData, templateTitle, templateId }) {
  const htmlRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("student");

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      let newPositionStyle = {
        top: window.innerHeight * 0.5 - 225 - topPosition + "px",
        left: window.innerWidth * 0.3 - leftPosition + "px",
        width: "40vw",
        height: "450px",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const deleteTemplate = (id) => {
    const body = {
      print_templates_id: id,
    };

    socketContext.socket.emit("deletePrintTemplate", body);
  };

  return (
    <div
      ref={containerRef}
      className={
        "template-item " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "template-item__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        <div className="template-box">
          {/* <TemplatePreview templateData={templateData} /> */}
        </div>
        {!isExpanded ? (
          <div className="overlay__title">
            <span>{templateTitle}</span>
          </div>
        ) : (
          ""
        )}
        {isExpanded ? (
          <div className="template-item__actions">
            <Link
              to={"/print/editor?template-id=" + templateId}
              className="cta cta-shadow"
            >
              edit
            </Link>
            <button
              onClick={() => deleteTemplate(templateId)}
              className="cta cta-shadow"
            >
              remove
            </button>
          </div>
        ) : (
          ""
        )}
      </motion.div>
    </div>
  );
}

export default TemplateItem;
