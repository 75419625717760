import React from "react";
import { useState, useContext } from "react";
import "./side-filters.scss";
import "../../scss/main.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentClassId, setCurrentClassName } from "./classNamesSlice";
import { setNewClassCreatedId } from "./classNamesSlice";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SocketContext } from "../../app/socket";
import { useNavigate } from "react-router-dom";
import { cookieUtils } from "../../utils/cookie-parser";
import {
  BiPlus,
  BiX,
  BiGridVertical,
  BiTrim,
  BiAbacus,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import { setCurrentChapterId } from "./chaptersSlice";
import { setTopFilter } from "./topFilterSlice";
import ClassItem from "./classItem";
import LoaderCard from "../LoaderCard/loaderCard";
import { iconStyle } from "../../utils/generalUtils";
import { setCurrentGradeId } from "../ClassesNav/classNamesSlice";

const SideFilters = ({
  hasTopFilters,
  addClassShow,
  addTopMargin,
  isForQuiz,
  onlyWithQuiz,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const socketContext = useContext(SocketContext);
  // const generalSettings = useSelector((state) => state.profile.generalSettings);
  const [generalSettings, setGeneralSettings] = useState([]);

  const profile = useSelector((state) => state.profile.value);

  const [classes, setClasses] = useState([]);
  const [gotClasses, setGotClasses] = useState(false);

  const [activeClassIndex, setActiveClassIndex] = useState(-1);
  const [activeGradeIndex, setActiveGradeIndex] = useState(-1);

  const [activeChapterIndex, setActiveChapterIndex] = useState(-1);

  const [showAddClassConfig, setShowAddClassConfig] = useState(false);

  const [chapterMode, setChapterMode] = useState(false);

  const [showMobileNav, setShowMobileNav] = useState(false);

  const [classesWithQuiz, setClassesWithQuiz] = useState([]);

  const [addClassError, setAddClassError] = useState();

  const [collapsedSections, setCollapsedSections] = useState(() => {
    const storedValue = cookieUtils.getCookie("collapsedSections");
    return storedValue ? JSON.parse(storedValue) : [];
  });

  const isRunningInWebView = () => {
    return window.ReactNativeWebView !== undefined;
  };

  // Update the cookie whenever collapsedSections changes
  useEffect(() => {
    if (!isRunningInWebView()) {
      cookieUtils.setCookie(
        "collapsedSections",
        JSON.stringify(collapsedSections),
        7
      );
    }
  }, [collapsedSections]);

  useEffect(() => {
    dispatch(setCurrentClassId(-1));
    dispatch(setCurrentGradeId(-1));
    dispatch(setCurrentClassName(""));
    dispatch(setNewClassCreatedId(-1));
    const cleanUpGetClasses = getClasses();
    if (onlyWithQuiz) {
      getClassesWithQuiz();
    }
    getGrades();
    const cleanUpGetGeneralSettings = getGeneralSettings();
    return () => {
      cleanUpGetGeneralSettings();
      cleanUpGetClasses();
    };
  }, []);

  useEffect(() => {
    if (showMobileNav) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showMobileNav]);

  const getGeneralSettings = () => {
    let args = {};

    const getGeneralSettingsListener = (data) => {
      setGeneralSettings(data);
    };

    const refreshGeneralSettingsListener = () => {
      socketContext.socket.emit("getGeneralSettings", args);
    };

    socketContext.socket.on("generalSettings", getGeneralSettingsListener);
    socketContext.socket.emit("getGeneralSettings", args);
    socketContext.socket.on(
      "refreshGeneralSettings",
      refreshGeneralSettingsListener
    );

    return () => {
      socketContext.socket.off(
        "getGeneralSettings",
        getGeneralSettingsListener
      );
      socketContext.socket.off("generalSettings", getGeneralSettingsListener);
      socketContext.socket.off(
        "refreshGeneralSettings",
        refreshGeneralSettingsListener
      );
    };
  };

  const [grades, setGrades] = useState([]);
  const getGrades = () => {
    let args = {};

    const getGradesListener = (data) => {
      if (data && data[0]) {
        setNewClassGrade(data[0].grade_id);
      }
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);
    socketContext.socket.on("refreshGrades", () => {
      socketContext.socket.emit("getGrades", args);
    });
  };

  const getClassesWithQuiz = () => {
    let args = {};

    const getClassesWithQuizListener = (data) => {
      setClassesWithQuiz(data);
    };

    socketContext.socket.on("classesWithQuiz", getClassesWithQuizListener);
    socketContext.socket.emit("getClassesWithQuiz", args);
    socketContext.socket.on("refreshClassesWithQuiz", () => {
      socketContext.socket.emit("getClassesWithQuiz", args);
    });
  };

  const populateGradeList = () => {
    return grades.map((grade) => {
      return (
        <option key={"grade " + grade.grade_id} value={grade.grade_id}>
          {grade.grade_name}
        </option>
      );
    });
  };

  const getClasses = () => {
    let args = {};
    const getClassesListener = (data) => {
      setGotClasses(true);
      setClasses(data);
    };
    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };
    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const classItemClick = (e, classIndex) => {
    if (e.target.tagName !== "path" && e.target.tagName !== "svg") {
      setActiveClassIndex(classIndex);
      setActiveGradeIndex(-1);
      dispatch(setCurrentClassId(classIndex));
      dispatch(setCurrentGradeId(-1));
      dispatch(
        setCurrentClassName(
          classes.find((cls) => cls.class_id == classIndex)
            ? classes.find((cls) => cls.class_id == classIndex).class_name
            : ""
        )
      );
      dispatch(setCurrentChapterId(-1));
      setActiveChapterIndex(-1);
    }
  };

  const gradeItemClick = (e, gradeIndex) => {
    console.log(" clicking grade item ");
    if (e.target.tagName !== "path" && e.target.tagName !== "svg") {
      setActiveGradeIndex(gradeIndex);
      setActiveClassIndex(-1);
      dispatch(setCurrentClassId(-1));
      dispatch(setCurrentGradeId(gradeIndex));
      console.log(" setting grade id " + gradeIndex);
      // dispatch(
      //   setCurrentGradeName(
      //     classes.find((cls) => cls.class_id == classIndex).class_name
      //   )
      // );
      dispatch(setCurrentChapterId(-1));
      setActiveChapterIndex(-1);
    }
  };

  const toggleSection = (gradeId) => {
    setCollapsedSections((prevState) => {
      if (prevState.includes(gradeId)) {
        return prevState.filter((id) => id !== gradeId);
      } else {
        return [...prevState, gradeId];
      }
    });
  };

  const populateClassesGrades = () => {
    const sortedClasses = sortClasses();
    return sortedClasses.map((classItem, index) => {
      if (classItem.class_id != -10) {
        return (
          <ClassItem
            key={"classItem" + classItem.class_id}
            classItem={classItem}
            index={index}
            activeClassIndex={activeClassIndex}
            activeChapterIndex={activeChapterIndex}
            profile={profile}
            classItemClick={classItemClick}
            disabled={
              classesWithQuiz.some(
                (cls) => cls.class_id == classItem.class_id
              ) || !onlyWithQuiz
                ? false
                : true
            }
          ></ClassItem>
        );
      } else if (
        generalSettings &&
        generalSettings.length &&
        !generalSettings.some((stn) => stn.name == "only_grades" && stn.value)
      ) {
        return (
          <div className={"side-filters__nav-list-item-line "}>
            <span>{classItem.grade_name}</span>
          </div>
        );
      }
    });
  };

  const populateClasses = () => {
    const sortedClasses = sortClasses();
    const sections = [];
    let currentGradeSection = [];
    let currentGradeId = null;
    let currentGradeName = null;

    sortedClasses.forEach((classItem, index) => {
      if (
        classItem.class_id === -10 &&
        !generalSettings.some((stn) => stn.name == "only_grades" && stn.value)
      ) {
        // Push the previous grade section if it exists
        if (currentGradeSection.length > 0) {
          const gradeIdForSection = currentGradeId; // Capture the gradeId in a constant

          sections.push(
            <div
              key={`grade-section-${gradeIdForSection}`}
              className={`grade-section ${gradeIdForSection}`}
            >
              <div
                className={
                  "side-filters__nav-list-item-line " +
                  (activeGradeIndex == gradeIdForSection ? "active" : "")
                }
                onClick={(e) => {
                  gradeItemClick(e, gradeIdForSection);
                }}
              >
                <span>{currentGradeName}</span>
                {collapsedSections.includes(gradeIdForSection) ? (
                  <BiChevronDown
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                    onClick={() => {
                      toggleSection(gradeIdForSection);
                    }}
                  />
                ) : (
                  <BiChevronUp
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                    onClick={() => {
                      toggleSection(gradeIdForSection);
                    }}
                  />
                )}
              </div>
              <div
                className={`class-section ${
                  collapsedSections.includes(gradeIdForSection)
                    ? "collapsed"
                    : ""
                }`}
              >
                {currentGradeSection}
              </div>
            </div>
          );
          currentGradeSection = [];
        }

        // Determine the grade_id for this section from the next item, if available
        const nextItem = sortedClasses[index + 1];
        if (nextItem && nextItem.grade_id) {
          currentGradeId = nextItem.grade_id;
        } else {
          currentGradeId = `unknown-${index}`; // Fallback in case no grade_id is found
        }

        currentGradeName = classItem.grade_name;
      } else {
        // This is a regular class item; add it to the current section
        currentGradeSection.push(
          <ClassItem
            key={"classItem" + classItem.class_id}
            classItem={classItem}
            index={index}
            activeClassIndex={activeClassIndex}
            activeChapterIndex={activeChapterIndex}
            profile={profile}
            classItemClick={classItemClick}
            disabled={
              classesWithQuiz.some(
                (cls) => cls.class_id == classItem.class_id
              ) || !onlyWithQuiz
                ? false
                : true
            }
          ></ClassItem>
        );
      }
    });

    // Push the last section
    if (currentGradeSection.length > 0) {
      const gradeIdForSection = currentGradeId; // Capture the gradeId in a constant

      sections.push(
        <div
          key={`grade-section-${gradeIdForSection}`}
          className="grade-section no-scrollbar"
        >
          {!generalSettings.some(
            (stn) => stn.name == "only_grades" && stn.value
          ) ? (
            <div
              className={
                "side-filters__nav-list-item-line " +
                (activeGradeIndex == gradeIdForSection ? "active" : "")
              }
              onClick={() => {
                toggleSection(gradeIdForSection);
              }}
            >
              <span>{currentGradeName}</span>
              {collapsedSections.includes(gradeIdForSection) ? (
                <BiChevronDown
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              ) : (
                <BiChevronUp
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              )}
            </div>
          ) : (
            ""
          )}
          <div
            className={`class-section no-scrollbar ${
              collapsedSections.includes(gradeIdForSection) ? "collapsed" : ""
            }`}
          >
            {currentGradeSection}
          </div>
        </div>
      );
    }

    return sections;
  };

  const sortClasses = () => {
    const sortedClasses = classes;

    const withHeaders = [];
    let previousGrade = null;

    sortedClasses.forEach((classItem, index) => {
      if (classItem.grade_name != previousGrade) {
        withHeaders.push({
          class_id: -10,
          type: "header",
          grade_name: classItem.grade_name,
        });
        previousGrade = classItem.grade_name;
      }
      withHeaders.push(classItem);
    });
    return withHeaders;
  };

  const populateClassLoaders = () => {
    const card = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return card.map((item, index) => {
      return (
        <LoaderCard
          key={"loaderCard" + index}
          cardSum={1}
          width={100}
          size="large"
        />
      );
    });
  };

  const [topFilterSelected, setTopFilterSelected] = useState("all");

  const topFilterClick = (filterIndex) => {
    setTopFilterSelected(filterIndex);
    dispatch(setTopFilter(filterIndex));
  };

  const [addClassName, setAddClassName] = useState("");
  const [newClassGrade, setNewClassGrade] = useState(1);

  const addClass = () => {
    if (addClassName) {
      const body = {
        class_name: addClassName,
        grade_id: newClassGrade,
        type: "add",
      };

      socketContext.socket.emit("updateClasses", body);

      const getLatestClassId = (data) => {
        let class_id = data;
        setActiveClassIndex(class_id);
        dispatch(setNewClassCreatedId(class_id));
        dispatch(setCurrentClassId(class_id));
        dispatch(setCurrentClassName(addClassName));
      };

      socketContext.socket.on("latestClassId", getLatestClassId);
      setShowAddClassConfig(false);
    } else {
      setAddClassError("Παρακαλώ εισάγετε όνομα μαθήματος.");
    }
  };

  return (
    <div className="side-filters">
      {showAddClassConfig ? (
        <div
          className={"modal-background show "}
          onClick={() => setShowAddClassConfig(false)}
        ></div>
      ) : (
        ""
      )}
      <div
        className={"side-filters__popup " + (showAddClassConfig ? "open" : "")}
      >
        <div className="side-filters__popup-container">
          <div
            className="side-filters__popup-container-close"
            onClick={() => {
              setShowAddClassConfig(false);
            }}
          >
            <BiX
              className="side-filters__nav-close"
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f2f2f2")}
            />
          </div>
          {profile.user_type !== "student" && (
            <div className="config">
              <div className="config__container">
                <label className="config__container-title">
                  Διαμόρφωση νέου μαθήματος
                </label>
                <div className="config__container-name">
                  <input
                    className="input"
                    placeholder="Όνομα μαθήματος"
                    type="text"
                    onChange={(e) => setAddClassName(e.target.value)}
                  />
                </div>
                {addClassError ? (
                  <span className="error">{addClassError}</span>
                ) : (
                  ""
                )}
                {grades && grades.length ? (
                  <div className="config__container-grade input-shadow">
                    <select
                      className="select"
                      value={newClassGrade}
                      onChange={(e) => setNewClassGrade(e.target.value)}
                    >
                      {populateGradeList()}
                    </select>
                  </div>
                ) : (
                  <span>
                    Για να προσθέσετε νέο μάθημα πρώτα διαμορφώστε τις τάξεις
                    μέσω των ρυθμίσεων...
                  </span>
                )}
              </div>
              {grades && grades.length ? (
                <div className="config__options">
                  <div
                    className="cta config__options-btn"
                    onClick={() => addClass()}
                  >
                    Αποθήκευση
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      <div className={"side-filters__mobile " + (isForQuiz ? "for-quiz" : "")}>
        <div className="cta" onClick={() => setShowMobileNav(true)}>
          <BiAbacus
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
          {/* <span>Φίλτρα</span> */}
        </div>
      </div>
      <div
        className={
          "side-filters__nav no-scrollbar " +
          (showMobileNav ? "show" : "") +
          (addTopMargin ? " addTopMargin" : "")
        }
      >
        <div
          className="side-filters__nav-mobile-close"
          onClick={() => {
            setShowMobileNav(false);
          }}
        >
          <BiX size={"35px"} color={"#cccccc"} style={iconStyle("#f2f2f2")} />
        </div>
        {hasTopFilters &&
          (profile.user_type === "professor" ||
            profile.user_type === "admin") && (
            <div className="container types">
              <div className="side-filters__nav-title">
                Ηλεκτρονικές υποβολές
              </div>
              <div className="side-filters__types">
                <div
                  className={"side-filters__types-item full"}
                  onClick={() => navigate("/exams-submitted")}
                >
                  <BiTrim
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span>Υποβολές e-Διαγωνισμάτων</span>
                </div>
              </div>
            </div>
          )}
        {hasTopFilters ? (
          <div className="container types">
            <div className="side-filters__nav-title">Τύπος</div>
            <div className="side-filters__types">
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "all" ? "active" : "")
                }
                onClick={() => topFilterClick("all")}
              >
                <BiGridVertical
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                {/* <img src="/resources/exam-icon.png" alt="Διαγώνισμα" /> */}
                <span>Όλα</span>
              </div>
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "test" ? "active" : "")
                }
                onClick={() => topFilterClick("test")}
              >
                <img src="/resources/test-icon.png" alt="Διαγώνισμα" />
                <span>Τεστ</span>
              </div>
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "exam" ? "active" : "")
                }
                onClick={() => topFilterClick("exam")}
              >
                <img src="/resources/classroom-icon.png" alt="Διαγώνισμα" />
                <span>Διαγωνίσματα</span>
              </div>
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "online-exam" ? "active" : "")
                }
                onClick={() => topFilterClick("online-exam")}
              >
                <img src="/resources/online-exam-icon.png" alt="Διαγώνισμα" />
                <span>e-Διαγωνίσματα</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {!chapterMode ? (
          <div className="container classes">
            {/* <div className="side-filters__nav-title">
              {generalSettings &&
              generalSettings.length &&
              generalSettings.some(
                (stn) => stn.name == "only_grades" && stn.value
              )
                ? "Τάξεις"
                : "Μαθήματα"}
            </div> */}
            <div className="side-filters__nav-list no-scrollbar">
              {addClassShow &&
              generalSettings &&
              generalSettings.length &&
              !generalSettings.some(
                (stn) => stn.name == "only_grades" && stn.value
              ) ? (
                <div className={"side-filters__nav-list-item-button add "}>
                  <div
                    className={"add-container "}
                    onClick={() => {
                      setShowAddClassConfig(true);
                      socketContext.socket.emit("refreshAllUsersWithParams");
                    }}
                  >
                    <BiPlus
                      className={
                        "side-filters__container-title-arrow " +
                        (showAddClassConfig ? "active" : "")
                      }
                      size={"30px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                    <span>Προσθήκη Μαθήματος</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                key={"classItem -1"}
                className={
                  "side-filters__nav-list-item-button " +
                  (activeClassIndex == -1 && activeGradeIndex == -1
                    ? "active"
                    : "")
                }
                onClick={(e) => classItemClick(e, -1)}
              >
                <span>Όλα</span>
              </div>
              {gotClasses ? (
                !generalSettings.some(
                  (stn) => stn.name == "only_grades" && stn.value
                ) ? (
                  populateClasses()
                ) : (
                  populateClassesGrades()
                )
              ) : (
                <div className="loading-classes">{populateClassLoaders()}</div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SideFilters;
