// PrintPageEditor.jsx
import "./print-page-editor.scss";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SocketContext } from "../../app/socket";
import EditorWrapper from "./EditorWrapper/editorWrapper";
import EditorPreview from "./EditorPreview/editorPreview";
import { DragDropContext } from "react-beautiful-dnd";

function reorderList(list, startIndex, endIndex) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function moveBetweenLists(sourceList, destList, sourceIndex, destIndex) {
  const newSource = [...sourceList];
  const newDest = [...destList];
  const [removed] = newSource.splice(sourceIndex, 1);
  newDest.splice(destIndex, 0, removed);
  return { newSource, newDest };
}

function buildSection() {
  return {
    id: "section-" + Date.now(),
    type: "section",
    name: "New Section",
    layout: "single-column",
    styles: {
      padding: "1em",
      border: "1px dotted #ccc",
      marginBottom: "1em",
      display: "flex",
      flexDirection: "column",
    },
    components: [],
  };
}

/**
 * Build a component by toolboxId (tb-text, tb-image, tb-nestedSection).
 */
function buildComponent(toolboxId) {
  switch (toolboxId) {
    case "tb-text":
      return {
        id: "comp-" + Date.now(),
        type: "text",
        content: "New text",
        styles: { fontSize: "16px" },
      };
    case "tb-image":
      return {
        id: "comp-" + Date.now(),
        type: "image",
        content: "/placeholder.jpg",
        styles: { container: { width: "200px" } },
      };
    case "tb-nestedSection":
      // a "nested section" with 2 columns
      return {
        id: "comp-" + Date.now(),
        type: "nestedSection",
        columns: [
          {
            id: "col-" + Date.now() + "-1",
            width: "50%",
            components: [],
          },
          {
            id: "col-" + Date.now() + "-2",
            width: "50%",
            components: [],
          },
        ],
      };
    default:
      return {
        id: "comp-" + Date.now(),
        type: "text",
        content: "Unknown",
        styles: {},
      };
  }
}

/** Find a top-level section by ID. */
function findSectionById(list, sectionId) {
  return list.find((s) => String(s.id) === String(sectionId)) || null;
}

/**
 * parseNestedDroppableId(droppableId) => [ nestedSectionId, columnId ]
 * We expect droppableId = "nestedSection-col-<NSEC_ID>__<COL_ID>"
 * e.g.: "nestedSection-col-comp-123456__col-1692657388475-1"
 */
function parseNestedDroppableId(droppableId) {
  const [prefix, colId] = droppableId.split("__");
  // prefix should look like "nestedSection-col-comp-123456"
  const nestedSectionId = prefix.replace("nestedSection-col-", "");
  return [nestedSectionId, colId];
}

/**
 * Traverse sections => find one containing a "nestedSection" with a given ID.
 * Return { sectionObj, nestedComp } if found, else nulls.
 */
function findNestedSection(sections, nestedSectionId) {
  for (const section of sections) {
    for (const comp of section.components) {
      if (
        comp.type === "nestedSection" &&
        String(comp.id) === String(nestedSectionId)
      ) {
        return { sectionObj: section, nestedComp: comp };
      }
    }
  }
  return { sectionObj: null, nestedComp: null };
}

function PrintPageEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const socket = useContext(SocketContext).socket;

  const [template, setTemplate] = useState(null);
  const [printTemplatesId, setPrintTemplatesId] = useState(null);
  const [editorData, setEditorData] = useState(null);

  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedComponentId, setSelectedComponentId] = useState(null);

  const [dragKey, setDragKey] = useState(0);

  useEffect(() => {
    const p = new URLSearchParams(location.search);
    const tid = p.get("template-id");
    if (!tid) {
      navigate("/print");
    } else {
      setPrintTemplatesId(tid);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    if (printTemplatesId) getTemplate();
    // Cleanup
    return () => {
      if (socket && printTemplatesId) {
        socket.off("printTemplates" + printTemplatesId);
        socket.off("refreshPrintTemplates");
      }
    };
  }, [printTemplatesId, socket]);

  function getTemplate() {
    const args = { print_templates_id: printTemplatesId };
    socket.on("printTemplates" + printTemplatesId, (data) => {
      if (data && data[0]) setTemplate(data[0]);
      else setTemplate(null);
    });
    socket.emit("getPrintTemplates", args);
    socket.on("refreshPrintTemplates", () => {
      socket.emit("getPrintTemplates", args);
    });
  }

  useEffect(() => {
    if (template?.template_data) {
      try {
        setEditorData(JSON.parse(template.template_data));
      } catch {
        setEditorData(null);
      }
    } else {
      setEditorData(null);
    }
  }, [template]);

  // Re-mount DragDropContext each time data changes
  useEffect(() => {
    setDragKey((k) => k + 1);
  }, [editorData]);

  function handleSelect(sectionId, componentId) {
    setSelectedSectionId(sectionId);
    setSelectedComponentId(componentId);
  }

  function clearSelection() {
    setSelectedSectionId(null);
    setSelectedComponentId(null);
  }

  function updateComponent(sectionId, componentId, changes) {
    setEditorData((prev) => {
      if (!prev?.sections) return prev;
      const newData = structuredClone(prev);
      const sec = findSectionById(newData.sections, sectionId);
      if (!sec) return prev;

      sec.components = sec.components.map((c) => {
        if (String(c.id) === String(componentId)) {
          return { ...c, ...changes };
        }
        return c;
      });
      return newData;
    });
  }

  function updateSection(sectionId, changes) {
    setEditorData((prev) => {
      if (!prev?.sections) return prev;
      const newData = structuredClone(prev);

      const sec = findSectionById(newData.sections, sectionId);
      if (!sec) return prev;

      Object.assign(sec, changes);
      return newData;
    });
  }

  /**
   * handleDragEnd from react-beautiful-dnd
   */
  function handleDragEnd(result) {
    if (!result.destination) return;

    const { source, destination, draggableId, type } = result;
    const fromId = source.droppableId;
    const toId = destination.droppableId;
    const fromIndex = source.index;
    const toIndex = destination.index;

    setEditorData((prev) => {
      if (!prev?.sections) return prev;
      const newData = structuredClone(prev);

      // 1) DRAG a new SECTION from toolbox => top-level only
      if (type === "SECTION" && fromId === "toolbox-sections") {
        if (toId === "all-sections") {
          const newSec = buildSection();
          newData.sections.splice(toIndex, 0, newSec);
        }
        return newData;
      }

      // 2) DRAG a new COMPONENT (tb-text, tb-image, tb-nestedSection)
      if (type === "COMPONENT" && fromId === "toolbox-components") {
        // not allowed on "all-sections"
        if (toId === "all-sections") return prev;

        // dropping into a normal section’s .components
        if (toId.startsWith("components-section-")) {
          // check if it's nestedSection => OK
          const secId = toId.replace("components-section-", "");
          const sec = findSectionById(newData.sections, secId);
          if (!sec) return prev;

          const newComp = buildComponent(draggableId);
          sec.components.splice(toIndex, 0, newComp);
        }

        // dropping into a nestedSection column
        if (toId.startsWith("nestedSection-col-")) {
          // If the user is dragging a nestedSection => skip (no nesting a nestedSection inside another)
          if (draggableId === "tb-nestedSection") {
            // skip
            return prev;
          }

          const [nestedSectionId, colId] = parseNestedDroppableId(toId);
          const { sectionObj, nestedComp } = findNestedSection(
            newData.sections,
            nestedSectionId
          );
          if (!sectionObj || !nestedComp) return prev;

          const column = nestedComp.columns.find((col) => col.id === colId);
          if (!column) return prev;

          const newComp = buildComponent(draggableId);
          column.components.splice(toIndex, 0, newComp);
        }
        return newData;
      }

      // 3) REORDER / MOVE EXISTING SECTION => top-level only
      if (type === "SECTION") {
        if (fromId === "all-sections" && toId === "all-sections") {
          const reordered = reorderList(newData.sections, fromIndex, toIndex);
          newData.sections = reordered;
        }
        return newData;
      }

      // 4) REORDER / MOVE an EXISTING COMPONENT
      if (type === "COMPONENT") {
        // (a) from a normal section
        if (fromId.startsWith("components-section-")) {
          const fromSecId = fromId.replace("components-section-", "");
          const fromSec = findSectionById(newData.sections, fromSecId);
          if (!fromSec) return prev;

          const item = fromSec.components[fromIndex];
          if (!item) return prev;

          // If item is "nestedSection" and we are dropping into a nestedSection column => skip
          if (
            item.type === "nestedSection" &&
            toId.startsWith("nestedSection-col-")
          ) {
            return prev;
          }

          // same droppable => reorder
          if (fromId === toId) {
            const reordered = reorderList(
              fromSec.components,
              fromIndex,
              toIndex
            );
            fromSec.components = reordered;
            return newData;
          }

          // move to another section
          if (toId.startsWith("components-section-")) {
            const toSecId = toId.replace("components-section-", "");
            const toSec = findSectionById(newData.sections, toSecId);
            if (!toSec) return prev;

            const fromArr = [...fromSec.components];
            const toArr = [...toSec.components];
            if (fromSecId === toSecId) {
              // reorder
              const reordered = reorderList(fromArr, fromIndex, toIndex);
              fromSec.components = reordered;
            } else {
              // move
              const { newSource, newDest } = moveBetweenLists(
                fromArr,
                toArr,
                fromIndex,
                toIndex
              );
              fromSec.components = newSource;
              toSec.components = newDest;
            }
            return newData;
          }

          // move to a nestedSection column
          if (toId.startsWith("nestedSection-col-")) {
            // again, skip if item is a nestedSection => no infinite nesting
            if (item.type === "nestedSection") {
              return prev;
            }

            const [nsecId, colId] = parseNestedDroppableId(toId);
            const { nestedComp } = findNestedSection(newData.sections, nsecId);
            if (!nestedComp) return prev;

            const toColumn = nestedComp.columns.find((c) => c.id === colId);
            if (!toColumn) return prev;

            // remove from old
            fromSec.components.splice(fromIndex, 1);
            // insert in new
            toColumn.components.splice(toIndex, 0, item);
            return newData;
          }
        }

        // (b) from a nestedSection column
        if (fromId.startsWith("nestedSection-col-")) {
          const [fromNsecId, fromColId] = parseNestedDroppableId(fromId);
          const { nestedComp: fromNested } = findNestedSection(
            newData.sections,
            fromNsecId
          );
          if (!fromNested) return prev;

          const fromColumn = fromNested.columns.find(
            (col) => col.id === fromColId
          );
          if (!fromColumn) return prev;

          const item = fromColumn.components[fromIndex];
          if (!item) return prev;

          // If item is nestedSection => skip any further nesting
          if (
            item.type === "nestedSection" &&
            toId.startsWith("nestedSection-col-")
          ) {
            return prev;
          }

          // reorder in same column
          if (fromId === toId) {
            const reordered = reorderList(
              fromColumn.components,
              fromIndex,
              toIndex
            );
            fromColumn.components = reordered;
            return newData;
          }

          // move to another nested column
          if (toId.startsWith("nestedSection-col-")) {
            const [toNsecId, toColId] = parseNestedDroppableId(toId);
            const { nestedComp: toNested } = findNestedSection(
              newData.sections,
              toNsecId
            );
            if (!toNested) return prev;

            const toColumn = toNested.columns.find((c) => c.id === toColId);
            if (!toColumn) return prev;

            // skip if item is nestedSection => no infinite nesting
            if (item.type === "nestedSection") {
              return prev;
            }

            // remove from old
            fromColumn.components.splice(fromIndex, 1);
            // add to new
            toColumn.components.splice(toIndex, 0, item);
            return newData;
          }

          // move to a normal section
          if (toId.startsWith("components-section-")) {
            const toSecId = toId.replace("components-section-", "");
            const toSec = findSectionById(newData.sections, toSecId);
            if (!toSec) return prev;

            // remove
            fromColumn.components.splice(fromIndex, 1);
            // add
            toSec.components.splice(toIndex, 0, item);
            return newData;
          }
        }
      }

      return prev;
    });
  }

  return (
    <DragDropContext key={dragKey} onDragEnd={handleDragEnd}>
      <div className="print-page-editor" style={{ overflow: "visible" }}>
        {editorData && (
          <EditorWrapper
            editorData={editorData}
            selectedSectionId={selectedSectionId}
            selectedComponentId={selectedComponentId}
            updateComponent={updateComponent}
            updateSection={updateSection}
            clearSelection={clearSelection}
          />
        )}
        <div className="editor-preview">
          <div className="editor-preview__wrapper">
            {editorData && (
              <EditorPreview
                data={editorData}
                onSelect={handleSelect}
                setEditorData={setEditorData}
              />
            )}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}

export default PrintPageEditor;
