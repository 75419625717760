import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../../../app/socket";
import { BiTime } from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import "./parent-event.scss";
import { useSelector } from "react-redux";
import ProfessorSelector from "./professorSelector/professorSelector";

function ParentEvent({ event, closeCard }) {
  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);
  const childrenDepartments = useSelector(
    (state) => state.profile.childrenDepartments
  );

  const [professors, setProfessors] = useState([]);
  const [minutes, setMinutes] = useState(0);
  const [profExpanded, setProfExpanded] = useState(false);
  const [selectedProf, setSelectedProf] = useState();
  const [dates, setDates] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]); // State to hold time slots
  const [selectedSlot, setSelectedSlot] = useState(null); // State to hold selected slot
  const [parentEvents, setParentEvents] = useState([]);

  const [availability, setAvailability] = useState([]);

  const [isPhone, setIsPhone] = useState(false);

  // Greek day names
  const greekDays = [
    "Κυριακή",
    "Δευτέρα",
    "Τρίτη",
    "Τετάρτη",
    "Πέμπτη",
    "Παρασκευή",
    "Σάββατο",
  ];

  useEffect(() => {
    const cleanUpGetProfessors = getProfessors();
    return () => {
      cleanUpGetProfessors();
    };
  }, []);

  useEffect(() => {
    setSelectedSlot();
    const cleanUpGetParentEvents = getParentEvents();
    return () => {
      cleanUpGetParentEvents();
    };
  }, [selectedProf]);

  useEffect(() => {
    if (selectedProf && selectedProf.user_id) {
      return getProfessorsAvailability();
    }
  }, [selectedProf]);

  useEffect(() => {
    if (event) {
      setMinutes(event.event_duration);
      generateDateList(event.start_at, event.finish_at);
      generateTimeSlots(event.start_at, event.finish_at, event.event_duration);
    }
  }, [event]);

  const getProfessorsAvailability = () => {
    let args = { user_id: selectedProf.user_id };

    const getProfessorsAvailabilityListener = (data) => {
      setAvailability(data);
    };

    socketContext.socket.on(
      "availability" + selectedProf.user_id,
      getProfessorsAvailabilityListener
    );
    socketContext.socket.emit("getAvailability", args);
  };

  const getProfessors = () => {
    let args = {
      class_id: event.class_id,
      department_id: childrenDepartments,
      event_id: event.event_id,
    };

    const getProfessorsListener = (data) => {
      setProfessors(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getParentProfessors", args);
    };

    socketContext.socket.on("parentProfessors", getProfessorsListener);
    socketContext.socket.emit("getParentProfessors", args);
    socketContext.socket.on(
      "refreshParentProfessors",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getParentProfessors", getProfessorsListener);
      socketContext.socket.off("parentProfessors", getProfessorsListener);
      socketContext.socket.off(
        "refreshParentProfessors",
        refreshProfessorsListener
      );
    };
  };

  const getParentEvents = () => {
    let args = {
      parent_event_id: event.event_id,
      professor_id: selectedProf ? selectedProf.user_id : -1,
    };

    const getParentEventsListener = (data) => {
      const adjustedData = data.map((event) => {
        return {
          ...event,
          start_at: new Date(event.start_at), // Convert to Date object
          finish_at: new Date(event.finish_at), // Convert to Date object
        };
      });
      if (selectedProf) {
        setParentEvents(adjustedData);
      } else {
        setParentEvents([]);
      }
    };

    const refreshParentEventsListener = () => {
      console.log(" refreshed parent events ");
      socketContext.socket.emit("getParentEvents", args);
    };

    socketContext.socket.on("parentEvents", getParentEventsListener);
    socketContext.socket.emit("getParentEvents", args);
    socketContext.socket.on("refreshParentEvents", refreshParentEventsListener);

    return () => {
      socketContext.socket.off("getParentEvents", getParentEventsListener);
      socketContext.socket.off("parentEvents", getParentEventsListener);
      socketContext.socket.off(
        "refreshParentEvents",
        refreshParentEventsListener
      );
    };
  };

  // Function to generate date list
  const generateDateList = (startAt, finishAt) => {
    const startDate = new Date(startAt);
    const endDate = new Date(finishAt);
    const days = [];

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dayName = greekDays[date.getDay()];
      const formattedDate = `${dayName} - ${date
        .getDate()
        .toString()
        .padStart(2, "0")}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
      days.push({ date: new Date(date), formatted: formattedDate }); // Ensure date is a Date object
    }

    setDates(days);
    setSelectedDay(days[0]); // Select the start date by default
  };

  // Function to generate time slots
  const generateTimeSlots = (startAt, finishAt, duration) => {
    const start = new Date(startAt);
    const end = new Date(finishAt);
    const slots = [];

    let currentTime = new Date(start);
    while (currentTime < end) {
      const slotStart = new Date(currentTime); // Create new instance for slot start
      const slotEnd = new Date(currentTime); // Create new instance for slot end
      slotEnd.setMinutes(slotEnd.getMinutes() + duration);

      if (slotEnd > end) break; // Stop if slot end time goes beyond finishAt

      slots.push({
        start: slotStart,
        end: slotEnd,
        formatted: `${slotStart.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${slotEnd.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
      });

      currentTime = new Date(slotEnd); // Move to the next slot start time
    }

    setTimeSlots(slots);
    setSelectedSlot(slots[0]); // Select the first slot by default
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  // Function to check if a slot is taken based on parentEvents and selectedDay
  const isSlotTaken = (slot) => {
    if (!selectedDay) return false;

    // Map JavaScript's getDay() (0-6) to your availability day numbering (1-7)
    const getAvailabilityDay = (jsDay) => {
      return jsDay === 0 ? 7 : jsDay;
    };
    const availabilityDayNumber = getAvailabilityDay(selectedDay.date.getDay());

    // Filter availability entries for the selected day
    const dayAvailability = availability.filter(
      (a) => a.day === availabilityDayNumber
    );

    let slotIsOutsideAvailability = false;

    if (dayAvailability.length > 0) {
      // There is availability data for this day
      const slotStartTime = slot.start;

      let slotIsInAvailability = false;

      for (let avail of dayAvailability) {
        // Parse availability start and end times
        const [startHour, startMinute] = avail.start_time
          .split(":")
          .map(Number);
        const [endHour, endMinute] = avail.end_time.split(":").map(Number);

        const availStartTime = new Date(selectedDay.date);
        availStartTime.setHours(startHour, startMinute, 0, 0);

        const availEndTime = new Date(selectedDay.date);
        availEndTime.setHours(endHour, endMinute, 0, 0);

        // Check if slot start time is within the availability period
        if (slotStartTime >= availStartTime && slotStartTime < availEndTime) {
          slotIsInAvailability = true;
          break;
        }
      }

      // Mark slot as taken if it's outside the availability periods
      if (!slotIsInAvailability) {
        slotIsOutsideAvailability = true;
      }
    }

    // Check if the slot is already taken in parentEvents
    const slotIsTakenByParentEvents = parentEvents.some((event) => {
      if (
        !(event.start_at instanceof Date) ||
        !(event.finish_at instanceof Date)
      )
        return false;

      const eventDate = event.start_at.toDateString();
      const selectedDate = selectedDay.date.toDateString();

      if (eventDate !== selectedDate) return false;

      return slot.start >= event.start_at && slot.start < event.finish_at;
    });

    // Slot is taken if it's outside availability or already booked
    return slotIsOutsideAvailability || slotIsTakenByParentEvents;
  };

  const saveSlot = () => {
    if (selectedSlot && selectedDay && selectedProf) {
      let temp = [];
      temp.push(selectedProf.user_id);

      const hash = window.crypto.randomUUID();
      let meeting_url = "https://meet.jit.si/" + hash;

      // Create new Date objects for eventStartDate and eventEndDate
      const eventStartDate = new Date(selectedDay.date);
      eventStartDate.setHours(selectedSlot.start.getHours());
      eventStartDate.setMinutes(selectedSlot.start.getMinutes());

      const eventEndDate = new Date(selectedDay.date);
      eventEndDate.setHours(selectedSlot.end.getHours());
      eventEndDate.setMinutes(selectedSlot.end.getMinutes());

      // Format start_at and finish_at as local date-time strings
      const start_at = `${eventStartDate.getFullYear()}-${(
        eventStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${eventStartDate
        .getDate()
        .toString()
        .padStart(2, "0")} ${eventStartDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${eventStartDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      const finish_at = `${eventEndDate.getFullYear()}-${(
        eventEndDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${eventEndDate
        .getDate()
        .toString()
        .padStart(2, "0")} ${eventEndDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${eventEndDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      // Create the body for the request
      let body = {
        title:
          "Ενημέρωση γονέα:  " + profile.last_name + " " + profile.first_name,
        start_at: start_at, // Local date-time string
        finish_at: finish_at, // Local date-time string
        type: "parent-event-meeting",
        classroom_id: "",
        class_id: event.class_id,
        department_id: event.department_id,
        repeat_type: "",
        classroom_name: "",
        professors: JSON.stringify(temp),
        meeting_url: meeting_url,
        duration: event.event_duration,
        old_event: false,
        parent_id: profile.user_id,
        parent_event_id: event.event_id,
        is_phone: isPhone,
      };
      socketContext.socket.emit("addEvent", body);
      closeCard();
      // Here, you can add the logic to send 'body' to the server or handle it further.
    }
  };

  return (
    <div className="parent-event__card">
      <div className="parent-event__config">
        <div className="config__general">
          <div className="config__general-prof">
            <div className="config__general-prof-parent hide-mobile">
              <img
                className="profile-img"
                src={
                  profile.profile_picture
                    ? profile.profile_picture
                    : "resources/student.png"
                }
                alt={"student"}
              />
              <div className="profile-name">
                <span>{profile.last_name}</span>
                <span>{profile.first_name}</span>
              </div>
            </div>
            <div className="config__general-prof-duration">
              <span>
                <BiTime
                  size={"20px"}
                  color={"#ccc"}
                  style={iconStyle("transparent")}
                />
                {minutes} λεπτά
              </span>
            </div>
            <div className="config__general-prof-selector">
              {!selectedProf ? (
                <ProfessorSelector
                  isExpanded={profExpanded}
                  setIsExpanded={setProfExpanded}
                  professors={professors}
                  selectedProf={selectedProf}
                  setSelectedProf={setSelectedProf}
                />
              ) : (
                <div className="selected-prof">
                  <div className="config__general-prof-parent prof">
                    <img
                      className="profile-img"
                      src={
                        selectedProf.profile_picture
                          ? selectedProf.profile_picture
                          : "resources/student.png"
                      }
                      alt={"student"}
                    />
                    <div className="profile-name">
                      <span>{selectedProf.last_name}</span>
                      <span>{selectedProf.first_name}</span>
                    </div>
                  </div>
                  <ProfessorSelector
                    isExpanded={profExpanded}
                    setIsExpanded={setProfExpanded}
                    professors={professors}
                    selectedProf={selectedProf}
                    setSelectedProf={setSelectedProf}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="config__phone">
            <div className="target input-container">
              <div className="target__select">
                <div className="item" onClick={() => setIsPhone(!isPhone)}>
                  <div
                    className={
                      "target__select-radio " + (isPhone ? "active" : "")
                    }
                  ></div>
                  <span>Τηλεφωνική Σύσκεψη</span>
                </div>
              </div>
            </div>
          </div>
          <div className="config__date">
            <span className="config__date-title">Ημερομηνία: </span>
            <div className="config__date-list">
              {dates.map((day, index) => (
                <div
                  key={"config__date-date" + index}
                  className={`config__date-list-item ${
                    selectedDay &&
                    selectedDay.date instanceof Date &&
                    selectedDay.date.toDateString() === day.date.toDateString()
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleDayClick(day)}
                >
                  <span>{day.formatted}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="config__time">
          <span className="config__time-title">Ώρα</span>
          <div className="config__time-list">
            {timeSlots.map((slot, index) => (
              <div
                key={"config__time-slot" + index}
                className={`config__time-list-item ${
                  isSlotTaken(slot) || !selectedProf ? "taken" : ""
                } ${
                  selectedSlot &&
                  selectedSlot.start.toTimeString() ===
                    slot.start.toTimeString()
                    ? "active"
                    : ""
                }`}
                onClick={() => !isSlotTaken(slot) && handleSlotClick(slot)}
              >
                <span>{slot.formatted}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="parent-event__card-save">
        <button className="cta" onClick={() => saveSlot()}>
          Αποθήκευση
        </button>
      </div>
    </div>
  );
}

export default ParentEvent;
