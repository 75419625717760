import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiPencil,
  BiPlus,
  BiPrinter,
  BiRotateLeft,
  BiUpload,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import { useSelector } from "react-redux";
import "./professor-selector.scss";

function ProfessorSelector({
  isExpanded,
  setIsExpanded,
  professors,
  selectedProf,
  setSelectedProf,
}) {
  const containerRef = useRef(null);
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const schoolLogo = useSelector((state) => state.profile.schoolLogo);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.2 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "60vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    // if (window.innerWidth < 700) {
    //   if (isExpanded) {
    //     document.getElementById("main-app").style.overflowY = "hidden";
    //   } else {
    //     document.getElementById("main-app").style.overflowY = "auto";
    //   }
    // } else {
    //   if (isExpanded) {
    //     document.body.style.overflowY = "hidden";
    //   } else {
    //     document.body.style.overflowY = "auto";
    //   }
    // }
  }, [isExpanded]);

  const selectProf = (prof) => {
    setSelectedProf(prof);
    setIsExpanded(false);
  };

  const populateProfessors = () => {
    return professors.map((prof) => {
      return (
        <div
          key={"prof-selector" + prof.user_id}
          className={
            "professor-selector__config-list-item " +
            (selectedProf && selectedProf.user_id == prof.user_id
              ? "selected"
              : "")
          }
          onClick={() => selectProf(prof)}
        >
          <div className="prof-personal">
            <div className="prof-personal__img">
              <img
                src={
                  prof.profile_picture
                    ? prof.profile_picture
                    : "resources/student.png"
                }
              />
            </div>
            <div className="prof-personal__name">
              <span>{prof.last_name}</span>
              <span>{prof.first_name}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      ref={containerRef}
      className={
        "professor-selector " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "professor-selector__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="professor-selector__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="professor-selector__config-list">
              {populateProfessors()}
            </div>
            {/* <div className="professor-selector__config-actions">
              <button className="cta">Επιλογή</button>
            </div> */}
            <div
              className="professor-selector__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="professor-selector__preview">
            {selectedProf ? (
              <BiRotateLeft
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            ) : (
              <BiPlus
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
            {selectedProf ? (
              <span>Αλλαγή Καθηγητή</span>
            ) : (
              <span>Επιλογή Καθηγητή</span>
            )}
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default ProfessorSelector;
