import "./print-page.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SocketContext } from "../../app/socket";
import { useContext } from "react";
import { BiPlus, BiSearch, BiTimer } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import AddPrintTemplate from "./AddPrintTemplate/addPrintTemplate";
import { renderComponent } from "../../utils/printComponents/defaultComponents";
import TemplatePreview from "./TemplatePreview/templatePreview";
import { Link } from "react-router-dom";
import TemplateItem from "./TemplateItem/templateItem";

function PrintPage() {
  const socketContext = useContext(SocketContext);
  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const [templates, setTemplates] = useState([]);
  const [createExpanded, setCreateExpanded] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    const cleanUpGetTemplates = getTemplates();
    return () => {
      cleanUpGetTemplates();
    };
  }, [search]);

  const getTemplates = () => {
    let args = { search: search };

    const getTemplatesListener = (data) => {
      setTemplates(data);
    };

    const refreshPrintTemplatesListener = () => {
      socketContext.socket.emit("getPrintTemplates", args);
    };

    socketContext.socket.on("printTemplates", getTemplatesListener);
    socketContext.socket.emit("getPrintTemplates", args);
    socketContext.socket.on(
      "refreshPrintTemplates",
      refreshPrintTemplatesListener
    );

    return () => {
      socketContext.socket.off("getPrintTemplates", getTemplatesListener);
      socketContext.socket.off("printTemplates", getTemplatesListener);
      socketContext.socket.off(
        "refreshPrintTemplates",
        refreshPrintTemplatesListener
      );
    };
  };

  const populateTemplates = () => {
    return templates.map((template) => {
      const { print_templates_id, title, template_data } = template;

      let parsedTemplateData;
      try {
        parsedTemplateData = JSON.parse(template_data); // Parse the JSON string
      } catch (error) {
        console.error("Error parsing template_data:", error);
        return null; // Skip rendering this template
      }

      return (
        <TemplateItem
          key={print_templates_id + " template item"}
          templateData={parsedTemplateData}
          templateTitle={title}
          templateId={print_templates_id}
        />
      );
    });
  };

  return (
    <div className="print-page">
      <div className="print-page__nav">
        <div className="print-page__nav-add">
          <AddPrintTemplate
            isExpanded={createExpanded}
            setIsExpanded={setCreateExpanded}
          />
        </div>
        <div className="print-page__nav-search">
          <input
            placeholder="Αναζήτηση πρότυπου"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <BiSearch
            size={"25px"}
            color={"#ccc"}
            style={iconStyle("transparent")}
          />
        </div>
      </div>
      <div className="print-page__list">{populateTemplates()}</div>
    </div>
  );
}

export default PrintPage;
