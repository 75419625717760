import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiCheck,
  BiPlus,
  BiSearch,
  BiTrash,
  BiUpload,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./add-expense.scss";
import { useSelector } from "react-redux";
import Compress from "compress.js";
import ExamThumbnail from "../../../../components/ExamThumbnail/exam-thumbnail";
import { calls } from "../../../../config/db_config";
import { tokenUtils } from "../../../../utils/token-utils";
import axios from "axios";
import { nanoid } from "nanoid";
import { fileUtils } from "../../../../utils/fileUtils";
import ImageSlider from "../../../../components/ImageSlider/imageSlider";

function ExpensesItem({ expense, expensesTypes }) {
  const envVars = useSelector((state) => state.profile.environmentVars);
  const socketContext = useContext(SocketContext);
  const hasCameraAccess = useSelector((state) => state.profile.hasCameraAccess);
  const [style, setStyle] = useState({});
  const [allFiles, setAllFiles] = useState([]);

  const [isDeleting, setIsDeleting] = useState(false);

  const [openDelete, setOpenDelete] = useState(null);
  const [imageIsOpen, setImageIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState("");

  const openFile = (image) => {
    setImageIsOpen(true);
    setActiveImage(image);
  };

  useEffect(() => {
    if (expense.files) {
      try {
        const temp = [];
        const tempFiles = JSON.parse(expense.files);
        tempFiles.map((file) => {
          file.map((fileItem) => {
            temp.push(fileItem);
          });
        });
        setAllFiles(temp);
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    if (imageIsOpen) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
    } else {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [imageIsOpen]);

  const populateFiles = (curriculum) => {
    try {
      const tempFiles = JSON.parse(curriculum.files);
      return tempFiles.map((images, index) => {
        return images.map((image, imageIndex) => {
          const lastIndex = image.lastIndexOf("/");
          const imageName = image.substring(lastIndex + 1);
          const fileExtension = image.substring(image.lastIndexOf(".") + 1);

          return (
            <div
              key={"asset__item" + imageIndex}
              className={
                "curriculum-card__files-item " +
                (fileExtension !== "pdf" && fileExtension != "PDF"
                  ? "img"
                  : "pdf") +
                (fileExtension == "mp3" || fileExtension == "wma"
                  ? " audio"
                  : "") +
                (fileExtension == "ppt" || fileExtension == "pptx"
                  ? " ppt"
                  : "") +
                (fileExtension == "doc" || fileExtension == "docx"
                  ? " doc"
                  : "")
              }
              onClick={() => openFile(image)}
              title={imageName}
            >
              <div className="curriculum-card__files-item-overlay">
                <BiSearch
                  size={"30px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                />
              </div>
              {image.substring(image.lastIndexOf(".") + 1) == "pdf" ||
              image.substring(image.lastIndexOf(".") + 1) == "PDF" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/pdf-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) == "ppt" ||
              image.substring(image.lastIndexOf(".") + 1) == "pptx" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/ppt-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) == "doc" ||
              image.substring(image.lastIndexOf(".") + 1) == "docx" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/word-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) == "mp3" ||
              image.substring(image.lastIndexOf(".") + 1) == "MP3" ||
              image.substring(image.lastIndexOf(".") + 1) == "wma" ||
              image.substring(image.lastIndexOf(".") + 1) == "WMA" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/audio-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) !== "pdf" &&
              image.substring(image.lastIndexOf(".") + 1) !== "PDF" &&
              image.substring(image.lastIndexOf(".") + 1) !== "ppt" &&
              image.substring(image.lastIndexOf(".") + 1) !== "docx" &&
              image.substring(image.lastIndexOf(".") + 1) !== "mp3" &&
              image.substring(image.lastIndexOf(".") + 1) !== "wma" ? (
                <img className="card__info-submitted-img img" src={image} />
              ) : (
                ""
              )}
            </div>
          );
        });
      });
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const deleteItem = (id) => {
    console.log(`Deleting item with id: ${id}`);
    setOpenDelete(null);
    let body = { expense_id: id };
    socketContext.socket.emit("deleteSchoolExpense", body);
  };

  return (
    <div className="expenses-timeline__item">
      <div className="item-details">
        <span className="expenses-timeline__item-type">
          {expensesTypes.find(
            (type) => type.school_expenses_type_id == expense.expense_type
          )?.name || "Unknown"}
        </span>
        <span className="expenses-timeline__item-date">
          {new Date(expense.date).toLocaleDateString("el-GR")}
        </span>
        <span className="expenses-timeline__item-ammount">
          <span>Ποσό: </span>
          <span>{expense.amount} €</span>
        </span>
      </div>
      <div className="item-files">
        {expense.files ? (
          <div className="curriculum-card__files">{populateFiles(expense)}</div>
        ) : (
          ""
        )}
      </div>
      <div className="expenses-timeline__item-actions">
        {isDeleting ? (
          <>
            <BiCheck
              size={"25px"}
              color={"#00c700"}
              style={iconStyle("transparent")}
              onClick={() => deleteItem(expense.school_expenses_id)}
            />
            <BiX
              size={"25px"}
              color={"#ff0000"}
              style={iconStyle("transparent")}
              onClick={() => setOpenDelete(null)}
            />
          </>
        ) : (
          <BiTrash
            size={"25px"}
            color={"#c7c7c7"}
            style={iconStyle("transparent")}
            onClick={() => setOpenDelete(expense.school_expenses_id)}
          />
        )}
      </div>
      {imageIsOpen ? (
        <div
          onClick={() => setImageIsOpen(false)}
          className={"modal-background show z-index"}
        ></div>
      ) : (
        ""
      )}
      {imageIsOpen ? (
        <ImageSlider assets={allFiles} activeAsset={activeImage} />
      ) : (
        ""
      )}
    </div>
  );
}

export default ExpensesItem;
