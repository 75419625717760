import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../../../app/socket";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BiPlus, BiCheck, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { useSelector } from "react-redux";
import "./classes-section.scss";
import ClassSelector from "../../../../components/ClassSelector/classSelector";
import { usePrintUsersContext } from "../printUsersContext";

function ClassesSection() {
  const socketContext = useContext(SocketContext);
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const {
    printTitle,
    setPrintTitle,
    selectedClasses,
    setSelectedClasses,
    selectedGrades,
    setSelectedGrades,
    includeParents,
    setIncludeParents,
    includeParentPhones,
    setIncludeParentPhones,
    includeStudentPhone,
    setIncludeStudentPhone,
  } = usePrintUsersContext();

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetClasses();
    };
  }, []);

  const getClasses = () => {
    let args = {};

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  return (
    <div className="classes-section">
      <div className="classes-section__container">
        <span className="classes-section__title">Τίτλος Καρτέλας</span>
        <input
          className="input"
          value={printTitle}
          onChange={(e) => setPrintTitle(e.target.value)}
        />
      </div>
      <div className="classes-section__selector">
        <ClassSelector
          classes={classes}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
          selectedGrades={selectedGrades}
          setSelectedGrades={setSelectedGrades}
        />
      </div>
      <div className="target input-container">
        <span className="label fc">Προσωπικά στοιχεία μαθητών:</span>
        <div className="target__select">
          <div
            className="item"
            onClick={() => setIncludeParents(!includeParents)}
          >
            <div
              className={
                "target__select-radio " + (includeParents ? "active" : "")
              }
            ></div>
            <span className="fc">Γονείς μαθητών</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeParentPhones(!includeParentPhones)}
          >
            <div
              className={
                "target__select-radio " + (includeParentPhones ? "active" : "")
              }
            ></div>
            <span className="fc">Τηλέφωνο Γονέων</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeStudentPhone(!includeStudentPhone)}
          >
            <div
              className={
                "target__select-radio " + (includeStudentPhone ? "active" : "")
              }
            ></div>
            <span className="fc">Τηλέφωνο μαθητή</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassesSection;
