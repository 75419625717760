// EditorWrapper.jsx
import "./editor-wrapper.scss";
import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Inspector from "./Inspector/inspector";
import ButtonMultipleToggle from "../../../components/ButtonMultipleToggle/buttonMultipleToggle";
import { BiImageAlt, BiX } from "react-icons/bi";
import {
  BsAspectRatio,
  BsBoundingBoxCircles,
  BsCardText,
  BsCast,
  BsLayoutSplit,
} from "react-icons/bs";
import { iconStyle } from "../../../utils/generalUtils";

function EditorWrapper({
  editorData,
  selectedSectionId,
  selectedComponentId,
  updateComponent,
  updateSection,
  clearSelection,
}) {
  const toolboxSections = [{ id: "tb-section", label: "Νέα Ενότητα" }];

  const [toggleTypeNum, setToggleTypeNum] = useState(1);
  const [toggleType, setToggleType] = useState("Editor");

  const toolboxComponents = [
    { id: "tb-text", label: "Κείμενο" },
    { id: "tb-image", label: "Φωτογραφία" },
    { id: "tb-nestedSection", label: "Στήλες" },
  ];

  return (
    <div className="editor-wrapper">
      <ButtonMultipleToggle
        button1="Editor"
        button2="Settings"
        setSelected={setToggleType}
        selected={toggleTypeNum}
      ></ButtonMultipleToggle>
      {!selectedComponentId && !selectedSectionId ? (
        <div className="editor-wrapper__toolbox">
          <Droppable droppableId="toolbox-sections" type="SECTION">
            {(provided) => (
              <div
                className="editor-wrapper__toolbox-section"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {toolboxSections.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    style={{ width: "50%" }}
                  >
                    {(dragProvided) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className="item-container"
                        style={{
                          padding: "0.5em",
                          ...dragProvided.draggableProps.style,
                        }}
                      >
                        <BsAspectRatio
                          size={"40px"}
                          color={"#cccccc"}
                          style={iconStyle("transparent")}
                        />
                        <span className="item-container__label">
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable
            className="editor-wrapper__toolbox-item"
            droppableId="toolbox-components"
            type="COMPONENT"
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="editor-wrapper__toolbox-components"
              >
                {toolboxComponents.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(dragProvided) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className="item-container"
                        style={{
                          padding: "0.5em",
                          ...dragProvided.draggableProps.style,
                        }}
                      >
                        {item.id == "tb-nestedSection" ? (
                          <BsLayoutSplit
                            size={"40px"}
                            color={"#cccccc"}
                            style={iconStyle("transparent")}
                          />
                        ) : (
                          ""
                        )}
                        {item.id == "tb-image" ? (
                          <BiImageAlt
                            size={"40px"}
                            color={"#cccccc"}
                            style={iconStyle("transparent")}
                          />
                        ) : (
                          ""
                        )}
                        {item.id == "tb-text" ? (
                          <BsCardText
                            size={"40px"}
                            color={"#cccccc"}
                            style={iconStyle("transparent")}
                          />
                        ) : (
                          ""
                        )}
                        <span className="item-container__label">
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ) : (
        <div
          className="editor-wrapper__inspector"
          style={{
            display:
              selectedSectionId || selectedComponentId ? "block" : "none",
          }}
        >
          <button onClick={clearSelection}>Close Editor</button>
          <Inspector
            editorData={editorData}
            selectedSectionId={selectedSectionId}
            selectedComponentId={selectedComponentId}
            updateComponent={updateComponent}
            updateSection={updateSection}
          />
        </div>
      )}
      <div className="editor-wrapper__save">
        <button className="cta ">Αποθήκευση</button>
      </div>
    </div>
  );
}

export default EditorWrapper;
