import React, { createContext, useState, useContext, useEffect } from "react";

// Step 1: Create the context
export const PrintUsersContext = createContext();

// Step 2: Create a provider component
export const PrintUsersProvider = ({ children }) => {
  const [printTitle, setPrintTitle] = useState("Καρτέλα Μαθητών");
  const [selectedClasses, setSelectedClasses] = useState([-1]);
  const [selectedGrades, setSelectedGrades] = useState([-1]);
  const [includeParents, setIncludeParents] = useState(false);
  const [includeParentPhones, setIncludeParentPhones] = useState(false);
  const [includeStudentPhone, setIncludeStudentPhone] = useState(false);

  const [includeExams, setIncludeExams] = useState(false);
  const [includeEvaluation, setIncludeEvaluation] = useState(false);
  const [includeTest, setIncludeTest] = useState(false);
  const [includeProgress, setIncludeProgress] = useState(false);
  const [includeUnderstanding, setIncludeUnderstanding] = useState(false);
  const [includeComments, setIncludeComments] = useState(false);
  const [includeDismissals, setIncludeDismissals] = useState(false);

  const currentDate = new Date();
  const oneMonthAhead = new Date();
  oneMonthAhead.setMonth(currentDate.getMonth() + 1);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startAt, setStartAt] = useState(formatDate(currentDate));
  const [finishAt, setFinishAt] = useState(formatDate(oneMonthAhead));
  const [filterExams, setFilterExams] = useState(false);
  const [filterEvaluation, setFilterEvaluation] = useState(false);
  const [filterTest, setFilterTest] = useState(false);
  const [filterProgress, setFilterProgress] = useState(false);
  const [filterUnderstanding, setFilterUnderstanding] = useState(false);
  const [filterComments, setFilterComments] = useState(false);
  const [filterDismissals, setFilterDismissals] = useState(false);

  const [examMaxGrade, setExamMaxGrade] = useState(100);
  const [examMinGrade, setExamMinGrade] = useState(0);
  const [testMaxGrade, setTestMaxGrade] = useState(100);
  const [testMinGrade, setTestMinGrade] = useState(0);

  const [progressMaxGrade, setProgressMaxGrade] = useState(4);
  const [progressMinGrade, setProgressMinGrade] = useState(0);
  const [understandingMaxGrade, setUnderstandingMaxGrade] = useState(4);
  const [understandingMinGrade, setUnderstandingMinGrade] = useState(0);

  return (
    <PrintUsersContext.Provider
      value={{
        printTitle,
        setPrintTitle,
        selectedClasses,
        setSelectedClasses,
        selectedGrades,
        setSelectedGrades,
        includeParents,
        setIncludeParents,
        includeParentPhones,
        setIncludeParentPhones,
        includeStudentPhone,
        setIncludeStudentPhone,
        includeExams,
        setIncludeExams,
        includeExams,
        setIncludeExams,
        includeTest,
        setIncludeTest,
        includeEvaluation,
        setIncludeEvaluation,
        includeProgress,
        setIncludeProgress,
        includeUnderstanding,
        setIncludeUnderstanding,
        includeComments,
        setIncludeComments,
        includeDismissals,
        setIncludeDismissals,
        startAt,
        setStartAt,
        finishAt,
        setFinishAt,
        filterProgress,
        setFilterProgress,
        filterUnderstanding,
        setFilterUnderstanding,
        progressMaxGrade,
        setProgressMaxGrade,
        progressMinGrade,
        setProgressMinGrade,
      }}
    >
      {children}
    </PrintUsersContext.Provider>
  );
};

// Step 3: Create a custom hook for easier consumption of the context
export const usePrintUsersContext = () => {
  const context = useContext(PrintUsersContext);
  if (!context) {
    throw new Error(
      "usePrintUsersContext must be used within an EventProvider"
    );
  }
  return context;
};
