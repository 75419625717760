import React, { useState } from "react";
import "./editor-preview.scss";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { componentRenderer } from "../../../utils/printComponents/defaultComponents";
import { BsArrowsMove } from "react-icons/bs";
import { iconStyle } from "../../../utils/generalUtils";
import { BiTrash } from "react-icons/bi";

function EditorPreview({ data, onSelect, setEditorData }) {
  const [isDragging, setIsDragging] = useState(false);

  function handleLocalDragStart() {
    setIsDragging(true);
  }
  function handleLocalDragEnd() {
    setIsDragging(false);
  }

  /**
   * Deletes a entire section from `data.sections`.
   */
  function handleDeleteSection(sectionId) {
    if (!setEditorData) return; // or show an error
    setEditorData((prev) => {
      if (!prev?.sections) return prev;
      const newData = structuredClone(prev);
      newData.sections = newData.sections.filter(
        (sect) => sect.id !== sectionId
      );
      return newData;
    });
  }

  /**
   * Deletes a component from a given section.
   */
  function handleDeleteComponent(sectionId, componentId) {
    if (!setEditorData) return;
    setEditorData((prev) => {
      if (!prev?.sections) return prev;
      const newData = structuredClone(prev);
      const sec = newData.sections.find((s) => s.id === sectionId);
      if (!sec) return prev;
      sec.components = sec.components.filter((c) => c.id !== componentId);
      return newData;
    });
  }

  /**
   * Renders a top-level section (draggable) AND
   * a droppable for the section's components in one go.
   */
  function renderTopLevelSection(section, index) {
    return (
      <Draggable
        key={section.id}
        draggableId={String(section.id)}
        index={index}
        type="SECTION"
      >
        {(draggableProvided, draggableSnapshot) => (
          <Droppable
            droppableId={`components-section-${section.id}`}
            type="COMPONENT"
            direction={
              section.layout === "two-column" ? "horizontal" : "vertical"
            }
          >
            {(droppableProvided, droppableSnapshot) => {
              const isDraggingThisSection = draggableSnapshot.isDragging;
              const isDroppingOverThisSection =
                droppableSnapshot.isDraggingOver;

              // Scale the transform if dragging
              let sectionTransform =
                draggableProvided.draggableProps.style?.transform;
              if (isDraggingThisSection && sectionTransform) {
                sectionTransform = sectionTransform.replace(
                  /\)$/,
                  ") scale(0.8)"
                );
              }

              return (
                <div
                  // Merge both Draggable & Droppable refs:
                  ref={(node) => {
                    draggableProvided.innerRef(node);
                    droppableProvided.innerRef(node);
                  }}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  {...droppableProvided.droppableProps}
                  className="editor-preview__section"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isDragging) onSelect(section.id, null);
                  }}
                  style={{
                    width: "100%",
                    border: isDroppingOverThisSection
                      ? "2px dashed #007bff"
                      : "1px solid #ddd",
                    background: isDroppingOverThisSection
                      ? "#fafaff"
                      : "#fefefe",
                    opacity: isDraggingThisSection ? 0.7 : 1,
                    zIndex: isDraggingThisSection ? 9999 : "auto",
                    ...draggableProvided.draggableProps.style,
                    // transform: sectionTransform,
                  }}
                >
                  {/* DRAG HANDLE */}
                  <div className="editor-preview__section-drag">
                    <BsArrowsMove
                      size={"25px"}
                      color={"#fff"}
                      style={iconStyle("transparent")}
                    />
                  </div>

                  {/* DELETE BUTTON for the Section */}
                  <div
                    className="editor-preview__section-delete delete-section"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSection(section.id);
                    }}
                  >
                    <BiTrash
                      size={"25px"}
                      color={"#fff"}
                      style={iconStyle("transparent")}
                    />
                  </div>

                  {/* The section's actual components: */}
                  <div
                    style={{
                      minHeight: isDroppingOverThisSection ? 200 : 30,
                      padding: "1em",
                      overflow: "visible",
                      ...section.styles,
                    }}
                  >
                    {section.components.map((comp, compIndex) =>
                      renderComponentItem(comp, compIndex, section.id)
                    )}
                    {droppableProvided.placeholder}
                  </div>
                </div>
              );
            }}
          </Droppable>
        )}
      </Draggable>
    );
  }

  /**
   * Renders a single component (draggable) inside a section.
   */
  function renderComponentItem(item, index, parentSectionId) {
    return (
      <Draggable
        key={String(item.id)}
        draggableId={String(item.id)}
        index={index}
        type="COMPONENT"
      >
        {(provided, snapshot) => {
          const isDraggingThis = snapshot.isDragging;
          let compTransform = provided.draggableProps.style?.transform;
          if (isDraggingThis && compTransform) {
            compTransform = compTransform.replace(/\)$/, ") scale(0.8)");
          }

          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={(e) => {
                e.stopPropagation();
                if (!isDragging) onSelect(parentSectionId, item.id);
              }}
              style={{
                width: "100%",
                userSelect: "none",
                marginBottom: "0.5em",
                border: isDraggingThis
                  ? "1px solid #007bff"
                  : "1px solid transparent",
                background: "#fff",
                zIndex: isDraggingThis ? 9999 : "auto",
                opacity: isDraggingThis ? 0.9 : 1,
                padding: "0.5em",
                ...provided.draggableProps.style,
                transform: compTransform,
              }}
            >
              {/* DELETE BUTTON for the Component */}
              <div
                className="editor-preview__section-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteComponent(parentSectionId, item.id);
                }}
              >
                <BiTrash
                  size={"25px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                />
              </div>

              {/* Render the component in "editor mode" => false */}
              {componentRenderer[item.type]
                ? componentRenderer[item.type](item, false)
                : `Unknown ${item.type}`}
            </div>
          );
        }}
      </Draggable>
    );
  }

  return (
    <Droppable droppableId="all-sections" type="SECTION" direction="vertical">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          onDragStart={handleLocalDragStart}
          onDragEnd={handleLocalDragEnd}
          style={{
            minHeight: 600,
            border: snapshot.isDraggingOver
              ? "2px dashed #007bff"
              : "1px solid #ddd",
            padding: "1em",
            background: snapshot.isDraggingOver ? "#f7faff" : "white",
            width: "100%",
          }}
        >
          {data.sections.map((section, idx) =>
            renderTopLevelSection(section, idx)
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default EditorPreview;
