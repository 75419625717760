import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useSelector } from "react-redux";
import "./online-exam-results.scss";
import { iconStyle } from "../../utils/generalUtils";

function OnlineExamResults({
  exam,
  setExamScore,
  setExamDetailedRank,
  examComments,
  setExamComments,
}) {
  const profile = useSelector((state) => state.profile.value);

  const [detailedScore, setDetailedScore] = useState([]);

  useEffect(() => {
    if (!exam.details) {
      exam.details = exam.exam_details;
    }
    let temp = [];
    JSON.parse(exam.detailed_rank).map((rank) => {
      temp.push(0);
    });
    setDetailedScore(temp);
  }, []);

  const updateRank = (id, rank) => {
    // console.log(id);

    rank = parseInt(rank);

    let scoreWithoutCurrent = 0;
    detailedScore.map((score, index) => {
      if (id != index) {
        scoreWithoutCurrent = parseInt(scoreWithoutCurrent) + parseInt(score);
      }
    });
    if (
      parseInt(rank) >= 0 &&
      parseInt(scoreWithoutCurrent) + rank <= exam.max_rank &&
      rank <= parseInt(JSON.parse(exam.detailed_rank)[id])
    ) {
      const updatedRanks = detailedScore.map((grade, index) => {
        if (id == index) {
          return rank;
        }
        return grade;
      });
      setDetailedScore(updatedRanks);
      setExamScore(rank + scoreWithoutCurrent);
    }
  };

  useEffect(() => {
    if (setExamDetailedRank) {
      setExamDetailedRank(detailedScore);
    }
  }, [detailedScore]);

  const getComment = (index) => {
    try {
      const comment = JSON.parse(exam.result_text)[index];
      if (comment) {
        return comment;
      } else {
        return "Δεν υπάρχει σχόλιο για αυτό το θέμα";
      }
    } catch (e) {
      return "Δεν υπάρχει σχόλιο για αυτό το θέμα";
    }
  };

  const getAnswerImages = (index) => {
    try {
      const answerImages = JSON.parse(exam.answer_image);
      return <img className="image__open__full" src={answerImages[index]} />;
    } catch (e) {
      console.log(e);
    }
  };

  const getCkEditorData = (index) => {
    try {
      return JSON.parse(decodeURIComponent(exam.answer_text))[index];
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const updateCommentSection = (value, index) => {
    // Make a copy of the examComments array
    const updatedComments = [...examComments];
    updatedComments[index] = value;
    setExamComments(updatedComments);
  };

  const populateSections = () => {
    try {
      return exam.details
        ? JSON.parse(decodeURIComponent(exam.details)).map((detail, index) => {
            return (
              <div
                className="sections__item"
                key={"onlineExamResultSection " + index}
              >
                <span className="label">Θέμα {index + 1}ο</span>
                <div className="wrapper">
                  <div className="exam-question">
                    <span className="sub-label">Εκφώνηση</span>
                    <div className="rich-text">
                      <CKEditor
                        editor={ClassicEditor}
                        data={detail}
                        config={{
                          toolbar: [], // Configures an empty toolbar
                        }}
                        disabled={true} // Disable editing
                      />
                    </div>
                  </div>
                  <div className="exam-answer">
                    <span className="sub-label">Απάντηση</span>
                    <div className="exam-answer__images">
                      {getAnswerImages(index)}
                    </div>
                    <div className="rich-text">
                      <CKEditor
                        editor={ClassicEditor}
                        data={getCkEditorData(index)}
                        config={{
                          toolbar: [], // Configures an empty toolbar
                        }}
                        disabled={true} // Disable editing
                      />
                    </div>
                  </div>
                  <div className="exam-score">
                    <span className="sub-label">Βαθμολογία</span>
                    <div className="exam-score__container">
                      <span className="score-max">
                        Μέγιστος βαθμός: {JSON.parse(exam.detailed_rank)[index]}
                      </span>
                      <div className="score-wrapper">
                        {detailedScore[index] != undefined ? (
                          <CircularProgressbar
                            pathColor="{red}"
                            strokeWidth={8}
                            className="user-score"
                            value={
                              exam.score
                                ? (JSON.parse(exam.detailed_score)[index] *
                                    100) /
                                  JSON.parse(exam.detailed_rank)[index]
                                : (detailedScore[index] /
                                    JSON.parse(exam.detailed_rank)[index]) *
                                  100
                            }
                            duration={1.4}
                            text={
                              exam.score
                                ? JSON.parse(exam.detailed_score)[index] +
                                  " / " +
                                  JSON.parse(exam.detailed_rank)[index]
                                : ""
                            }
                          />
                        ) : (
                          ""
                        )}
                        {!exam.score ? (
                          <div className="score-wrapper__input">
                            {/* <input
                        value={detailedScore[index]}
                        onChange={(e) => updateRank(index, e.target.value)}
                        type="number"
                      /> */}
                            {/* <span>/{JSON.parse(exam.detailed_rank)[index]}</span> */}
                            <div className="rank-item__input">
                              <BiChevronDown
                                size={"30px"}
                                color={"#fff"}
                                style={iconStyle("#6224e645")}
                                onClick={() =>
                                  updateRank(index, detailedScore[index] - 1)
                                }
                              />
                              <input
                                type="number"
                                value={detailedScore[index]}
                                onChange={(e) =>
                                  updateRank(index, e.target.value)
                                }
                              />
                              <BiChevronUp
                                size={"30px"}
                                color={"#fff"}
                                style={iconStyle("#6224e645")}
                                onClick={() =>
                                  updateRank(index, detailedScore[index] + 1)
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="score-comments">
                        <span className="score-comments__label">
                          Σχόλια θέματος
                        </span>
                        {exam.score ? (
                          <span className="comment">{getComment(index)}</span>
                        ) : (
                          <textarea
                            className="input"
                            placeholder="Εισάγετεκάποιο σχόλιο για τις απαντήσεις του θέματος..."
                            value={examComments[index]}
                            onChange={(e) =>
                              updateCommentSection(e.target.value, index)
                            }
                          ></textarea>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : "";
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"online-exam-results"}>
      <div className="sections">{populateSections()}</div>
    </div>
  );
}

export default OnlineExamResults;
