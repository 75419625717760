import React, { useState } from "react";
import "./fullSizeImg.scss";
import { BiX, BiRotateLeft, BiRotateRight } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function FullSizeImg({ imgSrc, closeOverlay }) {
  const [rotateValue, setRotateValue] = useState(0);

  const containerClick = (e) => {
    if (e.target.className !== "full-size-img__container-img") {
      closeOverlay();
    }
  };

  let divStyles = {
    rotate: rotateValue + "deg",
  };

  const closeContainer = (e) => {
    if (window.innerWidth > 900) {
      containerClick(e);
    }
  };

  return (
    <div className="full-size-img">
      <div
        className="wrapper"
        onClick={(e) => {
          closeContainer(e);
        }}
      >
        <div className="full-size-img__container">
          <img
            alt="zoomIn zoomOut edupal"
            style={divStyles}
            className={"full-size-img__container-img "}
            src={imgSrc}
          />
        </div>

        <div className="full-size-img__close">
          <BiX
            size={"50px"}
            color={"#fff"}
            style={iconStyle("transparent")}
            onClick={() => closeOverlay()}
          />
        </div>
      </div>
      <div className="full-size-img__controllers">
        <div className="full-size-img__controllers-item">
          <BiRotateLeft
            onClick={() => {
              setRotateValue(rotateValue - 90);
            }}
            className="full-size-img__controllers-item-icon"
            size={"40px"}
            color={"#fff"}
            style={iconStyle("transparent")}
          />
        </div>
        <div className="full-size-img__controllers-item">
          <BiRotateRight
            onClick={() => {
              setRotateValue(rotateValue + 90);
            }}
            className="full-size-img__controllers-item-icon"
            size={"40px"}
            color={"#fff"}
            style={iconStyle("transparent")}
          />
        </div>
      </div>
    </div>
  );
}

export default FullSizeImg;
