import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiPencil, BiPlus, BiPrinter, BiUpload, BiX } from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import "./print-user-stats.scss";
import { useReactToPrint } from "react-to-print";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function formatMySQLTimestamp(mysqlTimestamp) {
  const date = new Date(mysqlTimestamp);

  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 10);
}

function isCommentBetweenDates(date, startAt, finishAt) {
  const tempDate = new Date(date);
  const localDate = new Date(
    tempDate.getTime() - tempDate.getTimezoneOffset() * 60000
  );
  const createdAt = new Date(localDate).toISOString().split("T")[0];
  const startDate = new Date(startAt).toISOString().split("T")[0];
  const finishDate = new Date(finishAt).toISOString().split("T")[0];

  return createdAt >= startDate && createdAt <= finishDate;
}

function PrintEvaluation({ isExpanded, setIsExpanded, students }) {
  const htmlRef = useRef(null);
  const currentDate = new Date();
  const oneMonthAhead = new Date();
  oneMonthAhead.setMonth(currentDate.getMonth() + 1);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [evaluation, setEvaluation] = useState([]);

  const [startAt, setStartAt] = useState(formatDate(currentDate));
  const [finishAt, setFinishAt] = useState(formatDate(oneMonthAhead));

  const [profileComments, setProfileComments] = useState([]);
  const schoolLogo = useSelector((state) => state.profile.schoolLogo);

  useEffect(() => {
    console.log(" calling it here ");
    const cleanUpGetEvaluation = getEvaluation();
    return () => {
      cleanUpGetEvaluation();
    };
  }, []);

  const getEvaluation = () => {
    let args = { is_general: true };

    const getEvaluationListener = (data) => {
      console.log("Received evaluation data:", data); // Debug log
      setEvaluation(data); // Update state
    };

    // Attach the listener first
    socketContext.socket.on("evaluation-general", getEvaluationListener);

    const refreshEvaluationListener = () => {
      socketContext.socket.emit("getEvaluation", args);
    };

    socketContext.socket.on("refreshEvaluation", refreshEvaluationListener);

    // Emit after listeners are attached
    socketContext.socket.emit("getEvaluation", args);

    return () => {
      // Cleanup
      socketContext.socket.off("evaluation-general", getEvaluationListener);
      socketContext.socket.off("refreshEvaluation", refreshEvaluationListener);
    };
  };

  const getAcademicPeriod = () => {
    const formatDate = (date) => {
      const d = new Date(date + "T00:00:00Z"); // Force UTC interpretation
      return `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getUTCFullYear()}`;
    };

    const formattedStartAt = formatDate(startAt);
    const formattedFinishAt = formatDate(finishAt);

    return formattedStartAt + " - " + formattedFinishAt;
  };
  const populateComments = (studentId) => {
    return evaluation
      .filter((com) => com.student_id == studentId)
      .map((eva) => {
        if (isCommentBetweenDates(eva.date, startAt, finishAt)) {
          let evaTitle = eva.title ? eva.title : "";
          let classGrade = eva.class_grade;
          return `<tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Class Performance</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${classGrade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">HomeWork</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.homework_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Vocabulary</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.vocabulary_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Grammar</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.grammar_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Reading</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.reading_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>
        <tr>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${evaTitle}</td>
          <td width="15%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Writing</td>
          <td width="10%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.writing_grade} / 100</td>
          <td width="25%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${eva.general_comments}</td>
        </tr>`;
        }
      });
  };

  let htmlTemplate = `<!DOCTYPE html>
  <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&subset=greek" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" type="text/css" />
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        max-width: 100%; /* Prevent body overflow */
        width: 100%;
        font-family: 'Montserrat', sans-serif;
      }
  
      .nl-container {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 0;
      }
  
      table {
        width: 100%;
        border-collapse: collapse;
      }
  
      /* Header styling */
      .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
  
      .header-container img {
        max-width: 300px;
        max-height: 200px;
        margin-bottom: 20px;
      }
  
      .header-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 5px;
      }
  
      .header-period {
        font-size: 16px;
        color: #666;
      }
  
      /* Student image and info */
      .student-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
      }
  
      .student-wrapper img {
        width: 140px !important;
        height: 140px !important;
        object-fit: cover;
        border-radius: 50%;
      }
  
      .student-info {
        font-size: 18px;
        color: #7747FF;
      }
  
      /* Tables */
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
      }
  
      th, td {
        padding: 10px;
        text-align: left;
      }
  
      thead {
        background-color: #f2f2f2;
      }
  
      /* Remove unwanted dots */
      td:empty::before {
        content: none !important;
      }
  
      /* Print styles */
      @media print {
        /* Force same weight across different subsets */
          body {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400; /* You can experiment with other weights */
          }
  
          :lang(el) {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400; /* Match the weight used for other characters */
          }

          .section-print {
            page-break-before: always;
          }
  
        body, .nl-container {
          margin: 0;
          padding: 0;
          width: 100%;
          max-width: 900px;
          overflow: visible;
        }
  
        .nl-container {
          width: 100% !important;
          max-width: 900px !important;
        }
  
        .row-content {
          width: 100% !important;
          max-width: 900px !important;
          margin: 0 auto;
        }
  
        table {
          max-width: 100% !important;
          width: 100% !important;
          height: auto !important;
        }
  
        .desktop_hide, .mobile_hide {
          display: none !important;
        }
        .student-wrapper {
          width: 100%;
        }
        .student-wrapper__img {
          width: 200px;
        }
        .student-wrapper img {
          width: 100px !important;
          height: 100px !important;
          object-fit: cover;
          border-radius: 50% !important;
        }
      }
    </style>
  </head>
  
  <body class="body" style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;">
      <tbody>
        <tr>
          <td>
          <div class="header-container">
            <img src="${schoolLogo}" alt="School Logo" />
            <div class="header-title">Αξιολόγηση Μαθητών</div>
            <div class="header-period">Περίοδος: ${getAcademicPeriod()}</div>
          </div>
            <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
              <tbody>
                <tr>
                  <td>
                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                      <tbody>
                        <tr>
                          <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                            <table class="divider_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                              <tr>
                                <td class="">
                                  <div class="alignment" align="center">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                      <tr>
                                        <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #dddddd;"><span style="word-break: break-word;">&#8202;</span></td>
                                      </tr>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
      `;

  const populateTemplates = () => {
    return students.map((student) => {
      let htmlTemplate = `<!DOCTYPE html>
        <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
        <head>
          <title></title>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&subset=greek" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" type="text/css" />
          <style>
            * {
              box-sizing: border-box;
            }
        
            body {
              margin: 0;
              max-width: 100%; /* Prevent body overflow */
              width: 100%;
              font-family: 'Montserrat', sans-serif;
            }
        
            .nl-container {
              width: 100%;
              max-width: 900px;
              margin: 0 auto;
              padding: 0;
            }
        
            table {
              width: 100%;
              border-collapse: collapse;
            }
        
            /* Header styling */
            .header-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
        
            .header-container img {
              max-width: 300px;
              max-height: 200px;
              margin-bottom: 20px;
            }
        
            .header-title {
              font-size: 28px;
              font-weight: bold;
              margin-bottom: 5px;
            }
        
            .header-period {
              font-size: 16px;
              color: #666;
            }
        
            /* Student image and info */
            .student-wrapper {
              display: flex;
              align-items: center;
              gap: 20px;
              margin-bottom: 20px;
            }
        
            .student-wrapper img {
              width: 140px !important;
              height: 140px !important;
              object-fit: cover;
              border-radius: 50%;
            }
        
            .student-info {
              font-size: 18px;
              color: #7747FF;
            }
        
            /* Tables */
            table {
              border-collapse: collapse;
              width: 100%;
              font-size: 14px;
            }
        
            th, td {
              padding: 10px;
              text-align: left;
            }
        
            thead {
              background-color: #f2f2f2;
            }
        
            /* Remove unwanted dots */
            td:empty::before {
              content: none !important;
            }
        
            /* Print styles */
            @media print {
              /* Force same weight across different subsets */
                body {
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400; /* You can experiment with other weights */
                }
        
                :lang(el) {
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400; /* Match the weight used for other characters */
                }
        
              body, .nl-container {
                margin: 0;
                padding: 0;
                width: 100%;
                max-width: 900px;
                overflow: visible;
              }
        
              .nl-container {
                width: 100% !important;
                max-width: 900px !important;
              }
        
              .row-content {
                width: 100% !important;
                max-width: 900px !important;
                margin: 0 auto;
              }
        
              table {
                max-width: 100% !important;
                width: 100% !important;
                height: auto !important;
              }
        
              .desktop_hide, .mobile_hide {
                display: none !important;
              }
              .student-wrapper {
                width: 100%;
              }
              .student-wrapper__img {
                width: 200px;
              }
              .student-wrapper img {
                width: 100px !important;
                height: 100px !important;
                object-fit: cover;
                border-radius: 50% !important;
              }
            }
          </style>
        </head>
        
        <body class="body" style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
          <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;">
            <tbody>
              <tr>
                <td>
                  <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                    <tbody>
                      <tr>
                        <td>
                          <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                            <tbody>
                              <tr class="student-wrapper">
                                <td class="column column-1" width="25%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                  <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td class="student-wrapper__img">
                                        <h1 style="margin: 0; color: #7747FF; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 700; letter-spacing: normal;  text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 25.2px;"><span class="tinyMce-placeholder" style="word-break: break-word;">
                                        <img
              src="${
                student.profile_picture
                  ? student.profile_picture
                  : "resources/student.png"
              }"
              alt="student"
              style="width:100px;height:100px;"
            /></span></h1>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                  <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td class="pad">
                                        <h1 style="margin: 0; color: #7747FF; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 38px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 45.6px;"><span class="tinyMce-placeholder" style="word-break: break-word;">${
                                          student.first_name +
                                          " " +
                                          student.last_name
                                        }<br></span></h1>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                      <table class="row row-9" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                  <tbody>
                    <tr>
                      <td>
                        <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                          <tbody>
                            <tr>
                              <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <h1 style="margin: 0; color: #000000; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 20px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 24px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Αξιολόγηση:</span></h1>
                                    </td>
                                  </tr>
                                </table>
                                ${
                                  evaluation.find(
                                    (eva) => eva.student_id == student.user_id
                                  )
                                    ? ` <table class="table_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                <tr>
                                  <td class="pad">
                                    <table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; width: 100%; table-layout: fixed; direction: ltr; background-color: transparent; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 400; color: #101112; text-align: left; letter-spacing: 0px; word-break: break-all;" width="100%">
                                      <thead style="vertical-align: top; background-color: #f2f2f2; color: #101112; font-size: 14px; line-height: 120%; text-align: center;">
                                      <tr>
                                      <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Περίοδος<br></th>
                                      <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Τύπος βαθμού<br></th>
                                      <th width="15%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Βαθμός<br></th>
                                      <th width="50%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Σχόλιο<br></th>
                                    </tr>
                                      </thead>
                                      <tbody style="vertical-align: top; font-size: 16px; line-height: 120%;">
                                        ${populateComments(student.user_id)}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                </table>`
                                    : `Δεν υπάρχει αξιολόγηση`
                                }
                                <table class="divider_block block-3" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td class="pad">
                                      <div class="alignment" align="center">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                          <tr>
                                            <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #dddddd;"><span style="word-break: break-word;">&#8202;</span></td>
                                          </tr>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                     
                  </td>
              </tr>
            </tbody>
          </table><!-- End -->
        </body>
        
        </html>
            `;

      return (
        <div
          key={"dangerous html " + student.user_id}
          dangerouslySetInnerHTML={{ __html: htmlTemplate }}
        />
      );
    });
  };

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.3 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "40vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const handlePrint = useReactToPrint({
    content: () => htmlRef.current, // Reference to the element you want to print
    documentTitle: "Student Report", // Optional: Title for the print document
  });

  return (
    <div
      ref={containerRef}
      className={
        "print-user-stats " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "print-user-stats__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="print-user-stats__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="date-selection">
              <div className="html-container" ref={htmlRef}>
                <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
                {populateTemplates()}
              </div>
              <div className="date-selection__periods">
                <div className="period-item">
                  <span>Έναρξη</span>
                  <input
                    className="input"
                    type="date"
                    value={startAt}
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                </div>
                <div className="period-item">
                  <span>Λήξη</span>
                  <input
                    className="input"
                    type="date"
                    value={finishAt}
                    onChange={(e) => setFinishAt(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="print-user-stats__config-actions">
              <button className="cta" onClick={() => handlePrint()}>
                Εκτύπωση
              </button>
            </div>
            <div
              className="print-user-stats__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="print-user-stats__preview">
            <BiPrinter
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default PrintEvaluation;
